import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Header from "../../layouts/Header";

function Role() {
  const navigate = useNavigate();
  const [filterTypeEnterprise, setFilterTypeEnterprise] = useState(false);
  const [filterTypePremium, setFilterTypePremium] = useState(false);
  const [filterTypeStandard, setFilterTypeStandard] = useState(false);
  const [filterStatusActive, setFilterStatusActive] = useState(false);
  const [filterStatusInactive, setFilterStatusInactive] = useState(false);

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: false,
    },
    {
      name: "User email ",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.regDate,
      sortable: true,
    },
    {
      name: "Phone no.",
      selector: (row) => row.licenses,
      sortable: false,
    },
    {
      name: "Created on  ",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span style={getStatusStyle(row.status)}>{row.status}</span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/org-detail/${row.id}`)}
        >
          <i className="fa fa-arrow-right"></i>
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const columnData = [
    {
      id: 1,
      name: "John Doe",
      regDate: "2023-01-01",
      licenses: 2,
      type: "Premium",
      status: "Active",
    },
    {
      id: 2,
      name: "Jane Smith",
      regDate: "2023-02-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
    {
      id: 3,
      name: "Alice Johnson",
      regDate: "2023-03-01",
      licenses: 3,
      type: "Premium",
      status: "Active",
    },
    {
      id: 4,
      name: "Bob Brown",
      regDate: "2023-04-01",
      licenses: 4,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 5,
      name: "Charlie Davis",
      regDate: "2023-05-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
    {
      id: 6,
      name: "Diana Evans",
      regDate: "2023-06-01",
      licenses: 2,
      type: "Premium",
      status: "Active",
    },
    {
      id: 7,
      name: "Ethan Ford",
      regDate: "2023-07-01",
      licenses: 3,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 8,
      name: "Fiona Garcia",
      regDate: "2023-08-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
    {
      id: 9,
      name: "George Harris",
      regDate: "2023-09-01",
      licenses: 4,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 10,
      name: "Hannah King",
      regDate: "2023-10-01",
      licenses: 2,
      type: "Premium",
      status: "Inactive",
    },
    {
      id: 11,
      name: "Isaac Lewis",
      regDate: "2023-11-01",
      licenses: 3,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 12,
      name: "Jackie Moore",
      regDate: "2023-12-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
    {
      id: 13,
      name: "Kevin Nelson",
      regDate: "2024-01-01",
      licenses: 2,
      type: "Premium",
      status: "Active",
    },
    {
      id: 14,
      name: "Luna Owens",
      regDate: "2024-02-01",
      licenses: 3,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 15,
      name: "Mike Perez",
      regDate: "2024-03-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
    {
      id: 16,
      name: "Nina Quinn",
      regDate: "2024-04-01",
      licenses: 4,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 17,
      name: "Oscar Roberts",
      regDate: "2024-05-01",
      licenses: 2,
      type: "Premium",
      status: "Active",
    },
    {
      id: 18,
      name: "Paula Scott",
      regDate: "2024-06-01",
      licenses: 3,
      type: "Enterprise",
      status: "Active",
    },
    {
      id: 19,
      name: "Quincy Turner",
      regDate: "2024-07-01",
      licenses: 1,
      type: "Standard",
      status: "Inactive",
    },
  ];

  const filteredData = columnData.filter((row) => {
    let matchType = true;
    let matchStatus = true;

    if (filterTypeEnterprise && row.type !== "Enterprise") {
      matchType = false;
    }
    if (filterTypePremium && row.type !== "Premium") {
      matchType = false;
    }
    if (filterTypeStandard && row.type !== "Standard") {
      matchType = false;
    }

    if (filterStatusActive && row.status !== "Active") {
      matchStatus = false;
    }
    if (filterStatusInactive && row.status !== "Inactive") {
      matchStatus = false;
    }

    return matchType && matchStatus;
  });

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {/* Filter controls */}
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column ">
            <p className="fs-18 fw-bolder p-0 m-0 ">Roles</p>
          </span>
        </div>

        {/* DataTable */}
        {/* <div class="container text-center">
          <div class="row">
            <div class="col-sm-5">
              <div class="card rounded-4 bg-white">
                <div class="card-body">
                  <h5 class="card-title">Update Details</h5>
                  <div class="modal-header">
        <h5 class="modal-title">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a href="#" class="card-link">
                    Card link
                  </a>
                  <a href="#" class="card-link">
                    Another link
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-4"> <div class="card rounded-4 bg-white">
                <div class="card-body">
                  <h5 class="card-title">Change Password</h5>
                 
                  <p class="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <a href="#" class="card-link">
                    Card link
                  </a>
                  <a href="#" class="card-link">
                    Another link
                  </a>
                </div>
              </div></div>
            
          </div>
        </div> */}
        <DataTable
          columns={columns}
          data={columnData || []}
          // pagination
          // paginationServer
          // paginationTotalRows={totalDocuments}
          // paginationDefaultPage={currentPage}
          // paginationPerPage={pageSize}
          // onChangePage={(page) => {
          //   handlePageChange(page);
          // }}
          // onChangeRowsPerPage={(newPageSize) => {
          //   handlePageSizeChange(newPageSize);
          // }}
          noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
        />
      </div>
    </React.Fragment>
  );
}

export default Role;

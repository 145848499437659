import { useEffect } from 'react';

const useInactivityTimer = (user, timeout = 900000, onLogout) => {
    useEffect(() => {
        if (user) {
            let timeoutId;
            const resetTimer = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    onLogout();
                }, timeout);
            };

            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keydown', resetTimer);

            resetTimer();

            return () => {
                clearTimeout(timeoutId);
                window.removeEventListener('mousemove', resetTimer);
                window.removeEventListener('keydown', resetTimer);
            };
        }
    }, [timeout, onLogout]);
};

export default useInactivityTimer;

import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useSelector } from "react-redux";
import { notifyApi } from "../../api/axiosSet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CompLoader from "../../components/common/Loader/CompLoader";

export function FileUpload({ files, setFiles }) {
    const onDrop = (acceptedFiles) => {
        const uniqueRpmFiles = acceptedFiles.filter(
            (file) => !files.some(existingFile => existingFile.name === file.name)
        );

        setFiles((prevFiles) => [...prevFiles, ...uniqueRpmFiles]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true
    });

    return (
        <div style={styles.container}>
            <div
                {...getRootProps()}
                style={{
                    ...styles.dropzone,
                    ...(isDragActive ? styles.dropzoneActive : {})
                }}
            >
                <input {...getInputProps()} />
                <p>Drag and drop files here, or click to select files</p>
            </div>
            <div style={styles.fileList}>
                <h4>Files to be uploaded:</h4>
                <ul>
                    {files.map((file, index) => (
                        <li key={index}>
                            {file.name} - {file.size} bytes
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '2px dashed #007bff',
        borderRadius: '5px',
        width: '400px',
        margin: '30px auto',
    },
    dropzone: {
        padding: '20px',
        textAlign: 'center',
        color: '#007bff',
        cursor: 'pointer',
        transition: 'border 0.2s ease-in-out'
    },
    dropzoneActive: {
        borderColor: '#00bfff'
    },
    fileList: {
        marginTop: '20px',
        textAlign: 'left',
        width: '100%'
    }
};

function PatchManagement() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { user } = useSelector((state) => state.authSlice);
    const [columnData, setColumnData] = useState([]);
    const [files, setFiles] = useState([]);
    const [patchDataClicked, setPatchDataClicked] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [pageSizeModal, setPageSizeModal] = useState(10);
    const [currentPageModal, setCurrentPageModal] = useState(1);
    const [totalDocumentsModal, setTotalDocumentsModal] = useState(0);
    const [modalData, setModalData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [allDataPage, setAllDataPage] = useState(1);
    const [allDataRowPage, setAllDataRowPage] = useState(10);
    const [totalDocuments, setTotalDocuments] = useState(0);

    const handleCloseModal = () => {
        setShowModal(false);
        setFiles([]);
    }

    const closeModal = () => {
        setModalOpen(false);
        setPageSizeModal(10);
        setCurrentPageModal(1);
        setTotalDocumentsModal(0);
        setPatchDataClicked(null);
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#D7E3E8",
            },
        },
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const columns = [
        {
            name: "Sl no.",
            selector: (_, index) => (allDataPage - 1) * allDataRowPage + index + 1,
            width: "90px",
        },
        {
            name: "Patch Name",
            selector: (row) => row?.patchName,
            cell: (row) => (
                <Tippy maxWidth="none" content={row.patchName || "NA"}>
                    <div>{row.patchName || "NA"}</div>
                </Tippy>
            ),
            sortable: true,
        },
        {
            name: "Patch Type",
            selector: (row) => (
                <Tippy maxWidth="none" content={row.patchType || "NA"}>
                    <div>{row.patchType || "NA"}</div>
                </Tippy>
            ),
        },
        {
            name: "Description",
            selector: (row) => row?.description,
            cell: (row) => (
                <Tippy maxWidth="none" content={row?.description || "NA"}>
                    <div>{row?.description || "NA"}</div>
                </Tippy>
            ),
        },
        {
            name: "Files Added",
            selector: (row) => (
                <Tippy
                    maxWidth="none"
                    content={
                        row?.listOfFiles?.length > 0 ? (
                            <ol style={{ margin: 0, padding: "4px 8px" }}>
                                {row.listOfFiles.map((file, index) => (
                                    <li key={index} style={{ marginBottom: "4px" }}>
                                        {file?.fileName}
                                    </li>
                                ))}
                            </ol>
                        ) : (
                            "NA"
                        )
                    }
                >
                    <div>
                        {row?.listOfFiles && row?.listOfFiles?.map((file, index) => (
                            <div key={index}>{file?.fileName}</div>
                        ))}
                    </div>
                </Tippy>
            ),
        },
        {
            name: "Applied PC Count",
            selector: (row) => row?.appliedOnCount,
            cell: (row) => (
                <Tippy maxWidth="none" content={row?.appliedOnCount || "0"}>
                    <div style={{
                        ...(row?.appliedOnCount > 0 && {
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "underline",
                        }),
                    }}
                        onClick={() => {
                            if (row?.appliedOnCount > 0) {
                                setPatchDataClicked(row);
                                setModalOpen(true);
                            }
                        }}
                    >
                        {row?.appliedOnCount || 0}
                    </div>
                </Tippy>
            ),
            sortable: true,
        },
        {
            name: "Created By",
            selector: (row) => row?.email,
            cell: (row) => (
                <Tippy maxWidth="none" content={row?.email || 0}>
                    <div>{row?.email || 0}</div>
                </Tippy>
            ),
            sortable: true,
        },
        {
            name: "Update Time",
            selector: (row) => row?.updatedAt,
            cell: (row) => (
                <Tippy maxWidth="none" content={formatTimestamp(row?.updatedAt) || "NA"}>
                    <div>{formatTimestamp(row?.updatedAt) || "NA"}</div>
                </Tippy>
            ),
            sortable: true,
        }
    ];

    const [error, setError] = useState("");

    const handleSubmitPatchData = async (e) => {
        e.preventDefault();
        const regex = /^[a-zA-Z0-9._-]+$/;
        if (!regex.test(e.target.patchName.value)) {
            setError("Patch name should not contain special characters");
            return;
        }
        if (!regex.test(e.target.patchType.value)) {
            setError("Patch type should not contain special characters");
            return;
        }
        if (!regex.test(e.target.patchDesc.value)) {
            setError("Patch description should not contain special characters");
            return;
        }
        if (files.length === 0) {
            setError("Please select at least one file");
            return;
        }
        const formData = new FormData();
        formData.append("patchName", e.target.patchName.value);
        formData.append("patchType", e.target.patchType.value);
        formData.append("description", e.target.patchDesc.value);
        for (let i = 0; i < files.length; i++) {
            formData.append("patchFiles", files[i]);
        }

        try {
            setLoading(true);
            const response = await notifyApi.post(
                `/add-new-patch-info`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${user?.data?.accessToken}`,
                    },
                }
            );
            toast.success(response?.data?.message);
            fetchPatchData();
        } catch (error) {
            toast.error(error.response.data.message);
        }
        finally {
            setLoading(false);
            handleCloseModal();
            setFiles([]);
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchPatchData = async () => {
        try {
            const response = await notifyApi.get(
                `/get-patch-info/by/org?page=${currentPage}&limit=${pageSize}`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.data?.accessToken}`,
                    }
                }
            );
            setTotalDocuments(response?.data?.pagination?.totalDocuments);
            setColumnData(response?.data?.patches);
        } catch (error) {
            setColumnData([]);
        }
    }

    const fetchPatchDataClicked = async () => {
        try {
            const response = await notifyApi.get(
                `/get/applied-patch-info/list?patchId=${patchDataClicked?._id}&page=${currentPageModal}&limit=${pageSizeModal}`,
                {
                    headers: {
                        Authorization: `Bearer ${user?.data?.accessToken}`,
                    }
                }
            );
            setTotalDocumentsModal(response?.data?.pagination?.totalDocuments);
            setModalData(response?.data?.pcInfoList);
        } catch (error) {
            setModalData([]);
        }
    }

    useEffect(() => {
        fetchPatchData();
    }, [currentPage, pageSize]);

    useEffect(() => {
        patchDataClicked && fetchPatchDataClicked();
    }, [patchDataClicked, currentPageModal, pageSizeModal]);

    const columnsName = [
        {
            name: "S. No.",
            cell: (_, index) => (currentPageModal - 1) * pageSizeModal + index + 1,
            width: "90px",
        },
        {
            name: "Hostname",
            selector: (row) => row.hostname,
            cell: (row) => (
                <Tippy maxWidth="none" content={row.hostname}>
                    <div>{row.hostname}</div>
                </Tippy>
            ),
            sortable: true,
        },
        {
            name: "Serial Number",
            selector: (row) => (
                <Tippy maxWidth="none" content={row.serialNumber || "NA"}>
                    <div>{row.serialNumber || "NA"}</div>
                </Tippy>
            ),
        },
        {
            name: "IP Address",
            selector: (row) => (
                <Tippy maxWidth="none" content={row?.ipAddress?.split(" ").join(", ") || "NA"}>
                    <div>{row?.ipAddress?.split(" ").join(", ") || "NA"}</div>
                </Tippy>
            ),
        },
        {
            name: "Status",
            selector: (v) => (
                <Tippy maxWidth="none" content={v?.status ? "Installed" : "Not Installed"} >
                    <div style={{ color: v?.status ? "green" : "red" }}>{v?.status ? "Installed" : "Not Installed"}</div>
                </Tippy>
            ),
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="flex justify-center">
                    <Button
                        className="btn rounded-md d-flex align-items-center border-0 fs10 gap-2 mx-auto px-3 text-light bg-dark"
                        onClick={() =>
                            navigate("/linux-client-Details", {
                                state: {
                                    basicInfo: row,
                                    pcInfoId: row?.pcId,
                                    title: "Patch Info",
                                },
                            })
                        }
                    >
                        <p className="m-0 p-0 fs-12">Details</p>
                        <i className="ri-arrow-right-line"></i>
                    </Button>
                </div>
            ),
        }
    ]

    return (
        <React.Fragment>
            <div
                className="main main-app p-3 p-lg-4"
                style={{ backgroundColor: "#ECF9FF" }}
            >
                <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
                    <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
                        <p className="fs-18 fw-bolder p-0 m-0">
                            Patch Management
                        </p>
                    </span>
                    <button
                        type="button"
                        className="btn btn-info text-white"
                        onClick={() =>
                            setShowModal(true)
                        }
                    >
                        <i className="ri-add-line"></i> &nbsp;
                        Add Patch Update
                    </button>
                </div>
                <div className="card rounded mb-2 shadow cardStyle">
                    <DataTable
                        highlightOnHover
                        columns={columns}
                        data={columnData || []}
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationTotalRows={totalDocuments}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
                        noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                    />
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Patch Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitPatchData}>
                        <div className="mb-3">
                            <label htmlFor="patchName" className="form-label">
                                Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="patchName"
                                maxLength={50}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="patchType" className="form-label">
                                Type <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="patchType"
                                maxLength={50}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="patchDesc" className="form-label">
                                Description <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="patchDesc"
                                maxLength={50}
                                required
                            />
                        </div>
                        <FileUpload files={files} setFiles={setFiles} />
                        {error && <p className="text-danger">{error}</p>}
                        <div className="d-flex justify-content-end gap-3">
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={loading}
                            >
                                {loading ? <CompLoader /> : "Submit"}
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={modalOpen} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span style={{ color: "blue", }}>{patchDataClicked?.patchName}&nbsp;</span>
                        Applied On:
                        <span style={{ fontSize: "0.9em", fontWeight: "normal", }}>{` ${totalDocumentsModal} Endpoint(s)`} </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* DataTable to display API response */}
                    <DataTable
                        columns={columnsName}
                        data={modalData || []}
                        pagination
                        paginationServer
                        customStyles={customStyles}
                        paginationTotalRows={totalDocumentsModal}
                        paginationDefaultPage={currentPageModal}
                        paginationPerPage={10}
                        onChangePage={(page) => { setCurrentPageModal(page) }}
                        onChangeRowsPerPage={(newPageSize) => { setPageSizeModal(newPageSize) }}
                        noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary text-white"
                    // onClick={exportToPDF}
                    >
                        <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-success text-white"
                    // onClick={exportToCSV}
                    >
                        <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
                    </button>
                    <Button
                        variant="btn btn-secondary btn-sm"
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default PatchManagement;
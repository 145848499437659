import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context
const SkinModeContext = createContext();

// Custom hook to use the skin mode context
export const useSkinMode = () => {
  return useContext(SkinModeContext);
};

// Provider to wrap your application and provide the state
export const SkinModeProvider = ({ children }) => {
  const [skinMode, setSkinMode] = useState(localStorage.getItem("skin-mode") || "light");

  useEffect(() => {
    if (skinMode === "dark") {
      document.documentElement.setAttribute("data-skin", "dark");
      localStorage.setItem("skin-mode", "dark");
    } else {
      document.documentElement.removeAttribute("data-skin");
      localStorage.removeItem("skin-mode");
    }
  }, [skinMode]);

  const toggleSkinMode = () => {
    setSkinMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <SkinModeContext.Provider value={{ skinMode, toggleSkinMode }}>
      {children}
    </SkinModeContext.Provider>
  );
};

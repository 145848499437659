import React, { useRef, useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import DefaultLogo from "../assets/img/onlyLogo.png";
import { adminMenu, dashboardMenu, UserAdminMenu, OrgAdmin, otherAdmins } from "../data/Menu";
import { useSelector } from "react-redux";
import { useSidebarToogle } from "../components/SidebarToogleContext";

const Sidebar = () => {
  const scrollBarRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authSlice);

  const [imageSrc, setImageSrc] = useState(DefaultLogo);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to="/" className="sidebar-logo d-flex">
          <img
            src={imageSrc}
            id="logo"
            alt="Cyber Auditor"
            className="cyBerLogo"
          />
        </Link>
        <p className="m-2 p-0 fw-bolder fs-5 fs15em" style={{ cursor: "pointer" }} onClick={() => navigate("/")}>CYBER AUDITOR</p>
      </div>
      <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
        <SidebarMenu
          onUpdateSize={() => scrollBarRef.current.updateScroll()}
          userType={user?.data?.user?.roleName}
        />
      </PerfectScrollbar>
    </div>
  );
};

const SidebarMenu = ({ onUpdateSize, userType }) => {
  const { sidebarCollapsed } = useSidebarToogle();

  useEffect(() => {
    if (sidebarCollapsed) {
      // Select all .nav-item elements
      const navItems = document.querySelectorAll(".nav-item");

      navItems.forEach((item) => {
        if (!item.classList.contains("show")) {
          item.classList.add("show");
        }
      });
    }
  }, [sidebarCollapsed]);

  const populateMenuDash = (menuData) => {
    const menu = menuData.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink
              to={sm.link}
              className="nav-sub-link"
              key={key}
              title={sidebarCollapsed ? sm.label : null} // Add title attribute
            >
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink
              to={m.link}
              className="nav-link"
              title={sidebarCollapsed ? m.label : null}
            >
              <i className={m.icon} style={{ fontSize: "18px" }}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div
              onClick={toggleSubMenu}
              className="nav-link has-sub"
              title={sidebarCollapsed ? m.label : null}
            >
              <i className={m.icon} style={{ fontSize: "18px" }}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  const populateMenuControl = (menuData) => {
    const menu = menuData.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink
              to={sm.link}
              className="nav-sub-link d-flex align-items-center"
              key={key}
              title={sidebarCollapsed ? sm.label : null}
            >
              <i className={sm.icon} style={{ fontSize: "18px" }}></i> <span>{sm.label}</span>
            </NavLink>
          );
        });
      }

      return (
        <li key={key} className="nav-item show">
          {!sm ? (
            <NavLink
              to={m.link}
              className="nav-link"
              title={sidebarCollapsed ? m.label : null}
            >
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div
              onClick={toggleSubMenu}
              className="nav-link has-sub"
              title={sidebarCollapsed ? m.label : null}
            >
              <i className={m.icon} style={{ fontSize: "18px" }}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    onUpdateSize();
  };

  return (
    <>
      <div className="nav-group show mt-2">
        {(userType === "Super Admin" || userType === "Ca Admin" || userType === "Organization Admin")
          ? populateMenuDash(dashboardMenu)
          : populateMenuDash(otherAdmins)}
      </div>
      <hr />
      <div className="nav-group show mt-2">
        {userType === "Ca Admin" && populateMenuControl(adminMenu)}
        {userType === "Super Admin" && populateMenuControl(UserAdminMenu)}
        {userType === "Organization Admin" && populateMenuControl(OrgAdmin)}
      </div>
    </>
  );
};

export default Sidebar;

import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

export default function Main() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const [skin, setSkin] = useState(currentSkin);

  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add("sidebar-offset")
    : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  return (
    <div className="grid-layout">
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="outlet-container">
        <Outlet />
      </div>
    </div>
  );
}

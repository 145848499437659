import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Footer from "../../layouts/Footer";
import newWindowIcon from "../../assets/img/DashIcon/newWindowIcon.png";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";
import integrity from "../../assets/img/Alerts/integration.png";
import jsPDF from "jspdf";
import { notifyApi } from "../../api/axiosSet";
import { useSkinMode } from "../../components/SkinModeContext";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";
import usbIcon from "../../assets/img/Alerts/usbIcon.png";
import usbviolation from "../../assets/img/Alerts/usbviolation.png";
import fipsnot from "../../assets/img/Alerts/fipsnot.png";
import secure from "../../assets/img/Alerts/secure.png";
import openport from "../../assets/img/Alerts/openport.png";
import publicshare from "../../assets/img/Alerts/publicshare.png";
import tpm_icon from "../../assets/img/Alerts/tpm_icon.png";
import oldPassword from "../../assets/img/Alerts/oldPassword.png";
import multRoot from "../../assets/img/Alerts/multRoot.png";
import unreccg from "../../assets/img/Alerts/unreccg.png";
import unrecog from "../../assets/img/Alerts/unrecog.png";
import oshardingset from "../../assets/img/Alerts/oshardingset.png";
import ssl from "../../assets/img/Alerts/ssl.png";
import edr from "../../assets/img/Alerts/edr.png";
import zen from "../../assets/img/Alerts/zen.png";
import unknow from "../../assets/img/Alerts/unknow.png";
import bluetooth from "../../assets/img/Alerts/bluetooth.png";
import windows_rdp_gateway from "../../assets/img/Alerts/windows_rdp_gateway.png";
import battery from "../../assets/img/Alerts/battery.png";
import cddrive from "../../assets/img/Alerts/cddrive.ico";
import hardingSet from "../../assets/img/Alerts/hardingSet.png";
import wifi from "../../assets/img/Alerts/wifi.png";
import os_not_patch from "../../assets/img/Alerts/os_not_patch.png";
import virus from "../../assets/img/Alerts/virus.png";
import ram from "../../assets/img/Alerts/ram.png";
import cpu from "../../assets/img/Alerts/cpu.png";
import PageLoader from "../../components/common/Loader/PageLoader";
import { fetchWindowsSummary } from "../../hooks/windowsHooks/fetchWindowsSummary";
import {
  EDR_MALFUNCTION_ENDPOINT,
  ANTIVIRUS_NOT_INSTALLED_COUNT_ENDPOINT,
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  MALWARE_DETECTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  OS_PATCHED,
  NAC_NAP,
  FIREWALL_OFF,
  WINDOW_NOT_ACTIVATED,
  BLACKLIST_PROGRAME,
  WINDOW_ALL_CLIENT,
  USB_VOILATION,
  UNRESPONSIVE_WINDOWS,
  SAFE_WINDOW_CLIENT,
  UNSAFE_WINDOW_CLIENTS,
  OS_INFO_DATA,
  NETWORK_INFO_DATA,
  SHARED_FOLDER,
  RDP_ENABLED,
  WIFI_ENABLED,
  BLUETOOTH_ENABLED,
  UNKNOWN_NETWORK_COMMUNICATION,
  OPEN_NETWORK_CONNECTION,
  UNKNOWN_SERVICES,
  MULTIPLE_PASSWORD,
  OLD_PASSWORD,
  NAC_DISABLED,
  OUT_OF_DOMAIN,
  CD_DRIVE_ENABLED,
  SCANNER_INSTALLED,
  IS_HIGH_CPU_USAGE,
  IS_HIGH_RAM_USAGE,
  BIOS_BATTERY,
  UNWANTED_PORT,
  TPM_DISABLED,
  IS_FIPS_DISABLE,
  IS_SECURE_BOOT_DISABLED,
  OS_NOT_HARDENED,
  FILE_INTEGRITY_FAILED,
  ZEN_NOT_INSTALLED,
  CERTIFICATE_CLIENT_NOT_INSTALLED,
  IS_PROCESS_HARDENING_INFO,
} from "../../api/endPoints/windowAlertEndPoint";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export default function WindowDetail() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authSlice);
  const [loading, setLoading] = useState(true);
  const [windowsSummary, setWindowsSummary] = useState({});
  const [windowsAlertSummary, setWindowAlertSummary] = useState({});
  const [totalDashboard, setTotalDashboard] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      let url = `/${user?.data?.user?.userId}`;

      try {
        setLoading(true);
        if (isMounted) {
          const { windowsSummary, windowsAlertSummary, totalDashboard } = await fetchWindowsSummary(url, user?.data?.accessToken);
          setWindowsSummary(windowsSummary);
          setWindowAlertSummary(windowsAlertSummary);
          setTotalDashboard(totalDashboard);
        }
      }
      catch {
        console.error("Error fetching summary data:");
      }
      finally {
        if (isMounted) {
          setLoading(false);
          setTimeout(fetchData, 60000);
        }
      }

    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [user]);

  const [lineGraph, setLineGraph] = useState();
  const [monthGraph, setMonthGraph] = useState();
  const [monthGraphTwo, setMonthGraphTwo] = useState([]);
  const prevResponseRef = useRef(null); // Store the previous response
  const prevMonthResponseRef = useRef(null); // Ref for MonthResponse
  const prevDeptResponseRef = useRef(null); // Ref for MonthTwoResponse
  const prevSectionRespRef = useRef(null);
  const [view, setView] = useState("Department");
  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);
  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);
  const location = useLocation();
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const [departmentGraph, setDepartmentGraph] = useState([]);
  const [sectionGraph, setSectionGraph] = useState([]);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SUMMARY_URL}/graph-data/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        const departmentCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts;
        const sectionCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts;
        const newResponse = response.data.response.sevenDayData;
        const monthResponse = response.data.graphData.monthGraph;

        // Check if the new response is different from the previous response
        if (
          JSON.stringify(prevResponseRef.current) !==
          JSON.stringify(newResponse)
        ) {
          setLineGraph(newResponse); // Update the state with new data
          prevResponseRef.current = newResponse; // Save the new response in ref
        }

        // Check if the new MonthResponse is different from the previous monthResponse
        if (
          JSON.stringify(prevMonthResponseRef.current) !==
          JSON.stringify(monthResponse)
        ) {
          setMonthGraph(monthResponse); // Update the state with new data
          prevMonthResponseRef.current = monthResponse; // Save the new response in ref
        }
        if (
          JSON.stringify(prevDeptResponseRef.current) !==
          JSON.stringify(departmentCount)
        ) {
          setDeptSafeUnsafe(departmentCount); // Update the state with new data
          setDepartmentGraph(departmentCount);
          prevDeptResponseRef.current = departmentCount; // Save the new response in ref
        }

        if (
          JSON.stringify(prevSectionRespRef.current) !==
          JSON.stringify(sectionCount)
        ) {
          setSectionSafeUnsafe(sectionCount); // Update the state with new data
          setSectionGraph(sectionCount);
          prevSectionRespRef.current = sectionCount; // Save the new response in ref
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [tooltipData, setTooltipData] = useState([]);

  const fetchtooltipData = async () => {
    try {
      const response = await notifyApi.get(`/cards`, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });
      setTooltipData(response?.data?.data);
    } catch { }
  };

  useEffect(() => {
    fetchData();
    fetchtooltipData();
  }, []); // Run once when the component mounts

  const formatTimestampGraph = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleString("en-US", options);
  };
  if (skinMode === "dark") {
    document.body.classList.add('dark-skin'); // Add a class to the body
  } else {
    document.body.classList.remove('dark-skin'); // Remove it when it's not dark mode
  }

  // Clean data to replace "Unknown" with "N/A"
  const cleanData = (data, type) =>
    data.map((item) => ({
      ...item,
      [type]: item[type] === "Unknown" ? "N/A" : item[type],
    }));

  // Simulate fetching the data and setting the state
  useEffect(() => {
    setDepartmentGraph(cleanData(departmentGraph, "department"));
    setSectionGraph(cleanData(sectionGraph, "section"));
  }, []);

  const resetSVGIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
  </svg>
`;

  const seriesOne = [
    {
      name: "Antivirus Not Installed",
      data: lineGraph?.map((item) => item.getAntivirusNotInstalledCount),
    },
    {
      name: "Antivirus Not Updated",
      data: lineGraph?.map((item) => item.AntivirusNotUpdatedCount),
    },
    {
      name: "Antivirus Malfunction",
      data: lineGraph?.map((item) => item.getAntivirusMalfunctionCount),
    },
    {
      name: "EDR Not Installed",
      data: lineGraph?.map((item) => item.getEDRNotInstalledCount),
    },
    {
      name: "Firewall Off",
      data: lineGraph?.map((item) => item.firewallOffPCCount),
    },
    {
      name: "Network Violation",
      data: lineGraph?.map((item) => item.getNetworkViolationPCCount),
    },
    {
      name: "Unwanted Ports",
      data: lineGraph?.map((item) => item.getPCsCountWithUnwantedPorts),
    },
    {
      name: "Unwhitelisted USB",
      data: lineGraph?.map((item) => item.getPCsCountWithUnwhitelistedUSB),
    },
    {
      name: "TPM Not Enabled",
      data: lineGraph?.map((item) => item.tpmNotEnableedCount),
    },
    {
      name: "Secure Boot Not Enabled",
      data: lineGraph?.map((item) => item.isSecureBootNotEnableedCount),
    },
    {
      name: "OS Not Hardened",
      data: lineGraph?.map((item) => item.isOsNotHardenedCount),
    },
    {
      name: "Admin Password Age Exceeded",
      data: lineGraph?.map(
        (item) => item.adminPasswordAgeGreaterThanThresholdCount
      ),
    },
    {
      name: "Multiple Admins",
      data: lineGraph?.map((item) => item.multipleAdminCount),
    },
    {
      name: "OS Not Patched",
      data: lineGraph?.map((item) => item.getNotPatchedOSCount),
    },
  ];

  const optionOne = {
    chart: {
      type: "area",
      height: 430,
      toolbar: {
        tools: {
          download: resetSVGIcon,
          customIcons: []
        }
      },
    },

    title: {
      text: "Weekly Security Overview",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      categories: lineGraph?.map((item) => formatTimestampGraph(item.date)), // X-axis based on date
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        // text: "Count",
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd MMM yyyy",
      },
    },
    colors: [
      "#1E90FF", // Dodger Blue
      "#FF4500", // Orange Red
      "#32CD32", // Lime Green
      "#FFD700", // Gold
      "#FF6347", // Tomato
      "#40E0D0", // Turquoise
      "#8A2BE2", // Blue Violet
      "#ff0000", // Deep Pink
      "#00BFFF", // Deep Sky Blue
      "#FF69B4", // Hot Pink
      "#98FB98", // Pale Green
      "#FF00FF", // Magenta
      "#8B4513", // Saddle Brown
      "#4682B4", // Steel Blue
      "#D2691E", // Chocolate
    ],
  };

  const series = (monthGraph || []).map((item = {}) => ({
    name: formatTimestampGraph(item.date || ""),
    data: [
      item.getAntivirusNotInstalledCount || 0,
      item.AntivirusNotUpdatedCount || 0,
      item.getAntivirusMalfunctionCount || 0,
      item.getEDRNotInstalledCount || 0,
      item.firewallOffPCCount || 0,
      item.getNetworkViolationPCCount || 0,
      item.getPCsCountWithUnwantedPorts || 0,
      item.getPCsCountWithUnwhitelistedUSB || 0,
      item.tpmNotEnableedCount || 0,
      item.isSecureBootNotEnableedCount || 0,
      item.isOsNotHardenedCount || 0,
      item.adminPasswordAgeGreaterThanThresholdCount || 0,
      item.multipleAdminCount || 0,
      item.getNotPatchedOSCount || 0,
    ],
  }));

  const options = {
    chart: {
      type: "bar",
      height: 600,
      stacked: true,
      toolbar: {
        tools: {
          download: resetSVGIcon,
          customIcons: []
        }
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "35%", // Adjust bar width if necessary
        horizontal: false,
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"], // Label color
        fontSize: "12px",  // Optional: Adjust font size
        fontFamily: "Helvetica, Arial, sans-serif", // Optional: Adjust font family
      },
      offsetY: 2,  // Increase this to move labels higher above the bars
      background: {
        enabled: false, // Ensure there's no background around labels
      },
      dropShadow: {
        enabled: false, // Disable any shadow to avoid overlap
      },
      align: 'center',  // Center the data label within the bars
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"], // No borders around bars
    },

    xaxis: {
      categories: [
        "AV Not Installed",
        "AV Not Updated",
        "AV Malfunction",
        "EDR Not Installed",
        "Firewall Off",
        "Network Violations",
        "Unwanted Open Ports",
        "USB Violations",
        "TPM Not Enabled",
        "Secure Boot Not Enabled",
        "OS Not Hardened",
        "Old Admin Passwords",
        "Root Privilege Accounts",
        "OS Not Patched",
      ],
      labels: {
        rotate: -45, // Optional: Rotate X-axis labels if they overlap
        style: {
          fontSize: '12px', // Adjust font size of X-axis labels if needed
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },

    yaxis: {
      title: {
        // text: "Count",
      },
      labels: {
        style: {
          fontSize: "12px", // Adjust font size of Y-axis labels if needed
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },

    fill: {
      opacity: 1,
    },

    tooltip: {
      enabled: false,
      y: {
        formatter: (val) => val,
      },
    },

    title: {
      text: "Monthly Security Overview",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#263238",
      },
    },

    colors: ["#008FFB", "#FF4560"], // Adjust colors of bars if necessary
  };

  var TotalWindowEndpoint = (windowsSummary?.totalClients / totalDashboard) * 100;

  var totalWindowCount =
    windowsSummary?.totalSafeClients +
    windowsSummary?.totalUnsafeClients +
    windowsSummary?.totalUnresponsivelients;

  var window_Safe_Per =
    (windowsSummary?.totalSafeClients / windowsSummary?.totalClients) * 100;
  var window_UnSafe_Per =
    (windowsSummary?.totalUnsafeClients / windowsSummary?.totalClients) * 100;
  var window_UnResponsive_Per =
    (windowsSummary?.totalUnresponsivelients / windowsSummary?.totalClients) * 100;

  window_Safe_Per = Math.round(window_Safe_Per);
  window_UnSafe_Per = Math.round(window_UnSafe_Per);
  window_UnResponsive_Per = Math.round(window_UnResponsive_Per);
  TotalWindowEndpoint = Math.round(TotalWindowEndpoint);


  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const formatCamelCase = (str) => {
    return str
      .replace(/isOsNotHardenedCountSet/g, "OS Not Hardened")
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^./, (char) => char.toUpperCase())
      .replace(/Total Clients/g, "Total Endpoints")
      .replace(/Total Safe Clients/g, "Compliant Endpoints")
      .replace(/Total Unsafe Clients/g, "Non-compliant Endpoints")
      .replace(/Total Unresponsivelients/g, "Unresponsive Endpoints")
      .replace(/Mulitple Admins/g, "Multiple Root Privilege Account")
      .replace(/Bios Battery Health/g, "BIOS Battery Unserviceable")
      .replace(/Additional Process Hardening Info Count/g, "Process Not Hardened")
      .replace(/Zen Program Not Install PCCount/g, "ZENWorks Not Installed")
      .replace(/Certificate Client Program Not Install PCCount/g, "Certificate Not Available")
      .replace(/Cd Drive Enabled/g, "CD/DVD Drive Enabled")
      .replace(/Shared Folder/g, "Shared Folders Detected")
      .replace(/Fips Not Enableed/g, "FIPS Not Enabled")
      .replace(/Blue Tooth Count/g, "Bluetooth Enabled")
      .replace(/Wifi Pc Count/g, "Wi-Fi Enabled")
      .replace(/Blacklisted Software/g, "Unrecognized Software")
      .replace(/File Integrity Check Failed Count/g, "File Integrity Check Failed")
      .replace(/Is OS Not Hardened Count Set/g, "OS Not Hardened")
      .replace(/Is Secure Boot Not Enableed Count/g, "Secure Boot Not Enabled")
      .replace(/Tpm Not Enabled/g, "TPM Not Available")
      .replace(/Communication Violation/g, "Unknown Network Communication")
      .replace(/Unwanted Ports/g, "Unwanted Open Ports")
      .replace(/Open Network Connection/g, "Unrecognized IP Addresses")
      .replace(/Os/g, "OS ")
      .replace(/Av /g, "AV ")
      .replace(/Edr /g, "EDR ")
      .replace(/Napnac /g, "NAP/NAC ")
      .replace(/Malaware /g, "Malware ")
      .replace(/Firwall /g, "Firewall ")
      .replace(/Network Violation/g, "Unrecognized IP Address")
      .replace(/ Violation/g, " Violations")
      .replace(/ Ram /g, " RAM ")
      .replace(/ Cpu /g, " CPU ")
      .replace(/Rdp /, "RDP ")
  };

  const exportToPDF = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(windowsSummary).map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries({ ...windowsAlertSummary, virusIntrusion: 0 })
        .filter(([key]) =>
          key !== "windowsNotActivated" &&
          key !== "scannerInstalled" &&
          key !== "outOfDomain" &&
          key !== "openNetworkConnection" &&
          key !== "napnacDisabled" &&
          key !== "malawareDetected" &&
          key !== "edrMalfunction"
        )
        .map(([key, value]) => ({
          category: formatCamelCase(key),
          count: value,
        })).sort((a, b) => a.category.localeCompare(b.category)),
    ];


    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );

    doc.setFontSize(15);
    doc.text(`Window Summary Data`, 14, 22);

    const tableColumn = [
      "S. No.",
      "Category",
      "Total Count",
    ];

    const tableRows = data?.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
    });

    doc.save("window_summary_data.pdf");
  };

  const exportToCSV = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(windowsSummary).map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries({ ...windowsAlertSummary, virusIntrusion: 0 })
        .filter(([key]) => ![
          "windowsNotActivated",
          "scannerInstalled",
          "outOfDomain",
          "openNetworkConnection",
          "napnacDisabled",
          "malawareDetected",
          "edrMalfunction",
        ].includes(key))
        .map(([key, value]) => ({
          category: formatCamelCase(key),
          count: value,
        })).sort((a, b) => a.category.localeCompare(b.category)),
    ];


    const tableHeaders = ["S. No.", "Category", "Total Count"];

    const tableRows = data.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    const csvContent = [
      `Organization: ${orgName}, Exported: ${exportDateTime}`,
      "Window Summary Data",
      "",
      tableHeaders.join(","),
      ...tableRows.map(row => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "window_summary_data.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sortedAlerts = [
    {
      alertIcon: avBlueIcon,
      alertTitle: "AV Not Installed",
      alertNumber: windowsAlertSummary?.avNotInstalled || 0,
      apiUrl: ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: avRedIcon,
      alertTitle: "AV Malfunction",
      alertNumber: windowsAlertSummary?.avMalfunction || 0,
      apiUrl: ANTI_VIRUS_MALFUNCTION_ENDPOINT,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Malfunction"
      )[0]?.cardDescription,
    },
    {
      alertIcon: avBlackIcon,
      alertTitle: "AV Not Updated",
      alertNumber: windowsAlertSummary?.avNotUpdated || 0,
      apiUrl: ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Not Updated"
      )[0]?.cardDescription,
    },
    {
      alertIcon: edr,
      alertTitle: "EDR Not Installed",
      alertNumber: windowsAlertSummary?.edrNotInstalled || 0,
      apiUrl: EDR_NOT_INSTALLED_ENDPOINT,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "EDR Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: fireIcon,
      alertTitle: "Firewall Off",
      alertNumber: windowsAlertSummary?.firwallOff || 0,
      apiUrl: FIREWALL_OFF,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Firewall Off"
      )[0]?.cardDescription,
    },
    {
      alertIcon: usbIcon,
      alertTitle: "USB Violations",
      alertNumber: windowsAlertSummary?.usbViolation || 0,
      apiUrl: USB_VOILATION,
      routeUrl: "/windows-usb-violation",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "USB Violations"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unrecog,
      alertTitle: "Unrecognized IP Address",
      alertNumber: windowsAlertSummary?.networkViolation || 0,
      apiUrl: NETWORK_INFO_DATA,
      routeUrl: "/windows-severity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unrecognized IP Address"
      )[0]?.cardDescription,
    },
    {
      alertIcon: openport,
      alertTitle: "Unwanted Open Ports",
      alertNumber: windowsAlertSummary?.unwantedPorts || 0,
      apiUrl: UNWANTED_PORT,
      routeUrl: "/windows-severity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unwanted Open Ports"
      )[0]?.cardDescription,
    },
    {
      alertIcon: usbviolation,
      alertTitle: "Unknown Network Communication",
      alertNumber: windowsAlertSummary?.communicationViolation || 0,
      apiUrl: UNKNOWN_NETWORK_COMMUNICATION,
      routeUrl: "/windows-severity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unknown Network Communication"
      )[0]?.cardDescription,
    },
    {
      alertIcon: tpm_icon,
      alertTitle: "TPM Not Available",
      alertNumber: windowsAlertSummary?.tpmNotEnabled || 0,
      apiUrl: TPM_DISABLED,
      routeUrl: "/windows-severity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "TPM Not Available"
      )[0]?.cardDescription,
    },
    {
      alertIcon: secure,
      alertTitle: "Secure Boot Not Enabled",
      alertNumber: windowsAlertSummary?.isSecureBootNotEnableedCount || 0,
      apiUrl: IS_SECURE_BOOT_DISABLED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Secure Boot Not Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: oshardingset,
      alertTitle: "OS Not Hardened",
      alertNumber: windowsAlertSummary?.isOsNotHardenedCountSet || 0,
      apiUrl: OS_NOT_HARDENED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "OS Not Hardened"
      )[0]?.cardDescription,
    },
    {
      alertIcon: os_not_patch,
      alertTitle: "OS Not Patched",
      alertNumber: windowsAlertSummary?.osNotPatched || 0,
      apiUrl: OS_PATCHED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "OS Not Patched"
      )[0]?.cardDescription,
    },
    {
      alertIcon: virus,
      alertTitle: "Virus Intrusion",
      alertNumber: 0 || 0,
      apiUrl: SCANNER_INSTALLED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Virus Intrusion"
      )[0]?.cardDescription,
    },
    {
      alertIcon: oldPassword,
      alertTitle: "Old Admin Passwords",
      alertNumber: windowsAlertSummary?.oldAdminPasswords || 0,
      apiUrl: OLD_PASSWORD,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Old Admin Passwords"
      )[0]?.cardDescription,
    },
    {
      alertIcon: integrity,
      alertTitle: "File Integrity Check Failed",
      alertNumber: windowsAlertSummary?.fileIntegrityCheckFailedCount || 0,
      apiUrl: FILE_INTEGRITY_FAILED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "File Integrity Check Failed"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts = sortedAlerts
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );

  const sortedAlerts2 = [
    {
      alertIcon: zen,
      alertTitle: "ZENworks Not Installed",
      alertNumber: windowsAlertSummary?.zenProgramNotInstallPCCount || 0,
      apiUrl: ZEN_NOT_INSTALLED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "ZENworks Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: ssl,
      alertTitle: "Certificate Not Available",
      alertNumber: windowsAlertSummary?.certificateClientProgramNotInstallPCCount || 0,
      apiUrl: CERTIFICATE_CLIENT_NOT_INSTALLED,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Certificate Not Available"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unreccg,
      alertTitle: "Unrecognized Software",
      alertNumber: windowsAlertSummary?.blacklistedSoftware || 0,
      apiUrl: BLACKLIST_PROGRAME,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Unrecognized Software"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unknow,
      alertTitle: "Unknown Services",
      alertNumber: windowsAlertSummary?.unknownServices || 0,
      apiUrl: UNKNOWN_SERVICES,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Unknown Services"
      )[0]?.cardDescription,
    },
    {
      alertIcon: wifi,
      alertTitle: "Wi-Fi Enabled",
      alertNumber: windowsAlertSummary?.wifiPcCount || 0,
      apiUrl: WIFI_ENABLED,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Wi-Fi Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: bluetooth,
      alertTitle: "Bluetooth Enabled",
      alertNumber: windowsAlertSummary?.blueToothCount || 0,
      apiUrl: BLUETOOTH_ENABLED,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Bluetooth Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: windows_rdp_gateway,
      alertTitle: "RDP Enabled",
      alertNumber: windowsAlertSummary?.rdpEnabled || 0,
      apiUrl: RDP_ENABLED,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "RDP Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: fipsnot,
      alertTitle: "FIPS Not Enabled",
      alertNumber: windowsAlertSummary?.fipsNotEnabled || 0,
      apiUrl: IS_FIPS_DISABLE,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "FIPS Not Enabled"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts2 = sortedAlerts2
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );

  const sortedAlerts3 = [
    {
      alertIcon: publicshare,
      alertTitle: "Shared Folders Detected",
      alertNumber: windowsAlertSummary?.sharedFolder || 0,
      apiUrl: SHARED_FOLDER,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Shared Folders Detected"
      )[0]?.cardDescription,
    },
    {
      alertIcon: cddrive,
      alertTitle: "CD/DVD Drive Enabled",
      alertNumber: windowsAlertSummary?.cdDriveEnabled || 0,
      apiUrl: CD_DRIVE_ENABLED,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "CD/DVD Drive Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: hardingSet,
      alertTitle: "Process Not Hardened",
      alertNumber: windowsAlertSummary?.additionalProcessHardeningInfoCount || 0,
      apiUrl: IS_PROCESS_HARDENING_INFO,
      routeUrl: "/windows-severity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Process Not Hardened"
      )[0]?.cardDescription,
    },
    {
      alertIcon: battery,
      alertTitle: "BIOS Battery Unserviceable",
      alertNumber: windowsAlertSummary?.biosBatteryHealth || 0,
      apiUrl: BIOS_BATTERY,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "BIOS Battery Unserviceable"
      )[0]?.cardDescription,
    },
    {
      alertIcon: ram,
      alertTitle: "High RAM Usage",
      alertNumber: windowsAlertSummary?.highRamUsage || 0,
      apiUrl: IS_HIGH_RAM_USAGE,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "High RAM Usage"
      )[0]?.cardDescription,
    },
    {
      alertIcon: cpu,
      alertTitle: "High CPU Usage",
      alertNumber: windowsAlertSummary?.highCpuUsage || 0,
      apiUrl: IS_HIGH_CPU_USAGE,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "High CPU Usage"
      )[0]?.cardDescription,
    },
    {
      alertIcon: multRoot,
      alertTitle: "Multiple Root Privilege Account",
      alertNumber: windowsAlertSummary?.multipleAdmins || 0,
      apiUrl: MULTIPLE_PASSWORD,
      routeUrl: "/windows-severity",
      description: tooltipData.filter(
        (item) => item.cardName === "Multiple Root Privilege Account"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts3 = sortedAlerts3
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">

        <ol className="breadcrumb fs-sm mb-2 p-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">Dashboard</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/windows-dash" ? (
              <span className="active">Window Dashboard</span>
            ) : (
              <NavLink exact to="/windows-dash">
                Window Dashboard
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/windows-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={newWindowIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Window Dashboard</p>
          </span>

          <div className="d-flex gap-2 mt-3 mt-md-0 align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={exportToPDF}
              >
                <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
              </button>

              <button
                type="button"
                className="btn btn-success text-white"
                onClick={exportToCSV}
              >
                <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
              </button>
            </div>
          </div>
        </div>
        {!loading ? (
          <>
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Window Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={windowsSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={WINDOW_ALL_CLIENT}
                  routeUrl={"/windows-severity"}
                  cardIndex={0}
                  riskAt={TotalWindowEndpoint}
                  footerTitle={"endpoints are Window"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Compliant Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={windowsSummary?.totalSafeClients || 0}
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={SAFE_WINDOW_CLIENT}
                  routeUrl={"/windows-severity"}
                  cardIndex={1}
                  riskAt={window_Safe_Per ? window_Safe_Per : "0"}
                  footerTitle={"endpoints are secure"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Non-compliant Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={windowsSummary?.totalUnsafeClients || 0}
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={UNSAFE_WINDOW_CLIENTS}
                  routeUrl={"/windows-severity"}
                  cardIndex={2}
                  riskAt={window_UnSafe_Per ? window_UnSafe_Per : "0"}
                  footerTitle={"endpoints at risk"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Window Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={windowsSummary?.totalUnresponsivelients || 0}
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={UNRESPONSIVE_WINDOWS}
                  routeUrl={"/windows-severity"}
                  cardIndex={3}
                  riskAt={window_UnResponsive_Per ? window_UnResponsive_Per : "0"}
                  footerTitle={"endpoints unresponsive"}
                />
              </Col>
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts2.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts3.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Row className=" card-one m-1 shadow p-3">
                <Col xl="12">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="area"
                    height={430}
                    className="apex-chart-two"
                  />
                </Col>
              </Row>

              <Row className=" card-one m-1 shadow p-3">
                <Col xl="12">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={400}
                  />{" "}
                </Col>
              </Row>
            </Row>
            <Footer />
            {/* <CustomToaster /> */}
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}

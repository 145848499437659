import React, { useState, useRef, useCallback } from "react";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { notifyApi } from "../api/axiosSet";
import "./Notification.css";
import { useSelector } from "react-redux";
import { toHumanReadable } from "../components/common/toHumanReadable";

const Notification = ({
  hostname,
  eventTriggerType,
  timeStamp,
  notificationType,
  isRead,
  pcId,
  updateNotification,
  _id,
  legacyId,
  alertType
}) => {
  const navigate = useNavigate();

  const handleRouteClient = (
    pcId,
    timeStamp,
    eventTriggerType,
    _id,
    alertType,
    legacyId
  ) => {

    if (!isRead) {
      updateNotification(_id);
    }

    // Check for alertType related to WATCHDOG
    const watchdogAlerts = [
      "WATCHDOG_VERSION_ALERT",
      "WATCHDOG_FILE_ALERT",
      "WATCHDOG_SERVICE_ALERT",
      "WATCHDOG_UPDATE_ALERT",
    ];

    if (watchdogAlerts.includes(alertType)) {
      navigate("/linux-client-Details", {
        state: {
          pcInfoId: pcId,
          timeTrigger: timeStamp,
        },
      });
      return; // Exit after handling the WATCHDOG alertType
    }

    switch (true) {
      case eventTriggerType.includes("WINDOWS"):
        navigate("/windows-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("LINUX"):
        navigate("/linux-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("MAC"):
        navigate("/macOs-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("CHROME"):
        navigate("/chrome-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("ANDROID") ||
        eventTriggerType.includes("ROUTINE"):
        navigate("/android-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      default:
      // handle default case if needed
    }
  };

  return (
    <Card
      className={`notification ${isRead ? "" : "unread"} hoverable`}
      onClick={() =>
        handleRouteClient(
          pcId,
          timeStamp,
          eventTriggerType,
          _id,
          alertType,
          legacyId
        )
      }
    >
      <CardContent style={{ padding: "16px", display: "flex", alignItems: "center" }}>
        <IconButton style={{ marginRight: "16px" }}>
          <Notifications style={{ color: isRead ? "#aaa" : "red" }} />
        </IconButton>
        <div style={{ flex: 1 }}>
          <Typography variant="body1" style={{ fontWeight: isRead ? "400" : "600" }}>
            {hostname}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {eventTriggerType || alertType}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {timeStamp?.replace(", ", " ")} &bull;{" "}
            {notificationType === "TRIGGER" ? "Trigger" : toHumanReadable(notificationType)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const NotificationListMain = ({
  notificationsUnread,
  notificationCountUnread,
  notificationsAll,
  notificationCountAll,
  onNotificationClick,
  onClearAll,
  onChange,
  setPageUnread,
  setPageAll,
}) => {
  const { user } = useSelector((state) => state.authSlice);

  const [activeTab, setActiveTab] = useState("unread");
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };


  const updateNotification = (notificationID) => {
    notifyApi
      .post(
        `/update-notification/by/${notificationID}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then(() => {
        onChange();
      })
      .catch(() => {
        // Handle errors if needed
      });
  };

  const markAllAsRead = () => {
    if (activeTab === "all") {
      const updatedNotifications = notificationsAll.map((notification) => ({
        ...notification,
        isRead: true,
      }));
      onClearAll();
    } else if (activeTab === "unread") {
      const updatedNotifications = notificationsUnread.map((notification) => ({
        ...notification,
        isRead: true,
      }));
      onClearAll();
    }
    onChange();
  };

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          activeTab === "unread" ? setPageUnread((prevPage) => prevPage + 1) :
            setPageAll((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [setPageAll, setPageUnread, activeTab]
  );

  const currentNotifications =
    activeTab === "unread" ? notificationsUnread : notificationsAll;

  const notificationsToDisplay = Array.isArray(currentNotifications)
    ? currentNotifications
    : [];

  return (
    <div
      className="notification-list rounded-3"
    >
      {/* Tabs Section */}
      <div className="tabs">
        <Typography
          variant="button"
          className={`tab ${activeTab === "unread" ? "active" : ""}`}
          onClick={() => handleTabChange("unread")}
        >
          Unread ({notificationCountUnread})
        </Typography>
        <Typography
          variant="button"
          className={`tab ${activeTab === "all" ? "active" : ""}`}
          onClick={() => handleTabChange("all")}
        >
          All ({notificationCountAll})
        </Typography>
        <button className="mark-all-read" onClick={markAllAsRead}>
          Mark all as read
        </button>
      </div>

      {/* Scrollable Notifications List */}
      <div
        className="notification-list-container"
        style={{
          maxHeight: "400px",
          overflowY: "auto"
        }}
      >
        <ul className="list-group list-group-flush px-2">
          {notificationsToDisplay.map((notification, index) => {
            const isLastElement = index === notificationsToDisplay.length - 1;
            return (
              <div key={index} ref={isLastElement ? lastElementRef : null}>
                <Notification
                  {...notification}
                  updateNotification={updateNotification}
                  onClick={onNotificationClick}
                />
              </div>
            );
          })}
        </ul>
      </div>

      {/* Footer */}
      <div
        className="card-footer text-body p-1 rounded-3"
      >
        <p onClick={() => navigate("/all-notification")}>Show all Notifications</p>
      </div>
    </div>
  );
};

export default NotificationListMain;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import CustomTableLinuxUsb from "../../components/common/Table/CustomTableLinuxUsb";
import { linuxApi } from "../../api/axiosSet";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function LinuxSeverityUsb() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();
  const navigate = useNavigate();

  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (!state?.callUrl) {
      navigate("/")
    }
  }, [state?.callUrl])

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async (bool) => {
    let isMounted = true;
    if (bool) {
      setPageNumber(1);
    }
    try {
      setLoading(true);
      let url = `/${state?.callUrl}/${user?.data?.user?.userId}`;
      const selectedDepartment = state?.selectedDepartment;
      const selectedSection = state?.selectedSection;
      const selectedSubsection = state?.selectedSubsection;
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${selectedSection.value
          }`;
      }
      if (selectedSubsection) {
        url += `${selectedDepartment || selectedSection ? "&" : "?"
          }subSection=${selectedSubsection.value}`;
      }
      if (selectedDepartment || selectedSection || selectedSubsection) {
        url += `&page=${pageNumber}&pageSize=${pageSize}`
      }
      else {
        url += `?page=${pageNumber}&pageSize=${pageSize}`
      }
      if (searchTerm) {
        url += `&identifier=${searchTerm}`;
      }
      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      if (isMounted) {

        if (response?.data?.data.length > 0) {
          const calculateData = response?.data?.data?.map((v) => {
            let returnData = {
              hostname: v?.hostname,
              serialNumber: v?.serialNumber,
              ipAddress: v?.ipAddress,
              openTcpPorts: v?.openTcpPorts,
              usbName: v?.usbName?.split(" ") || [],
              usbSerialNumber: v?.usbSerialNumber,
              macAddress: v?.macAddress,
              usbManufacturer: v?.usbManufacturer,
              usbProductId: v?.usbProductId,
              usbVendorId: v?.usbVendorId,
              localAddress: v?.localAddress,
              localPort: v?.localPort,
              foreignAddress: v?.foreignAddress,
              foreignPort: v?.foreignPort,
              time: v?.time,
              falsePositive: v?.falsePositive,
              usbHistoryId: v?.usbHistoryId,
              action: "yourActionValue",
              pc_Id: v?.pcId,
              adminActionCount: v?.adminActionCount,
              IsadminActionDone: v?.IsadminActionDone,
              foreignAddressCount: v?.foreignAddressCount,
              pcId: v?.pcId,
              updatedAt: v?.updatedAt
            };
            const title = state?.titleState;
            if (title === "TPM Not Available") {
              returnData = {
                ...returnData,
                tpmStatus: "Not Available"
              }
            }
            return returnData;
          });
          setColumnData(calculateData);
        } else {
          setColumnData([]);
        }
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    fetchData(false);
  }, [user, state, pageNumber, pageSize]);

  useEffect(() => {
    fetchData(true);
  }, [user, state, searchTerm]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {

      const hasData = (key) => key === "usbName" ? columnData?.some((item) => item[key] && item[key]?.length > 0) : columnData?.some((item) => item[key]);

      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Usb Name", key: "usbName" },
        { label: "Usb Serial Number", key: "usbSerialNumber" },
        { label: "Mac Address", key: "macAddress" },
        { label: "Usb Manufacturer", key: "usbManufacturer" },
        { label: "Usb Product Id", key: "usbProductId" },
        { label: "Usb Vendor Id", key: "usbVendorId" },
        { label: "Local Address", key: "localAddress" },
        { label: "Local Port", key: "localPort" },
        { label: "Foreign Address", key: "foreignAddress" },
        { label: "Foreign Port", key: "foreignPort" },
        { label: "Open Ports", key: "openTcpPorts" },
        { label: "Count", key: "foreignAddressCount" },
        { label: "TPM Status", key: "tpmStatus" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Time", key: "time" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses?.split(" ").join(" | ");
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatTcpPorts = (openTcpPorts) => {
        return openTcpPorts
          .map((port) => port)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const csvData = [
        tableColumn,
        ...columnData.map((item, index) => [
          index + 1,
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "updatedAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? item[header.key]
                    .map(
                      (user) =>
                        `${user.userType} :${user.userName}: ${user.passwordAge}`
                    )
                    .join("; ")
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "openTcpPorts" ? formatTcpPorts(item[header.key])
                          : item[header.key]
          )
        ]),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        `${state?.titleState}`,
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {

      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(14);
      doc.text(`${state?.titleState}`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => key === "usbName" ? columnData?.some((item) => item[key] && item[key]?.length > 0) : columnData?.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Usb Name", key: "usbName" },
        { label: "Usb Serial Number", key: "usbSerialNumber" },
        { label: "Mac Address", key: "macAddress" },
        { label: "Usb Manufacturer", key: "usbManufacturer" },
        { label: "Usb Product Id", key: "usbProductId" },
        { label: "Usb Vendor Id", key: "usbVendorId" },
        { label: "Local Address", key: "localAddress" },
        { label: "Local Port", key: "localPort" },
        { label: "Foreign Address", key: "foreignAddress" },
        { label: "Foreign Port", key: "foreignPort" },
        { label: "Open Ports", key: "openTcpPorts" },
        { label: "Count", key: "foreignAddressCount" },
        { label: "TPM Status", key: "tpmStatus" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Time", key: "time" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses?.split(" ").join(" | ");
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      columnData.forEach((item, index) => {
        const rowData = [index + 1, ...headers.map((header) =>
          header.key === "ipAddress"
            ? formatMultipleIpAddresses(item[header.key])
            : header.key === "updatedAt"
              ? formatTimestamp(item[header.key])
              : header.key === "sharedDirectories"
                ? formatSharedDirectories(item[header.key])
                : header.key === "usersPasswordAge"
                  ? item[header.key]
                    .map(
                      (user) =>
                        `${user.userType} :${user.userName}: ${user.passwordAge}`
                    )
                    .join("; ")
                  : header.key === "avDetails"
                    ? formatAvDetails(item[header.key])
                    : header.key === "service"
                      ? formatOsDetails(item[header.key])
                      : Array.isArray(item[header.key])
                        ? item[header.key].join(", ")
                        : item[header.key]
        )];
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 25,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 2, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  }

  const location = useLocation();

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <ol className="breadcrumb fs-sm mb-2 p-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">{`Dashboard`}</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-dash" ? (
              <span className="active">{`Linux Dashboard`}</span>
            ) : (
              <NavLink exact to="/linux-dash">
                Linux Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-severity-usb" ? (
              <span className="active">{state?.titleState}</span>
            ) : (
              <NavLink exact to="/linux-severity-usb">
                {state?.titleState}
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal ps-2">
            <Link to="/" className="shadow" style={{ borderRadius: "25px" }} >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Dashboard</p>
          </span>
          {/* <div className="d-flex justify-content-center align-items-center">
            <Button className="btn btn-primary rounded-md d-flex align-items-center gap-2 back-button" onClick={() => navigate(-1)} >
              <i className="ri-arrow-left-line fs-18 lh-1 mr-2" style={{ verticalAlign: 'middle', marginRight: '3px' }}></i>
              <span>Back</span>
            </Button>
          </div> */}
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div className="col-xl">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">
              {state?.titleState.includes("Comm") === true ? "Unknown Network Communication" : state?.titleState || "Alert Title"}
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0} {state?.titleState.includes("Violations") === true ? "Violations" : "Endpoints"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="" style={{ position: "relative", width: "17rem" }}>
              <input
                style={{ paddingRight: "1.3rem" }}
                className="form-control"
                type="text"
                placeholder="Search by Hostname or Serial No."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
            </div>
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div xl={12} className="card rounded mb-2 shadow cardStyle mt-2">
          {!loading ? (
            <CustomTableLinuxUsb
              rowData={columnData}
              columnData={columnData}
              routeUrl={"/linux-client-Details"}
              currentPage={pageNumber}
              pageSize={pageSize}
              totalDocuments={totalDocuments}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              titleState={state?.titleState}
            />
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LinuxSeverityUsb;

import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Main from "../layouts/Main";
import NotFound from "../pages/NotFound";
import { logout } from "../feature/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdminRoutes from "../routes/AdminRoutes";
import UserAdminRoutes from "../routes/UserAdminRoutes";
import OrganizationAdmin from "../routes/OrganizationAdmin";
import publicRoutes from "../routes/PublicRoutes";
import protectedRoutes from "../routes/ProtectedRoutes";
import useInactivityTimer from "../components/common/userInactivityTimer";
// import { useSidebarToogle } from "../components/SidebarToogleContext";

function AppRoutes() {
    const { user, isAuthenticated } = useSelector((state) => state.authSlice);
    // const { sidebarCollapsed, toggleSidebarCollapsed } = useSidebarToogle();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const expireTime = 900 * 1000;
    const UserType = user?.data?.user?.roleName;

    const handleLogout = () => {
        toast.warn("You have been logged out due to inactivity.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
        });
        setTimeout(async () => {
            await dispatch(logout());
            navigate("/");
        }, 2000);
    };

    useInactivityTimer(user, expireTime, handleLogout);

    // useEffect(() => {
    //     const mediaQuery = window.matchMedia("(max-width: 1000px)");

    //     const handleMediaChange = () => {
    //         if (mediaQuery.matches && !sidebarCollapsed) {
    //             toggleSidebarCollapsed();
    //         }
    //     };

    //     handleMediaChange();
    //     mediaQuery.addEventListener("change", handleMediaChange);

    //     return () => {
    //         mediaQuery.removeEventListener("change", handleMediaChange);
    //     };
    // }, [sidebarCollapsed, toggleSidebarCollapsed]);

    return (
        <Routes>
            {isAuthenticated ? (
                <Route path="/" element={<Main />}>
                    {UserType === "Super Admin" &&
                        protectedRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Guest Admin" &&
                        protectedRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Department Admin" &&
                        protectedRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Section Admin" &&
                        protectedRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "subSection Admin" &&
                        protectedRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Organization Admin" &&
                        OrganizationAdmin.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Ca Admin" &&
                        AdminRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                    {UserType === "Super Admin" &&
                        UserAdminRoutes.map((route, index) => (
                            <Route path={route.path} element={route.element} key={index} />
                        ))}
                </Route>
            ) : (
                publicRoutes.map((route, index) => (
                    <Route path={route.path} element={route.element} key={index} />
                ))
            )}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default AppRoutes;
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Margin, usePDF } from "react-to-pdf";
import HistoryCard from "./HistoryCard";
import { Dropdown, Menu } from "antd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useLocation } from "react-router-dom";

function GeneratePdf({
  osData,
  networkData,
  basicInfoData,
  hardwareInfoData,
  usbInfoData,
  bluetoothInfoData,
  installedProgrameData,
  serviceProgrameData,
  startUpProgrameData,
  recentEventData,
  establishedConnection,
  highCpuusage,
  ramUsage,
  securityControls,
  accountDetail,
  osPatchInformation,
  sharedDir,
  internalHardDrive,
  usbFileData,
  avCards,
  opticalDriveInfo,
  nonWhitelistApps,
  nonWhitelistServices,
  fileIntegrityInfo,
  adminConfig,
  blacklistedIPAddress,
}) {
  const { state } = useLocation();


  function convertDateFormat(dateStr) {
    const regex = new RegExp(
      `^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)\\s(\\d{1,2})\\s` +
      `(January|February|March|April|May|June|July|August|September|October|November|December)\\s` +
      `(\\d{4})\\s(0?[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])\\s(AM|PM)\\s` +
      `(IST|GMT|UTC|[A-Z]{3})$`
    );

    const monthMap = {
      "January": "01", "February": "02", "March": "03", "April": "04", "May": "05", "June": "06",
      "July": "07", "August": "08", "September": "09", "October": "10", "November": "11", "December": "12"
    };

    const match = dateStr.match(regex);
    if (!match) return dateStr;

    const [, , day, month, year, hour, minute, second, period] = match;

    // Convert hour to 24-hour format
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) hour24 += 12;
    if (period === "AM" && hour24 === 12) hour24 = 0;

    const pad = (num) => num.toString().padStart(2, '0');

    return `${pad(day)}-${monthMap[month]}-${year} ${pad(hour24)}:${pad(minute)}:${pad(second)}`;
  }

  const menuAvService = (record) => {
    return (
      <Menu>
        {record.services?.map((serviceObj, index) => {
          const serviceName = Object.keys(serviceObj)[0];
          const serviceStatus = serviceObj[serviceName];
          return (
            <Menu.Item key={index}>
              {serviceName}:
              <span
                className={`badge rounded-pill ${serviceStatus === "active"
                  ? "text-bg-success text-white"
                  : "text-bg-danger text-white"
                  }`}
                disabled
              >
                {serviceStatus}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const [loading, setLoading] = useState(false);

  const pdfName = basicInfoData[0]?.["Serial Number"];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const [show, setShow] = useState(false);

  const { toPDF, targetRef } = usePDF({
    filename: `${pdfName}-endpoint-report.pdf`,
    page: {
      margin: Margin.SMALL,
      format: "A4",
      orientation: "landscape",
    },
  });

  const handleGeneratePDF = async () => {
    setShow(true);
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await toPDF();
    } catch (error) {
      alert("An error occurred while generating the PDF. Please try again.");
    } finally {
      setLoading(false);
      setShow(false);
    }
  };

  return (

    <div>
      <button
        type="button"
        className="btn btn-primary text-white"
        onClick={() => handleGeneratePDF()}
      >
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <><i className="ri-file-pdf-2-fill"></i> &nbsp; PDF</>
        )}

      </button>

      {show && <div
        ref={targetRef}
        className="mx-2"
        style={{
          position: "absolute",
          left: "-9999px",
          top: "-9999px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <p className="text-primary fw-bold text-center fs-2 py-2">
          {basicInfoData[0]?.Hostname}
        </p>
        <p className="fw-bold text-center fs-14">{new Date().toLocaleString()}</p>
        <div className="d-flex flex-column gap-2">
          <div className="row row-cols-4 g-2">
            <div className="col">
              <HistoryCard infoIcon={false} headerName={"Basic Information"}>
                {basicInfoData?.length > 0 ? (
                  basicInfoData?.map((item, index) => (
                    <div key={index} className="my-2">
                      {Object.entries(item).map(
                        ([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4">
                              <strong>{key}</strong>
                            </p>
                            <div
                              className="p-0 m-0 fw-medium fs-11 text-end col-6"
                              style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                            >
                              {value || "NA"}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <div className="py-2">
                    <p className="p-0 m-0">Basic Info is not available</p>
                  </div>
                )}
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false} headerName={"Hardware Information"}>
                {hardwareInfoData?.length > 0 ? (
                  hardwareInfoData[0]?.map((item, index) => (
                    <div key={index} className="my-2">
                      {Object.entries(item)?.map(
                        ([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            {key === "RAM" ? (
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu className="p-2 card-one shadow-md">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Total
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.totalRAM || "NA"}
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between ">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Used
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.usedRAM || "NA"}
                                      </p>
                                    </div>

                                    {/* <div className="d-flex align-items-center justify-content-between">
                                            <p className="p-0 m-0 fw-medium fs-10 fw-semibold text-end">
                                              Available
                                            </p>
                                            <p className="p-0 m-0  fs-10 fw-semibold text-end">
                                              {hardwareInfoData[1]?.ramData
                                                ?.availableRAM || "NA"}
                                            </p>
                                          </div> */}
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Free
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.availableRAM || "NA"}
                                      </p>
                                    </div>
                                  </Menu>
                                }
                              >
                                <p
                                  className="p-0 m-0 fw-medium fs-11 text-primary  w-50 text-end "
                                  style={{ cursor: "pointer" }}
                                >
                                  {value}
                                </p>
                              </Dropdown>
                            ) : (
                              key === 'BIOS Battery State' ?
                                <div className="p-0 m-0 fw-medium fs-11 text-end" style={{ color: value === 'Unserviceable' ? 'red' : 'inherit' }}>
                                  {value}
                                </div> : <div className="p-0 m-0 fw-medium fs-11 text-end">
                                  {value}
                                </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <div className="py-2">
                    <p className="p-0 m-0">
                      Hardware Info is not available
                    </p>
                  </div>
                )}
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false} headerName={"OS Information"}>
                {osData?.length > 0 ? (
                  osData?.map((item, index) => (
                    <div key={index} className="my-2">
                      {Object.entries(item).map(
                        ([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            <p
                              className={` m-0 fw-medium text-end fs-11 ${value === true
                                ? "text-success"
                                : "text-dark"
                                }`}
                            >
                              {typeof value === "boolean"
                                ? value.toString()
                                : value}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <div className="py-2">
                    <p className="p-0 m-0">Os Info is not available</p>
                  </div>
                )}
              </HistoryCard>
            </div>
            <div className="col position-relative">
              <HistoryCard infoIcon={false}
                headerName={"Network Information"}
                paddingBottom={true}
              >
                {/* <p className="fs-9 p-0 my-2 text-primary fw-semibold">
                        Current Network
                      </p> */}

                {networkData?.length > 0 ? (
                  networkData[0]?.map((item, index) => (
                    <div key={index} className="my-2">
                      {Object.entries(item)?.map(([key, value], index2) => (
                        <div
                          key={index2}
                          className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                        >
                          <p className="p-0 fw-medium m-0 fs-11">
                            <strong>{key}</strong>
                          </p>
                          <div className="fw-medium fs-11 text-end col-6">
                            {key === "DNS Server" ? (
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu className="card-one shadow-md">
                                    {value?.map((v, i) => (
                                      <Menu.Item key={i}>
                                        <span className="fw-medium fs-11 fw-semibold text-end">
                                          {v}
                                        </span>
                                      </Menu.Item>
                                    ))}
                                  </Menu>
                                }
                                className="p-0 m-0"
                              >
                                <p
                                  className="fw-medium fs-11 text-primary text-end ml-4rem-important"
                                  style={{ cursor: "pointer" }}
                                >
                                  {value[0]}
                                </p>
                              </Dropdown>
                            ) : key === "IP Address" ? (
                              <div>
                                {value ? value
                                  .split(" ")
                                  .map((ip, i) => (
                                    <p key={i} style={{ color: blacklistedIPAddress?.includes(ip) ? "red" : "inherit" }} className="m-0">
                                      {ip}
                                    </p>
                                  )) : "NA"}
                              </div>
                            ) : key === "Network Category" && state?.title === "Wi-Fi Enabled" && value === "Wifi" ? (
                              <div style={{ color: "red" }}>
                                {value || "NA"}
                              </div>
                            ) : (
                              value || "NA"
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <div className="py-2">
                    <p className="p-0 m-0">
                      Network Info is not available
                    </p>
                  </div>
                )}
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col-6">
              <HistoryCard infoIcon={false}
                headerName={"AV Information"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Name",
                      selector: (v, index) => (
                        <Tippy maxWidth="none" content={v?.name}>
                          <div>{v?.name}</div>
                        </Tippy>
                      ),
                      width: "110px",
                    },
                    {
                      name: "Status",
                      selector: (v) => (
                        <span
                          className={`badge rounded-pill ${v.installed === true
                            ? "text-bg-success text-white"
                            : "text-bg-danger text-white"
                            }`}
                          disabled
                        >
                          {v.installed === true ? "Active" : "InActive"}
                        </span>
                      ),
                      width: "120px",
                    },
                    {
                      name: "Version",
                      selector: (v) => v?.version,
                      cell: (row) => {
                        const avDetailsFromConfig = adminConfig?.avDetails;
                        for (let i = 0; i < avDetailsFromConfig?.length; i++) {
                          if (avDetailsFromConfig[i]?.avName === row?.name) {
                            return <Tippy maxWidth="none" content={row?.version}>
                              <div style={{ color: avDetailsFromConfig[i]?.avVersion !== row?.version && "red" }}>
                                {row?.version}
                              </div>
                            </Tippy>
                          }
                        }
                        return <Tippy maxWidth="none" content={row?.version}>
                          <div>{row?.version}</div>
                        </Tippy>;
                      },
                      width: "120px",
                    },

                    {
                      name: "Services",
                      selector: (row) => row?.services,
                      cell: (row) => {
                        const avArray = row?.services || [];

                        if (avArray?.length === 1) {
                          const serviceName = Object.keys(avArray[0])[0];
                          const serviceStatus = avArray[0][serviceName];
                          return (
                            <span style={serviceStatus !== "active" ? { color: "red" } : {}}>
                              {serviceName}: {serviceStatus}
                            </span>
                          );
                        } else if (avArray?.length > 1) {
                          return (
                            <Dropdown
                              overlay={menuAvService(row)}
                              trigger={["click"]}
                            >
                              <Button className="bg-transparent border-0 p-0 m-0" style={{ height: "100%" }}>
                                {avArray
                                  .map((serviceObj, index) => {
                                    const serviceName =
                                      Object.keys(serviceObj)[0];
                                    const serviceStatus =
                                      Object.values(serviceObj)[0]
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: 'inline',
                                          color: serviceStatus !== "active" ? "red" : "inherit"
                                        }}
                                      >
                                        {serviceName}{index !== avArray.length - 1 ? ", " : ""}
                                      </div>
                                    );
                                  })}
                              </Button>
                            </Dropdown>
                          );
                        } else {
                          return <span>NA</span>;
                        }
                      },
                      width: "340px", // Adjust width if necessary
                    },
                  ]}
                  data={avCards[0]}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      AV is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col-6 d-flex gap-2">
              <div className="col">
                <HistoryCard infoIcon={false} headerName={"Security Controls"}>
                  {securityControls?.length > 0 ? (
                    securityControls?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(
                          ([key, value], index2) => (
                            key === "Firewall Status" && value === "OFF" ?
                              <div
                                key={index2}
                                className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                              >
                                <p className="p-0 fw-medium m-0 fs-11 col-4">
                                  <strong>{key}</strong>
                                </p>
                                <div className="p-0 m-0 fw-medium fs-11 text-end col-6" style={{ color: 'red' }}>
                                  {value || "NA"}
                                </div>
                              </div> :
                              (key === "RDP Status" || key === "SSH Status") && value === "Enabled" ?
                                <div
                                  key={index2}
                                  className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                                >
                                  <p className="p-0 fw-medium m-0 fs-11 col-4">
                                    <strong>{key}</strong>
                                  </p>
                                  <div className="p-0 m-0 fw-medium fs-11 text-end col-6" style={{ color: 'red' }}>
                                    {value || "NA"}
                                  </div>
                                </div> :
                                <div
                                  key={index2}
                                  className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                                >
                                  <p className="p-0 fw-medium m-0 fs-11 col-4">
                                    <strong>{key}</strong>
                                  </p>
                                  <div className="p-0 m-0 fw-medium fs-11 text-end col-6">
                                    {value || "NA"}
                                  </div>
                                </div>
                          )
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Security Controls Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div className="col position-relative">
                <HistoryCard infoIcon={false} headerName={"Bluetooth Details"}>
                  {bluetoothInfoData[0]?.length > 0 ? (
                    bluetoothInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(
                          ([key, value], index2) => (
                            key === "Bluetooth Status" && value === "On" ?
                              <div
                                key={index2}
                                className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                              >
                                <p className="p-0 fw-medium m-0 fs-11">
                                  <strong>{key}</strong>
                                </p>
                                <p className="p-0 m-0 fw-medium fs-11 text-end" style={{ color: 'red' }}>
                                  {value}
                                </p>
                              </div> :
                              <div
                                key={index2}
                                className="d-flex align-items-center py-2 justify-content-between border-1 border-bottom"
                              >
                                <p className="p-0 fw-medium m-0 fs-11">
                                  <strong>{key}</strong>
                                </p>
                                <p className="p-0 m-0 fw-medium fs-11 text-end">
                                  {value}
                                </p>
                              </div>
                          )
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">
                        Bluetooth Info is not available
                      </p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"USB Usage History"}
              >
                <div>
                  <DataTable
                    fixedHeader={true}
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Date/Time",
                        selector: (v) => (
                          <Tippy maxWidth="none" content={v?.time}>
                            <div>
                              {v?.time}
                            </div>
                          </Tippy>
                        ),
                        width: "160px",
                      },
                      {
                        name: "Device Name",
                        selector: (v) => v?.usbName,
                        cell: (row) => {
                          const flag = row?.falsePositive === false && row?.is_Linked === false &&
                            (row?.IsadminActionDone === "pending" || row?.IsadminActionDone === "inProgress")
                          return (
                            <Tippy maxWidth="none" content={row?.usbName}>
                              <div style={{ color: flag ? "red" : "inherit" }}>
                                {row?.usbName}
                              </div>
                            </Tippy>
                          )
                        },
                        width: "140px",
                      },
                      {
                        name: "Type",
                        selector: (v) => (
                          <Tippy maxWidth="none" content={v?.interfaceType}>
                            <div>{v?.interfaceType}</div>
                          </Tippy>
                        ),
                        width: "100px",
                      },
                      {
                        name: "Manufacturer",
                        selector: (v) => (
                          <Tippy maxWidth="none" content={v?.usbManufacturer}>
                            <div>{v?.usbManufacturer}</div>
                          </Tippy>
                        ),
                        width: "120px",
                      },
                      {
                        name: "Serial Number",
                        selector: (v) => v?.usbSerialNumber,
                        cell: (row) => {
                          const flag = row?.falsePositive === false && row?.is_Linked === false &&
                            (row?.IsadminActionDone === "pending" || row?.IsadminActionDone === "inProgress")
                          return (
                            <Tippy maxWidth="none" content={row?.usbSerialNumber}>
                              <div style={{ color: flag ? "red" : "inherit" }}>
                                {row?.usbSerialNumber}
                              </div>
                            </Tippy>
                          )
                        },
                        width: "200px",
                      },
                      {
                        name: "Vendor ID",
                        selector: (v) => (
                          <Tippy maxWidth="none" content={v?.usbVendorId}>
                            <div>{v?.usbVendorId}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Product ID",
                        selector: (v) => v?.usbProductId,
                      },
                    ]}
                    data={usbInfoData[1]?.usbHistory.slice(0, 20)}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        USB History is not available
                      </p>
                    }
                  />
                </div>
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"USB File Transfer History"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Date",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.timeStamp}>
                          <div>{v?.timeStamp}</div>
                        </Tippy>
                      ),
                      width: "200px",
                    },
                    {
                      name: "EventType", selector: (v) => v?.eventType,
                      width: "100px",
                    },

                    {
                      name: "Path",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.path ? v?.path : "NA"}>
                          <div>{v?.path ? v?.path : "NA"}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "File Type",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.fileType || "NA"}>
                          <div>{v.fileType || "NA"}</div>
                        </Tippy>
                      ),
                      width: "180px",
                    },
                    {
                      name: "File Hash",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.fileHash || "NA"}>
                          <div>{v?.fileHash || "NA"}</div>
                        </Tippy>
                      ),
                      width: "300px",
                    },
                  ]}
                  data={usbFileData.slice(0, 20)}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      File transfer history not available.
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Installed Applications"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Application", selector: (v) => (
                        <Tippy maxWidth="none" content={v?.name}><div style={{ color: nonWhitelistApps.includes(v?._id) ? "red" : "inherit" }}>{v?.name}</div></Tippy>
                      )
                    },
                    {
                      name: "Version", selector: (v) => (
                        <Tippy maxWidth="none" content={v?.version || "NA"}>
                          <div>{v?.version || "NA"}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Installation Date", selector: (v) => (
                        <Tippy maxWidth="none" content={convertDateFormat(v?.date) || "NA"}>
                          <div>{convertDateFormat(v?.date) || "NA"}</div>
                        </Tippy>
                      )
                    },
                  ]}
                  data={installedProgrameData.slice(0, 50)}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      Installed Programme is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Services List"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Service",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.displayName}>
                          <div style={{ color: nonWhitelistServices.includes(v?._id) ? "red" : "" }} >
                            {v?.displayName || "NA"}
                          </div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Description",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.description}>
                          <div>{v?.description}</div>
                        </Tippy>
                      ),
                    },
                    { name: "Status", selector: (v) => v?.status },
                  ]}
                  data={serviceProgrameData.slice(0, 50)}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      Service program info is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Startup Programs List"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    { name: "Program Name", selector: (v, i) => v },
                  ]}
                  data={startUpProgrameData.slice(0, 20)}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Startup program info is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Established Connection"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Local Address",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.localAddress}>
                          <div>{v?.localAddress}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Local Port",
                      selector: (v, i) => v?.localPort,
                    },
                    {
                      name: "Foreign Address",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.foreignAddress}>
                          <div style={{ color: v?.isOutside ? "red" : "" }}>{v?.foreignAddress}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Foreign Port",
                      selector: (v, i) => v?.foreignPort,
                    },
                  ]}
                  data={establishedConnection.slice(0, 20)}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Established Connection is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"OS Patch Information"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Name",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.name}>
                          <div>{v?.name}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Version",
                      selector: (v, i) => v?.version,
                      cell: (row) => {
                        const osDetailsFromConfig = adminConfig?.osDetails;
                        for (let i = 0; i < osDetailsFromConfig?.length; i++) {
                          if (
                            osDetailsFromConfig[i]?.osName === row?.name
                          ) {
                            return <Tippy maxWidth="none" content={row?.version}>
                              <div style={{ color: osDetailsFromConfig[i]?.osVersion !== row?.version?.split(" ")[0] ? "red" : "inherit" }}>
                                {row?.version}
                              </div>
                            </Tippy>
                          }
                        }
                        return <Tippy maxWidth="none" content={row?.version}>
                          <div>
                            {row?.version}
                          </div>
                        </Tippy>
                      }
                    },
                    {
                      name: "Date Applied",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.date}>
                          <div>{v?.date}</div>
                        </Tippy>
                      ),
                    },
                  ]}
                  data={osPatchInformation.slice(0, 10)}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      OS Patch is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"User Accounts"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Username",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.userName}>
                          <div>{v?.userName}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Type",
                      selector: (v) => (
                        <div style={{
                          color: accountDetail[0]?.adminAccountsAreMultiple?.multipleAdminCount &&
                            accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                              v?.userName
                            ) ? "red" : "inherit"
                        }}>
                          {accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                            v?.userName
                          )
                            ? "Admin"
                            : "User"}
                        </div>
                      ),
                    },
                    {
                      name: "Password Age(days)",
                      selector: (v) => v?.passwordAge,
                      cell: (row) => (
                        <Tippy maxWidth="none" content={row?.passwordAge}>
                          <div style={{ color: row?.passwordAge > adminConfig?.passwordThresholdAge ? "red" : "inherit" }}>{row?.passwordAge}</div>
                        </Tippy>
                      ),
                    },
                  ]}
                  data={accountDetail[0]?.usersPasswordAge || []}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      User Account Detail is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"High CPU Usage"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Process Name",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.name}>
                          <div style={{ color: "red" }}>{v?.name}</div>
                        </Tippy>
                      ),
                      width: "350px",
                    },
                    { name: "PID", selector: (v, i) => v?.pid },
                    { name: "Usage Percent", selector: (v, i) => `${v?.cpuPercent} %` },
                  ]}
                  data={highCpuusage}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Process with high CPU usage not found.
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"High RAM Usage"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Process Name",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.name}>
                          <div style={{ color: "red" }}>{v?.name}</div>
                        </Tippy>
                      ),
                      width: "350px",
                    },
                    { name: "PID", selector: (v, i) => v?.pid },
                    { name: "Usage Percent", selector: (v, i) => `${v?.memoryPercent} %` },
                  ]}
                  data={ramUsage}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Process with high RAM usage not found.
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Shared Directory Information"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Name",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.name}>
                          <div style={{ color: 'red' }}>{v?.name}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Path",
                      selector: (v, i) => (
                        <Tippy maxWidth="none" content={v?.path}>
                          <div>{v?.path}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Permissions",
                      selector: (v, i) => (
                        <Tippy
                          content={`Owner : ${v?.permission?.owner || "N/A"}, Group : ${v?.permission?.group || "N/A"}, Other : ${v?.permission?.other || "N/A"}`}>
                          <div>
                            Owner : {v?.permission?.owner || "N/A"}, Group : {v?.permission?.group || "N/A"}, Other : {v?.permission?.other || "N/A"}
                          </div>
                        </Tippy>
                      ),
                    },
                  ]}
                  data={sharedDir}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Shared directory data is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Optical Drive Information"}
              >
                <DataTable
                  columns={[
                    {
                      name: "Description",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.description}>
                          <div style={{ color: 'red' }}>{v?.description}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Product",
                      selector: (v) => (
                        <div>{v?.product}</div>
                      ),
                    },
                    {
                      name: "Vendor",
                      selector: (v) => (
                        <div>{v?.vendor}</div>
                      ),
                    },
                    {
                      name: "Physical Id",
                      selector: (v) => (
                        <div>{v?.physicalId}</div>
                      )
                    },
                  ]}
                  data={opticalDriveInfo}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Optical drive data not available.
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-2 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Recent Events Triggered"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Event Type",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.eventTriggerType}>
                          <div>{v?.eventTriggerType}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Timestamp",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.serverTimestamp.replace(", ", " ")}>
                          <div>{v?.serverTimestamp.replace(", ", " ")}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "SnapShot",
                      selector: (v) => "NA",
                    },
                  ]}
                  data={recentEventData[0]}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      Recent Events Triggered is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"Internal Hard Drive"}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    {
                      name: "Drive",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.fileSystem}>
                          <div>{v?.fileSystem}</div>
                        </Tippy>
                      ),
                      // width: "350px",
                    },
                    {
                      name: "Size",
                      selector: (v) => {
                        const totalSpace =
                          parseFloat(v?.size).toFixed(2) || 0;
                        return `${totalSpace} GB`;
                      },
                    },
                    {
                      name: "Used",
                      selector: (v) => {
                        const usedSpace =
                          parseFloat(v?.used).toFixed(2) || 0;
                        return `${usedSpace} GB`;
                      },
                    },
                    {
                      name: "Used Percent",
                      selector: (v) => {
                        const usedPercent =
                          parseFloat(v?.usedPercent).toFixed(2) || 0;
                        return `${usedPercent}%`;
                      },
                    },
                    {
                      name: "Available",
                      selector: (v) => {
                        const availSpace =
                          parseFloat(v?.available).toFixed(2) || 0;
                        return `${availSpace} GB`;
                      },
                    },
                    {
                      name: "Mount Point",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.mounted}>
                          <div>{v?.mounted}</div>
                        </Tippy>
                      ),
                    },
                  ]}
                  data={internalHardDrive}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      Established Connection is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>

          <div className="row row-cols-1 g-2">
            <div className="col">
              <HistoryCard infoIcon={false}
                headerName={"File Integrity Info"}
              >
                <DataTable
                  columns={[
                    {
                      name: "Sl no",
                      selector: (_, index) => index + 1,
                      width: "70px"
                    },
                    {
                      name: "Host Type",
                      selector: (v) => (
                        <div style={{
                          color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                            && v?.expectedHash !== v?.actualHash ? "red" : "black"
                        }}>{v?.hostType}</div>
                      ),
                      width: "100px"
                    },
                    {
                      name: "File Path",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.filePath}>
                          <div style={{
                            color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                              && v?.expectedHash !== v?.actualHash ? "red" : "black"
                          }}>{v?.filePath}</div>
                        </Tippy>
                      ),
                    },
                    {
                      name: "Actual File Hash",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.actualHash} >
                          <div style={{
                            color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                              && v?.expectedHash !== v?.actualHash ? "red" : "black"
                          }}>{v?.actualHash}</div>
                        </Tippy>
                      ),
                      width: "550px"
                    },
                    {
                      name: "Expected File Hash",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={<span className="w-full">{v?.expectedHash}</span>} >
                          <div style={{
                            color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                              && v?.expectedHash !== v?.actualHash ? "red" : "black"
                          }}>{v?.expectedHash}</div>
                        </Tippy>
                      ),
                      width: "550px"
                    },
                    {
                      name: "File Type",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.fileType} style={{ textOverflow: 'ellipsis' }}>
                          <div style={{
                            color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                              && v?.expectedHash !== v?.actualHash ? "red" : "black"
                          }}>{v?.fileType}</div>
                        </Tippy>
                      ),
                      width: "100px"
                    },
                    {
                      name: "Last Modified",
                      selector: (v) => (
                        <Tippy maxWidth="none" content={v?.lastModified}>
                          <div style={{
                            color: (v?.expectedHash !== "NA" && v?.actualHash !== "NA")
                              && v?.expectedHash !== v?.actualHash ? "red" : "black"
                          }}>{v?.lastModified}</div>
                        </Tippy>
                      ),
                      width: "200px"
                    },
                  ]}
                  data={fileIntegrityInfo}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      File Integrity data not available.
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default GeneratePdf;

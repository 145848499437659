import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { notifyApi } from "../../api/axiosSet";
import { saveAs } from "file-saver";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { error } from "jquery";
import PageLoader from "../../components/common/Loader/PageLoader";

function OrganizationInfo() {
  const { user } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [licenseData, setLicenseData] = useState({});

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      let url = `/${user?.data?.user?.organizationId}/license-details-org`
      if (searchTerm) {
        url += `?identifier=${searchTerm}`
      }
      const response = await notifyApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setColumnData([response?.data?.licenses]);
      setLoading(false);
    } catch (error) {
      setColumnData([]);
      setLoading(false);
    }
  };

  const fetchLicenseCounts = async () => {
    try {
      const response = await notifyApi.get(
        `/${user?.data?.user?.organizationId}/license-stats-count`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setLicenseData(response?.data?.licenseCounts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLicenseCounts();
  }, []);

  useEffect(() => {
    fetchData();
  }, [user, searchTerm]);

  const exportToCSV = () => {
    if (columnData[0] && columnData[0].length > 0) {
      // Determine which columns have data
      const hasData = (key) => ["pcIdAssociated", "lkeyStatus"].includes(key) ? true : columnData[0].some((item) => item[key]);

      const headers = [
        { label: "Endpoint Identifier Key", key: "licKey" },
        { label: "Valid From", key: "validFrom" },
        { label: "Valid To", key: "validTo" },
        { label: "Hostname", key: "hostname" },
        { label: "Serial No.", key: "serialNumber" },
        { label: "Device ID", key: "pcIdAssociated" },
        { label: "Status", key: "lkeyStatus" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const csvData = [
        tableColumn,
        ...columnData[0].map((item, index) => [
          index + 1,
          ...headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              : header.key === "createdAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "department"
                          ? item[header.key] || "NA"
                          : item[header.key]
          ),
        ]),
      ];

      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "Endpoint Identifier Keys",
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `endpoint_identifier_keys.csv`);
    }
  };

  function exportToPDF() {
    if (columnData[0] && columnData[0].length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape",
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15);
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      );

      // Add title
      doc.setFontSize(15);
      doc.text(`Endpoint Identifier Keys`, 14, 22);

      const hasData = (key) => ["pcIdAssociated", "lkeyStatus"].includes(key) ? true : columnData[0].some((item) => item[key]);
      const headers = [
        { label: "Endpoint Identifier Key", key: "licKey" },
        { label: "Valid From", key: "validFrom" },
        { label: "Valid To", key: "validTo" },
        { label: "Hostname", key: "hostname" },
        { label: "Serial No.", key: "serialNumber" },
        { label: "Device ID", key: "pcIdAssociated" },
        { label: "Status", key: "lkeyStatus" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData[0].forEach((item, index) => {
        const rowData = [index + 1, ...headers.map((header) =>
          header.key === "craetedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : header.key === "department" ? item[header.key] || "NA"
                      : Array.isArray(item[header.key])
                        ? item[header.key].join(", ")
                        : item[header.key]
        ),];
        tableRows.push(rowData);
      });

      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3,
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [52, 73, 94],
          textColor: 255,
          halign: "center",
          lineWidth: 0.5,
        },
        bodyStyles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: { cellWidth: 15 },
          2: { cellWidth: 25 },
          3: { cellWidth: 25 },
          4: { cellWidth: 50 },
          5: { cellWidth: 30 },
          7: { cellWidth: 20 },
        },
      });

      // Save the PDF
      doc.save(`endpoint_identifier_keys.pdf`);
    }
  }

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      name: "S. No.",
      selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
      width: "90px",
    },
    {
      name: "Endpoint Identifier Key",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.licKey || "NA"}>
          <div>{row?.licKey || "NA"}</div>
        </Tippy>
      ),
      width: "320px",
    },
    {
      name: "Valid From",
      selector: (row) => row?.validFrom,
      sortable: true,
      width: "140px",
    },
    {
      name: "Valid Upto",
      selector: (row) => row?.validTo,
      sortable: true,
      width: "140px",
    },
    {
      name: "Hostname",
      selector: (row) => row?.hostname,
      cell: (row) => (
        <Tippy maxWidth="none" content={row?.hostname || "NA"}>
          <div>{row?.hostname || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Serial No.",
      selector: (row) => row?.serialNumber,
      cell: (row) => (
        <Tippy maxWidth="none" content={row.serialNumber || "NA"}>
          <div>{row.serialNumber || "NA"}</div>
        </Tippy>
      ),
      width: "200px",
    },
    {
      name: "Device ID",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.pcIdAssociated || "NA"}>
          <div>{row?.pcIdAssociated || "NA"}</div>
        </Tippy>
      ),
    },
    {
      name: "Status",
      selector: (row) => (row?.lkeyStatus || "NA"),
      sortable: true,
      width: "200px",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [totalKeysToAdd, setTotalKeysToAdd] = useState(100);
  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState("");
  const [toggledClearRows, setToggledClearRows] = useState(false);

  const [selectedRows, setSelectedRows] = useState({ selectedRows: [] });

  const handleCloseModal = () => {
    setTotalKeysToAdd(100);
    setStartDate(new Date().toISOString().split("T")[0]);
    setEndDate("");
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setToggledClearRows(true);
    setEndDate("");
    setSelectedRows({ selectedRows: [] });
    setShowModal2(false);
  };

  function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const validate = () => {
    if (totalKeysToAdd < 1) {
      return { isValid: false, message: "Device Count should be greater than 0" };
    }
    if (!startDate || !endDate) {
      return { isValid: false, message: "Please select start and end date" };
    }
    if (startDate > endDate) {
      return { isValid: false, message: "Start date should be less than end date" };
    }
    return { isValid: true, message: null };
  };

  const handleSubmitAddLicense = async (e) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (!isValid) {
      handleCloseModal();
      toast.error(message, { position: "top-center" });
      return;
    }

    try {

      const validFrom = formatDateToDDMMYYYY(new Date(startDate));
      const validTo = formatDateToDDMMYYYY(new Date(endDate));

      const response = await notifyApi.post(
        `/add-device-count/generate-lic-key`,
        {
          organizationId: user?.data?.user?.organizationId,
          deviceCount: totalKeysToAdd,
          validFrom,
          validTo,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      handleCloseModal();
      toast.success("License Added Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData();
    } catch (err) {
      handleCloseModal();
      toast.error(err?.response?.data?.message || "Failed to add license", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleSubmitUpdateLicense = async (e) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (!isValid) {
      handleCloseModal();
      toast.error(message, { position: "top-center" });
      return;
    }
    try {
      setToggledClearRows(false);
      const validTo = formatDateToDDMMYYYY(new Date(endDate));

      await notifyApi.put(
        `/extend-license-expiry`,
        {
          licenseKeys: selectedRows?.selectedRows?.map((row) => row.licKey),
          organizationId: user?.data?.user?.organizationId,
          newValidTo: validTo,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      handleCloseModal2();
      toast.success("License Updated Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData();
    } catch (err) {
      handleCloseModal();
      toast.error(err?.response?.data?.message || "Failed to update license", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <div className="card rounded shadow mb-4 bg-body-tertiary">
          <div className="card-body">
            <h5 className="card-title text-center fw-bolder">Basic Details</h5>
            <hr />

            <div className="container text-center">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Org. Name</h5>
                      <p className="card-text">{user?.data?.user?.organization}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Admin Name</h5>
                      <p className="card-text">
                        {user?.data?.user?.firstName}{" "}
                        {user?.data?.user?.lastName}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Admin Email</h5>
                      <p className="card-text">{user?.data?.user?.email}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Registered</h5>
                      <p className="card-text ">
                        {formatTimestamp(user?.data?.user?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card rounded shadow mb-4 bg-body-tertiary">
          <div className="card-body">
            <h5 className="card-title text-center fw-bolder">Endpoints Identifier Keys</h5>
            <hr />

            <div className="container text-center">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Total</h5>
                      <p className="card-text">{licenseData?.total}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Used</h5>
                      <p className="card-text">{licenseData?.used} </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Unused</h5>
                      <p className="card-text">{licenseData?.unused}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body shadow p-3">
                      <h5 className="card-title">Expired</h5>
                      <p className="card-text ">{licenseData?.expired}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded shadow cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Identifier Keys</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="" style={{ position: "relative", width: "17rem" }}>
              <input
                style={{ paddingRight: "1.3rem" }}
                className="form-control"
                type="text"
                placeholder="Search by Hostname or Serial No."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
            </div>

            <button
              type="button"
              className="btn btn-secondary text-white"
              onClick={() =>
                setShowModal2(true)
              }
              disabled={selectedRows?.length === 0 || selectedRows?.selectedRows?.length === 0}
            >
              <i className="ri-arrow-right-up-box-line"></i> &nbsp;
              Update Validity
            </button>

            <button
              type="button"
              className="btn btn-info text-white"
              onClick={() =>
                setShowModal(true)
              }
            >
              <i className="ri-add-line"></i> &nbsp;
              Add Keys
            </button>

            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() =>
                exportToPDF()
              }
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() =>
                exportToCSV()
              }
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="card rounded mb-2 shadow cardStyle">
          {loading ? (
            <PageLoader />
          ) : (
            <DataTable
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={(selectedRows) => setSelectedRows(selectedRows)}
              clearSelectedRows={toggledClearRows}
              columns={columns}
              data={columnData[0] || []}
              pagination
              paginationPerPage={pageSize}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              onChangePage={(page) => setCurrentPage(page)}
              onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Keys To Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitAddLicense}>
              <div className="mb-3">
                <label htmlFor="keyCount" className="form-label">
                  No of Keys <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="keyCount"
                  min={1}
                  max={1000}
                  value={totalKeysToAdd}
                  onChange={(e) => setTotalKeysToAdd(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="validFrom" className="form-label">
                  Valid From <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="validFrom"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="validTo" className="form-label">
                  Valid To <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="validTo"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                  required
                />
              </div>
              <div className="d-flex justify-content-end gap-3">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                >
                  Genarate
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={handleCloseModal2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Validity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitUpdateLicense}>
              <div className="mb-3">
                <label htmlFor="keyCount" className="form-label">
                  Selected Keys: <span className="text-danger">{selectedRows?.selectedRows?.length}</span>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="validTo" className="form-label">
                  Valid Upto <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="validTo"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                  required
                />
              </div>
              <div className="d-flex justify-content-end gap-3">
                <Button variant="secondary" onClick={handleCloseModal2}>
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                >
                  Update
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default OrganizationInfo;

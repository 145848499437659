import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../feature/slices/AuthSlice";
// import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import PageLoader from "../../components/common/Loader/PageLoader";
import DashCard from "../../components/common/DashBoard/DashCard";
import { dp3 } from "../../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import AdminCard from "../../components/common/AdminCard/AdminCard";
import yellowIcon from "../../assets/img/ServerityIcon/yellowAdmin.png";
import greenIcon from "../../assets/img/ServerityIcon/greenAdmin.png";
import greyIcon from "../../assets/img/ServerityIcon/greyAdmin.png";
export default function Admin() {
  const navigate = useNavigate();

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  // const seriesEight = [
  //   {
  //     type: "column",
  //     data: [
  //       [0, 2],
  //       [1, 3],
  //       [2, 5],
  //       [3, 7],
  //       [4, 12],
  //       [5, 17],
  //       [6, 10],
  //       [7, 14],
  //       [8, 15],
  //       [9, 12],
  //       [10, 8],
  //     ],
  //   },
  //   {
  //     type: "column",
  //     data: [
  //       [0, 12],
  //       [1, 7],
  //       [2, 4],
  //       [3, 5],
  //       [4, 8],
  //       [5, 10],
  //       [6, 4], 
  //       [7, 7],
  //       [8, 11],
  //       [9, 9],
  //       [10, 5],
  //     ],
  //   },
  // ];






  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [windowCount, setWindowCount] = useState(0);
  const [linuxCount, setLinuxCount] = useState(0);
  const [androidCount, setAndroidCount] = useState(0);
  const [macCount, setMacCount] = useState(0);

  const [summoryCount, setSummoryCount] = useState([]);
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  useEffect(() => {
    if (!isAuthenticated) {
      // User not authenticated, redirect to login page
      navigate("/login");
    } else {
      // Check token expiration time

      const tokenExpirationTimer = setTimeout(() => {
        // Token has expired, log the user out
        dispatch(logout()); // Dispatch logout action to clear user data and token
        navigate("/signin");
      }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    let isMounted = true; // Variable to track mount status

    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_NOTIFY_URL}/organization-stats`,
          // {
          //   headers: {
          //     Authorization: `Bearer ${user?.data?.accessToken}`,
          //   },
          // }
        )
        .then((res) => {
          if (isMounted) {
            setWindowCount(res?.data?.totalOrganizations);
            setLinuxCount(res?.data?.paidOrganizations);
            setAndroidCount(res?.data?.trialOrganizations);
            setSummoryCount(res?.data?.monthWiseRegistrations)

          }
        })
        .catch(() => {

        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            // Schedule the next fetchData call after 50 seconds
            setTimeout(fetchData, 50000);
          }
        });
    };

    fetchData(); // Initial fetchData call

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, [user]);





  const seriesEight = [
    {
      type: "column",
      data: summoryCount.map(registration => [registration.month, registration.count])
    }
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: true },
    legend: { show: false },
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              {/* <ol className="breadcrumb fs-sm mb-2 p-1">
                <li className="breadcrumb-item">
                  <Link to="/">Admin</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard
                </li>
              </ol> */}
              <ol className="breadcrumb fs-sm mb-2 p-1">
                <li className="breadcrumb-item">
                  <NavLink exact to="/" activeClassName="active">Admin</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink exact to="/organizations" activeClassName="active">Organizations</NavLink>
                </li>
              </ol>
              <h4 className="main-title mb-0">Welcome to Dashboard</h4>
            </div>
          </div>
          <Row className="g-5 px-5">
            <Col xl="4">
              <AdminCard
                dashNumber={windowCount ? windowCount : '0'}
                handleRoute={() => navigate("/Organizations")}
                dashTitle={"Total Organizations"}
                riskNumber={36}
                riskPerc={77}
                adminCardIcon={yellowIcon}
              />
            </Col>
            <Col xl="4">
              <AdminCard
                dashNumber={linuxCount ? linuxCount : '0'}
                handleRoute={() => navigate("/paid-org")}
                dashTitle={"Paid Organizations"}
                riskNumber={176}
                riskPerc={77}
                adminCardIcon={greenIcon}
              />
            </Col>
            <Col xl="4">
              <AdminCard
                dashNumber={androidCount ? androidCount : '0'}
                dashTitle={"Trial Organizations"}
                handleRoute={() => navigate("/trial-org")}
                riskNumber={36}
                adminCardIcon={greyIcon}
              />
            </Col>

            <Col xl="12">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Registration Analytics</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="g-4">
                    <Col md="12">
                      <ReactApexChart
                        series={seriesEight}
                        options={optionEight}
                        height={210}
                        width={990}
                        className="apex-chart-three"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}

import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { logout } from "./feature/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./feature/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import CSS
import "./assets/css/remixicon.css";

// Import SCSS
import "./scss/style.scss";

import "./app.css";
import AdminRoutes from "./routes/AdminRoutes";
import UserAdminRoutes from "./routes/UserAdminRoutes";
import OrganizationAdmin from "./routes/OrganizationAdmin";
import useInactivityTimer from "./components/common/userInactivityTimer";

import { SkinModeProvider } from "./components/SkinModeContext";
import { SidebarToogleProvider } from "./components/SidebarToogleContext";
import AppRoutes from "./routes/AppRoutes";

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <SidebarToogleProvider>
          <SkinModeProvider>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes />
            </PersistGate>
          </SkinModeProvider>
        </SidebarToogleProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

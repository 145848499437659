import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context
const SidebarToogleContext = createContext();

// Custom hook to use the skin mode context
export const useSidebarToogle = () => {
  return useContext(SidebarToogleContext);
};

// Provider to wrap your application and provide the state
export const SidebarToogleProvider = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(localStorage.getItem("sidebar-collapsed") || false);

  useEffect(() => {
    if (sidebarCollapsed) {
      document.documentElement.setAttribute("data-sidebar", "true");
      localStorage.setItem("sidebar-collapsed", true);
    } else {
      document.documentElement.removeAttribute("data-sidebar");
      localStorage.removeItem("sidebar-collapsed");
    }
  }, [sidebarCollapsed]);

  const toggleSidebarCollapsed = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  return (
    <SidebarToogleContext.Provider value={{ sidebarCollapsed, toggleSidebarCollapsed }}>
      {children}
    </SidebarToogleContext.Provider>
  );
};

import React from "react";
import OrganizationInfo from "../pages/Useradmin/OrganizationInfo.jsx";
import ManageOrg from "../pages/Useradmin/ManageOrg";
import AllEndPoint from "../pages/Useradmin/AllEndPoint";
import ManageUsers from "../pages/Useradmin/ManageUsers.jsx";
import AdminConfig from "../pages/Useradmin/AdminConfig";
import WhitelistPorts from "../pages/Useradmin/WhitePages/WhitelistPorts";
import WhitelistSubnets from "../pages/Useradmin/WhitePages/WhitelistSubnets.jsx";
import WhitelistApplication from "../pages/Useradmin/WhitePages/WhitelistApplication";
import WhitelistUsbDevice from "../pages/Useradmin/WhitePages/WhitelistUsbDevice";
import ExemptionConfig from "../pages/Useradmin/ExemptionConfig.jsx";
import WhitelistService from "../pages/Useradmin/WhitePages/WhitelistServices.jsx";
import PatchManagement from "../pages/Useradmin/PatchManagement.jsx";

const UserAdminRoutes = [
  { path: "/organization-info", element: <OrganizationInfo /> },
  { path: "/manage-org", element: <ManageOrg /> },
  { path: "/all-endpoint", element: <AllEndPoint /> },
  { path: "/manage-users", element: <ManageUsers /> },
  { path: "/whitelist-ports", element: <WhitelistPorts /> },
  { path: "/whitelist-subnet", element: <WhitelistSubnets /> },
  { path: "/whitelist-application", element: <WhitelistApplication /> },
  { path: "/whitelist-service", element: <WhitelistService /> },
  { path: "/whitelist-usb", element: <WhitelistUsbDevice /> },
  { path: "/admin-config", element: <AdminConfig /> },
  { path: "/exemption-config", element: <ExemptionConfig /> },
  { path: "/patch-management", element: <PatchManagement /> },
];

export default UserAdminRoutes;

import { summaryApi } from "../../api/axiosSet";

export const fetchWindowsSummary = async (url, token) => {
  try {
    const response = await summaryApi.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response.data);
    const jsonData = response.data;

    const windowsSummary = {
      totalClients: jsonData?.windows?.getTotalPCCount || 0,
      totalSafeClients: jsonData?.windows?.SafeWindowsClients || 0,
      totalUnsafeClients: jsonData?.windows?.UnsafeWindowsClients || 0,
      totalUnresponsiveClients: jsonData?.windows?.getUnresponsiveWindowsCount || 0,
    };

    const windowsAlertSummary = {
      avNotInstalled: jsonData?.windows?.antivirusNotInstalledCount || 0,
      avMalfunction: jsonData?.windows?.getAntivirusMalfunctionCount || 0,
      avNotUpdated: jsonData?.windows?.AntivirusNotUpdatedCount || 0,
      edrNotInstalled: jsonData?.windows?.EDRNotInstalledCount || 0,
      edrMalfunction: jsonData?.windows?.EDRMalfunctionCount || 0,
      osNotPatched: jsonData?.windows?.getNotPatchedOSCount || 0,
      napnacDisabled: jsonData?.windows?.nacDisabledCount || 0,
      windowsNotActivated: jsonData?.windows?.getInactiveWindowsCount || 0,
      malwareDetected: jsonData?.windows?.MalwareDetectionCount || 0,
      firewallOff: jsonData?.windows?.firewallOffPCCount || 0,
      usbViolation: jsonData?.windows?.getPCsCountWithUnwhitelistedUSB?.getPCsCountWithUnwhitelistedUSB || 0,
      blacklistedSoftware: jsonData?.windows?.blacklistedProgramsCount || 0,
      unknownServices: jsonData?.windows?.getunknownServicesPCCount || 0,
      oldAdminPasswords: jsonData?.windows?.adminPasswordAgeGreaterThanThresholdCount || 0,
      multipleAdmins: jsonData?.windows?.multipleAdminCount || 0,
      sharedFolder: jsonData?.windows?.getSharedDirectoriesDetectedCount || 0,
      outOfDomain: jsonData?.windows?.getOutOfDomainWindowsCount || 0,
      rdpEnabled: jsonData?.windows?.getRdpEnableCount || 0,
      cdDriveEnabled: jsonData?.windows?.getCdDriveEnabledCount || 0,
      biosBatteryHealth: jsonData?.windows?.getBiosBatteryServiceablePcCount || 0,
      scannerInstalled: jsonData?.windows?.getScannerInstalledCount || 0,
      highRamUsage: jsonData?.windows?.getHighRamUsagePcCount || 0,
      highCpuUsage: jsonData?.windows?.getHighCpuUsagePcCount || 0,
      wifiPcCount: jsonData?.windows?.wifiConnectedPCCount || 0,
      bluetoothCount: jsonData?.windows?.getBluetoothEnabledCount || 0,
      openNetworkConnection: jsonData?.windows?.getOpenNetworkConnectionPCCount || 0,
      unwantedPorts: jsonData?.windows?.getntpServerEnabledPCCount || 0,
      networkViolation: jsonData?.windows?.getNetworkViolationPCCount || 0,
      communicationViolation: jsonData?.windows?.getNetworkCommunicationViolationCount || 0,
      totalUsbInsertions: jsonData?.windows?.totalUsbInsertionsCount || 0,
      tpmNotEnabled: jsonData?.windows?.tpmNotEnableedCount || 0,
      fipsNotEnabled: jsonData?.windows?.isFipsNotEnableedCount || 0,
      additionalProcessHardeningInfoCount: jsonData?.windows?.cisAdditionalProcessHardeningInfoCount || 0,
      zenProgramNotInstallPCCount: jsonData?.windows?.getZenProgramNotInstallPCCount || 0,
      certificateClientProgramNotInstallPCCount: jsonData?.windows?.getCertificateClientProgramNotInstallPCCount || 0,
      isOsNotHardenedCountSet: jsonData?.windows?.isOsNotHardenedCount || 0,
      isSecureBootNotEnableedCount: jsonData?.windows?.isSecureBootNotEnableedCount || 0,
      fileIntegrityCheckFailedCount: jsonData?.windows?.fileIntegrityCheckFailedCount || 0,
    };

    const totalDashboard = jsonData?.windows?.getTotalPCCount || 0;

    return { windowsSummary, windowsAlertSummary, totalDashboard };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

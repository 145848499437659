import { useEffect, useState } from "react";
import { linuxApi } from "../../api/axiosSet";
import { useSelector } from "react-redux";

const useFetchOsLinux = (pcInfoId, timeTrigger, legacyId) => {
  const [osData, setOsData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState([]);
  const [antivirusInfoData, setAntivirusInfoData] = useState([]);
  const [hardwareInfoData, setHardwareInfoData] = useState([]);
  const [osPatchUpdateInfoData, setOsPatchUpdateInfoData] = useState(null);
  const [usbInfoData, setUsbInfoData] = useState([]);
  const [bluetoothInfoData, setBluetoothInfoData] = useState([]);
  const [installedProgrameData, setInstalledProgrameData] = useState([]);
  const [serviceProgrameData, setServiceProgrameData] = useState([]);
  const [startUpProgrameData, setStartUpProgrameData] = useState([]);
  const [recentEventData, setRecentEventData] = useState([]);
  const [linuxCisData, setLinuxCisData] = useState([]);
  const [establishedConnection, setEstablishedConnection] = useState([]);
  const [highCpuusage, setHighCpuUsage] = useState([]);
  const [ramUsage, setRamUsage] = useState([]);
  const [osloading, setOsLoading] = useState(true);
  const [securityControls, setSecurityControls] = useState([]);
  const [sharedDir, setSharedDir] = useState([]);
  const [osPatchInformation, setOsPatchInformation] = useState([]);
  const [accountDetail, setAccountDetail] = useState([]);
  const [internalHardDrive, setInternalHardDrive] = useState([]);
  const [logError, setLogError] = useState(false);
  const [usbFileData, setUsbFileData] = useState([]);
  const [auditScore, setAuditScore] = useState([0]);
  const [avCards, setAvCards] = useState([]);
  const [opticalDriveInfo, setOpticalDriveInfo] = useState([]);
  const [nonWhitelistApps, setNonWhitelistApps] = useState([]);
  const [nonWhitelistServices, setNonWhitelistServices] = useState([]);
  const [fileIntegrityInfo, setFileIntegrityInfo] = useState([])
  const [adminConfig, setAdminConfig] = useState({});
  const [blacklistedNICIPAddress, setBlacklistedNICIPAddress] = useState([]);
  const [blacklistedIPAddress, setBlacklistedIPAddress] = useState([]);
  const [blacklistedOpenPorts, setBlacklistedOpenPorts] = useState([]);
  const [simplifiedPatchInfo, setSimplifiedPatchInfo] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  //legacyId  routing set
  useEffect(() => {
    const source = linuxApi.CancelToken.source();
    const fetchData = async () => {
      try {
        let url = "";
        if (legacyId) {
          url = `/legacyinfo/dashboard/legacy-latest-by-pcId/${user?.data?.user?.userId}?pcId=${pcInfoId}&legacyId=${legacyId}`
        }
        else {
          url = `/legacyinfo/dashboard/current-legacy-latest-by-pcId/${user?.data?.user?.userId}?pcId=${pcInfoId}`
        }
        const response = await linuxApi.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          },
          { cancelToken: source.token }
        );
        const osInstalledTime = response?.data?.formattedosInstallationDate;
        const loginTime = response?.data?.formattedLoginTime;
        const osEntriesData = response?.data?.legacyLogs;

        const usdHistoryData = response?.data?.UsbInformation;
        const recentEventTrigger = response?.data?.RecentEventsTriggered;
        const recentAvCards = response?.data?.filteredAvInfo;

        const serverTimeeStamp = response?.data?.formattedserverTimestamp;
        const bootTime = response?.data?.formattedSystemBootTime;
        const EventsTriggered = response?.data;
        const usbFileHistroy = response?.data;
        const triggerUserType = osEntriesData?.pcIdentityInfo?.eventTriggerType;
        const audit_score = osEntriesData?.pcIdentityInfo;
        const updatedNetworkInfo = response?.data?.updatedNetworkInfo;
        const nonWhitelistedAppsNew = response?.data?.nonWhitelist?.nonWhitelistedInstalledPrograms || [];
        const nonWhitelistedServicesNew = response?.data?.nonWhitelist?.nonWhitelistedServices || [];
        const nonWhitelistAppsNewIDs = nonWhitelistedAppsNew && nonWhitelistedAppsNew.map(app => app._id) || [];
        const nonWhitelistServicesNewIDs = nonWhitelistedServicesNew && nonWhitelistedServicesNew.map(service => service._id) || [];
        setNonWhitelistApps(nonWhitelistAppsNewIDs)
        setNonWhitelistServices(nonWhitelistServicesNewIDs)

        setAuditScore(audit_score?.auditScore)

        setBasicInfoData([
          {
            "Serial Number":
              osEntriesData?.pcIdentityInfo?.serialNumber || "NA",
            "Endpoint ID": osEntriesData?.pcIdentityInfo?.licenseKey || "NA",
            Hostname: osEntriesData?.pcIdentityInfo?.hostname || "NA",
            "Endpoint Type":
              osEntriesData?.pcIdentityInfo?.endpoint_type === osEntriesData?.pcIdentityInfo?.hostname ? "NA" : osEntriesData?.pcIdentityInfo?.endpoint_type || "NA",
            "Phase": osEntriesData?.pcIdentityInfo?.phase || "NA",
            "Logged-In User":
              osEntriesData?.pcIdentityInfo?.currentUser || "NA",
            "User Type":
              osEntriesData?.accountInfo?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                osEntriesData?.pcIdentityInfo?.currentUser
              )
                ? "Admin"
                : "User" || "NA",

            "Trigger Type": triggerUserType || "NA",
            "Audit Time": loginTime || "NA",
            "Last Sync Time": serverTimeeStamp || "NA",
            "Last Boot Time": bootTime || "NA",
            "Audit Agent":
              osEntriesData?.pcIdentityInfo?.currentAgentVersion || "NA",
          },
        ]);
        setOsData([
          {
            OS: osEntriesData?.pcIdentityInfo?.osName || "NA",
            "OS Version": osEntriesData?.osInfo?.osVersion || "NA",
            "OS Architecture":
              osEntriesData?.hardwareInfo?.machineBitType || "NA",

            "OS Installed On": osInstalledTime || "NA",
            Timezone: osEntriesData?.hardwareInfo?.timeZone || "NA",
            "Audit Logging":
              osEntriesData?.osInfo?.auditLogs === "active"
                ? "Active"
                : "Inactive" || "NA",

            "Boot Device": osEntriesData?.osInfo?.bootDevice || "NA",
          },
        ]);

        setAvCards([recentAvCards])
        setNetworkData([
          [
            {
              "Name":
                osEntriesData?.networkInfo?.wifiStatus.length > 0 &&
                  osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? "Ethernet"
                    : "OFF"
                  : osEntriesData?.networkInfo?.wifiStatus.length > 0
                    ? osEntriesData?.networkInfo?.wifiStatus[0]?.ssid
                    : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                      ? "Ethernet"
                      : "OFF",
              "IP Address":
                osEntriesData?.pcIdentityInfo?.ipAddress,
              // Type:
              //   osEntriesData?.networkInfo?.connectionStatus?.connectionType ||
              //   "NA",
              "MAC Address":
                osEntriesData?.networkInfo?.wifiStatus?.length > 0 &&
                  osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? osEntriesData?.networkInfo?.ethernetStatus[0]?.macAddress
                    : "NA"
                  : osEntriesData?.networkInfo?.wifiStatus?.length > 0
                    ? osEntriesData?.networkInfo?.wifiStatus[0]?.macAddress
                    : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                      ? osEntriesData?.networkInfo?.ethernetStatus[0]?.macAddress
                      : "NA",
              "Network Category":
                osEntriesData?.networkInfo?.wifiStatus?.length > 0 &&
                  osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? "Wired"
                    : "NA"
                  : osEntriesData?.networkInfo?.wifiStatus?.length > 0
                    ? "Wifi"
                    : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                      ? "Wired"
                      : "NA",
              "DNS Server": osEntriesData?.networkInfo?.dnsServer || " NA",
              "Connected To Domain":
                osEntriesData?.pcIdentityInfo?.connectedToDomainName || " NA",

              "NTP Server":
                osEntriesData?.networkInfo?.ntpDetails?.ntpServer || "NA",
              "NTP Status": osEntriesData?.networkInfo?.ntpDetails?.isNtpEnabled
                ? "Enabled"
                : "Disabled" || "NA",

              "Total NIC Cards":
                osEntriesData?.hardwareInfo?.nicDetails?.nicCount || "NA",
              "Total Open Ports":
                osEntriesData?.networkInfo?.openTcpPorts.length || "NA",
            },
          ],
          {
            totalOpenPorts: osEntriesData?.networkInfo?.openTcpPorts || [],
            totalNICCards:
              osEntriesData?.hardwareInfo?.nicDetails?.nicInfo || [],
          },
        ]);

        setHardwareInfoData([
          [
            {
              Manufacturer:
                osEntriesData?.pcIdentityInfo?.systemManufacturer || "NA",
              Model: osEntriesData?.pcIdentityInfo?.systemModel || "NA",
              "Machine Type": osEntriesData?.hardwareInfo?.machineType || "NA",
              "Motherboard SN":
                osEntriesData?.pcIdentityInfo?.motherboardSerialNumber || "NA",
              CPU: osEntriesData?.hardwareInfo?.processorInfo || "NA",
              "Processor Architecture":
                osEntriesData?.hardwareInfo?.machineBitType || "NA",
              RAM:
                osEntriesData?.hardwareInfo?.memoryInformation?.totalRAM ||
                "NA",
              "HDD/SSD":
                Math.round(
                  osEntriesData?.hardwareInfo?.internalHardDrivesInfo?.reduce(
                    (s, v) => (s += +v?.size),
                    0
                  )
                ) + " GB" || "NA",
              "Device UUID":
                osEntriesData?.hardwareInfo?.deviceUUIDNumber || "NA",

              "BIOS Version": osEntriesData?.hardwareInfo?.biosVersion || "NA",
              "BIOS Battery State": osEntriesData?.hardwareInfo
                ?.biosBatteryServiceable
                ? "Serviceable"
                : "Unserviceable" || "NA",
              "TPM Version": osEntriesData?.hardwareInfo?.tpmVersion || "NA",
            },
          ],
          {
            ramData: osEntriesData?.hardwareInfo?.memoryInformation || [],
          },
        ]);

        setSecurityControls([
          {
            "Firewall": osEntriesData?.networkInfo?.firewallService || "NA",
            "Firewall Status":
              osEntriesData?.networkInfo?.firewallStatus || "NA",
            "EDR Installed": response?.data?.edrDetails?.isEdrInstalled
              ? "True"
              : "False" || "NA",
            "EDR Service Status": "NA",
            "NAC Installed": osEntriesData?.networkInfo?.nacInstalled
              || "NA",
            "RDP Status": osEntriesData?.osInfo?.rdpStatus?.rdpEnabled === "False"
              ? "Disabled"
              : "Enabled" || "NA",
            "SSH Status":
              osEntriesData?.osInfo?.rdpStatus?.sshActive === "False"
                ? "Disabled"
                : "Enabled" || "NA",
          },
        ]);

        setOsPatchUpdateInfoData(osEntriesData?.OsPatchUpdateInfo || []);
        setUsbFileData(usbFileHistroy?.FileHistory || []);
        const parseDate = (dateString) => {
          try {
            const [day, month, year, hour, minute, second] = dateString.split(/[-\s:]/);
            return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
          }
          catch {
            return dateString;
          }
        };
        setUsbInfoData([
          [
            {
              "Usb History": usdHistoryData?.history?.length,
            },
          ],
          {
            usbHistory: usdHistoryData?.history.sort((a, b) => {
              if (parseDate(a.time) > parseDate(b.time)) {
                return -1;
              }
              if (parseDate(a.time) < parseDate(b.time)) {
                return 1;
              }
              return 0;
            }) || [],
            usbConnection: osEntriesData?.usbInfo?.usbInfoDetails || [],
          },
        ]);

        setBluetoothInfoData([
          [
            {
              "Bluetooth Status": osEntriesData?.networkInfo?.bluetoothInfo
                ? osEntriesData?.networkInfo?.bluetoothInfo?.status
                : "NA",
              "Bluetooth Device Serial No":
                osEntriesData?.networkInfo?.bluetoothInfo?.deviceSerial || "NA",
              "Paired Devices":
                osEntriesData?.networkInfo?.bluetoothInfo?.pairedDevice
                  .length || 0,
            },
          ],
          {
            blueToothPariedDevice:
              osEntriesData?.networkInfo?.bluetoothInfo?.pairedDevice || [],
            blueToothConnectedDevice:
              osEntriesData?.networkInfo?.bluetoothInfo?.connectedDevice || [],
          },
        ]);

        const installedProgramsList = osEntriesData?.backendInfo?.installedPrograms || [];
        const whitelistedAppsNew = installedProgramsList?.filter(item => !nonWhitelistAppsNewIDs.includes(item._id));
        setInstalledProgrameData([...nonWhitelistedAppsNew, ...whitelistedAppsNew]);

        const servicesList = osEntriesData?.osInfo?.servicesInfo || [];
        const whitelistedServicesNew = servicesList?.filter(item => !nonWhitelistServicesNewIDs.includes(item._id));
        setServiceProgrameData([...nonWhitelistedServicesNew, ...whitelistedServicesNew]);

        setStartUpProgrameData(osEntriesData?.osInfo?.startupPrograms || []);
        setRecentEventData([recentEventTrigger]);

        const keysToRemove = [
          "createdAt",
          "currentTime",
          "eventTriggerType",
          "exemptedFipsNotEnabled",
          "exemptedOsNotHardened",
          "exemptedProcessNotHardened",
          "exemptedSecureBootNotEnabled",
          "exemptedTpmNotAvailable",
          "isOsNotHardened",
          "serverTimestamp",
          "updatedAt",
          "OsNotHardenedmismatchCount",
          "_id",
          "pcId",
          "__v"
        ];

        const cisData = osEntriesData?.cisInfo || {};
        keysToRemove.forEach(key => delete cisData[key]);
        setLinuxCisData(cisData);

        setEstablishedConnection(
          updatedNetworkInfo?.establishedNetworkConnections || []
        );

        setHighCpuUsage(osEntriesData?.hardwareInfo?.highCpuUsage || []);
        setRamUsage(osEntriesData?.hardwareInfo?.ramUsage || []);

        setAccountDetail([osEntriesData?.accountInfo] || []);
        setOsPatchInformation(
          osEntriesData?.backendInfo?.osPatchInfo || []
        );

        setFileIntegrityInfo(response?.data?.fileIntegrityComparision || [])
        setAdminConfig(response?.data?.adminConfig || {})
        const outsideIps = response?.data?.blacklistedNetwork?.blacklistIpAddress
          .filter(item => item.isOutside)
          .map(item => item.ipAddress);
        setBlacklistedIPAddress(outsideIps || []);
        const outsideNICIps = response?.data?.blacklistedNetwork?.blacklistNicIp
          .filter(item => item.isOutside)
          .map(item => item.ipAddress);
        setBlacklistedNICIPAddress(outsideNICIps || []);
        const outsidePorts = response?.data?.blacklistedNetwork?.blacklistOpenPorts
          .filter(item => item.outside)
          .map(item => item.port);
        setBlacklistedOpenPorts(outsidePorts || []);

        setSharedDir(osEntriesData?.osInfo?.sharedDirectories || []);

        setInternalHardDrive(
          osEntriesData?.hardwareInfo?.internalHardDrivesInfo || []
        );

        setOpticalDriveInfo(osEntriesData?.hardwareInfo?.opticalDriveInfo?.driveDetails || []);

        setOsLoading(false);
      } catch (error) {
        if (linuxApi.isCancel(error)) {
          setOsLoading(false);
        } else if (error.response && error.response.status === 404) {
          setLogError(true);
          setOsLoading(false);
        } else {
          console.error("Error fetching data:", error);
          setOsLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to cancel the request when the component unmounts
    return () => {
      source.cancel("Request canceled due to component unmount.");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcInfoId, timeTrigger]);

  return {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    osPatchUpdateInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    linuxCisData,
    establishedConnection,
    highCpuusage,
    ramUsage,
    securityControls,
    accountDetail,
    osPatchInformation,
    sharedDir,
    internalHardDrive,
    logError,
    usbFileData,
    auditScore,
    avCards,
    opticalDriveInfo,
    nonWhitelistApps,
    nonWhitelistServices,
    fileIntegrityInfo,
    adminConfig,
    blacklistedIPAddress,
    blacklistedNICIPAddress,
    blacklistedOpenPorts,
  };
};

export default useFetchOsLinux;

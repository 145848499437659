import React from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "./common/CustomButton";

function CustomModal({
  modelTitle,
  children,
  show,
  handleClose,
  customWidth = "",
}) {
  return (
    <Modal show={show} onHide={handleClose} size={customWidth}>
      <Modal.Header>
        <div className="m-0 p-0 d-flex align-items-center w-100  justify-content-between">
          <p className="m-0 p-0 fs-16 fw-bold">{modelTitle}</p>
          {/* <Button className="p-0 m-0 text-secondary bg-transparent border-0">
        <i className="fas fa-sync"></i>
        </Button> */}
        </div>
      </Modal.Header>
      <Modal.Body className="port-modal">{children}</Modal.Body>
      <Modal.Footer>
        <CustomButton buttonTitle={"Close"} handleClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;

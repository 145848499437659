import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { linuxApi, summaryApi } from "../../api/axiosSet";
import '../../scss/org-view/_oranizationview.scss';
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import PageLoader from "../../components/common/Loader/PageLoader";
import { Link } from "react-router-dom";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";

export default function OrganizationView() {

  const { user } = useSelector((state) => state.authSlice);
  const roleName = user?.data?.user?.roleName;
  const navigate = useNavigate();

  const userRoleName = user?.data?.user?.roleName;

  const [loading, setLoading] = useState(true);
  const [columnDataDepartment, setColumnDataDepartment] = useState([]);
  const [columnDataSection, setColumnDataSection] = useState([]);
  const [columnDataSubsection, setColumnDataSubsection] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const [isDepartmentVisible, setDepartmentVisible] = useState(true);
  const [isSectionVisible, setSectionVisible] = useState(true);
  const [isSubsectionVisible, setSubsectionVisible] = useState(true);

  const hideSectionTable = () => {
    setSectionVisible(false);
    setSubsectionVisible(false);
    setTimeout(() => {
      setColumnDataSection([]);
      setColumnDataSubsection([]);
      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
    }, 500);
  };

  const hideSubsectionTable = () => {
    setSubsectionVisible(false);
    setTimeout(() => {
      setColumnDataSubsection([]);
      setSelectedSection(null);
      setSelectedSubsection(null);
    }, 500);
  };

  const conditionalRowStylesDept = [
    {
      when: row => selectedDepartment && row?._id === selectedDepartment?.value,
      style: {
        backgroundColor: "#dff1fa",
      },
    },
  ]

  const conditionalRowStylesSec = [
    {
      when: row => selectedSection && row?.section === selectedSection?.value,
      style: {
        backgroundColor: "#d3ebf7",
      },
    },
  ]

  const navigateToDashboard = (row, type) => {
    function getFilteredData() {
      if (type === "department") {
        let state = {
          selectedDepartment: {
            label: row?.departmentName,
            value: row?._id
          }
        }
        return state
      } else if (type === "section") {
        let state = {
          selectedDepartment: selectedDepartment && {
            label: selectedDepartment?.label,
            value: selectedDepartment?.value
          },
          selectedSection: {
            label: row?.sectionName,
            value: row?.section
          }
        }
        return state
      }
      else if (type === "subSection") {
        let state = {
          selectedDepartment: selectedDepartment && {
            label: selectedDepartment?.label,
            value: selectedDepartment?.value
          },
          selectedSection: selectedSection && {
            label: selectedSection?.label,
            value: selectedSection?.value
          },
          selectedSubsection: {
            label: row?.subSectionName,
            value: row?.subSection
          }
        }
        return state
      }
      else {
        return null
      }
    }
    navigate("/", { state: getFilteredData() });
  }

  const fetchInitialTableData = async () => {
    try {
      let url = `/pcinfo/org-dashboard/${user?.data?.user?.userId}`;
      if (userRoleName === "Super Admin" || userRoleName === "Organization Admin" || userRoleName === "Department Admin") {
        url = `/pcinfo/org-department-dashboard/${user?.data?.user?.userId}`;
      }
      else if (userRoleName === "Section Admin") {
        url = `/pcinfo/org-section-dashboard/${user?.data?.user?.userId}`;
      }
      else if (userRoleName === "subSection Admin") {
        url = `/pcinfo/org-sub-section-dashboard/${user?.data?.user?.userId}`;
      }

      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      if (userRoleName === "Super Admin" || userRoleName === "Organization Admin" || userRoleName === "Department Admin") {
        setColumnDataDepartment(response?.data?.data || []);
      }
      else if (userRoleName === "Section Admin") {
        setColumnDataSection(response?.data?.data || []);
      }
      else if (userRoleName === "subSection Admin") {
        setColumnDataSubsection(response?.data?.data || []);
      }
      else {
        setColumnDataDepartment([]);
        setColumnDataSection([]);
        setColumnDataSubsection([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  const fetchSectionTableData = async () => {
    try {
      if (!selectedDepartment) {
        return;
      }
      let url = `/pcinfo/org-section-dashboard/${user?.data?.user?.userId}?department=${selectedDepartment?.value}`;
      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      if (response?.data?.data?.length === 0) {
        setSelectedDepartment(null);
      }
      setSectionVisible(true);
      setColumnDataSection(response?.data?.data || []);
      setColumnDataSubsection([]);
      setSelectedSection(null);
      setSelectedSubsection(null);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  const fetchSubsectionTableData = async () => {
    try {
      if (!selectedSection) {
        return;
      }

      let url = `/pcinfo/org-sub-section-dashboard/${user?.data?.user?.userId}`
      if (selectedDepartment && selectedSection) {
        url += `?department=${selectedDepartment?.value}&section=${selectedSection?.value}`
      }
      else if (selectedDepartment) {
        url += `?department=${selectedDepartment?.value}`
      }
      else if (selectedSection) {
        url += `?section=${selectedSection.value}`;
      }
      else {
        return;
      }
      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      if (response?.data?.data?.length === 0) {
        setSelectedSection(null);
      }
      setSubsectionVisible(true);
      setColumnDataSubsection(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }


  useEffect(() => {
    fetchInitialTableData();
  }, []);

  useEffect(() => {
    if (selectedDepartment)
      fetchSectionTableData();
  }, [selectedDepartment]);

  useEffect(() => {

    if (selectedSection)
      fetchSubsectionTableData();
  }, [selectedSection]);

  const commonColumns = [
    {
      name: "Total",
      selector: (row) => row?.total,
      sortable: true,
    },
    {
      name: "Compliant",
      selector: (row) => row?.safe,
      sortable: true,
    },
    {
      name: "Non-Compliant",
      selector: (row) => row?.unsafe,
      sortable: true,
    },
    {
      name: "Unresponsive",
      selector: (row) => row?.unresponsive,
      sortable: true,
    },
  ]
  const columnsDepartment = [
    {
      name: "Command",
      selector: (row) => (
        <div className="org-view-table-column-name"
          onClick={() => {
            setSelectedDepartment({ label: row?.departmentName, value: row?._id });
          }}
        >
          {row?.departmentName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "department")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      ),
    }
  ]

  const columnsSection = [
    {
      name: "Node",
      selector: (row) => (
        <div className="org-view-table-column-name"
          onClick={() => {
            setSelectedSection({ label: row?.sectionName, value: row?.section });
          }}
        >
          {row?.sectionName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "section")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      ),
    }
  ]

  const columnsSubsection = [
    {
      name: "Agency",
      selector: (row) => (
        <div
        // onClick={() => {
        //     setSelectedSubsection(row?.subSection);
        // }}
        >
          {row?.subSectionName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "subSection")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="ri-arrow-right-line"></i>
          </Button>
        </div>
      ),
    }
  ]
  const [lineGraph, setLineGraph] = useState([]);
  const [monthGraph, setMonthGraph] = useState([]);
  const [monthGraphTwo, setMonthGraphTwo] = useState([]);
  const prevDeptResponseRef = useRef(null);
  const prevSectionRespRef = useRef(null);
  const [view, setView] = useState("Department");
  const prevResponseRef = useRef(null);
  const prevMonthResponseRef = useRef(null);

  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);

  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);

  const [chartSeries, setChartSeries] = useState([]);
  const [totalPcs, setTotalPcs] = useState(0);

  const [departmentGraph, setDepartmentGraph] = useState([]);
  const [sectionGraph, setSectionGraph] = useState([]);

  const buttonStyle = {
    backgroundColor: view === "Department" ? "#007bff" : "#28a745",
    color: "white",
    border: "none",
    padding: "3px 6px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "9px"
  };

  const fetchData = () => {
    let url = `/graph-data/${user?.data?.user?.userId}`;
    if (selectedDepartment && selectedSection && selectedSubsection) {
      url += `?department=${selectedDepartment.value}&section=${selectedSection.value}&subSection=${selectedSubsection.value}`;
    }
    else if (selectedDepartment && selectedSection) {
      url += `?department=${selectedDepartment.value}&section=${selectedSection.value}`;
    } else if (selectedDepartment) {
      url += `?department=${selectedDepartment.value}`;
    }
    summaryApi
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        const departmentCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts;
        const sectionCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts;
        const newResponse = response.data.response.sevenDayData;
        const monthResponse = response.data.graphData.monthGraph;
        if (
          JSON.stringify(prevResponseRef.current) !==
          JSON.stringify(newResponse)
        ) {
          setLineGraph(newResponse);
          prevResponseRef.current = newResponse;
        }

        if (
          JSON.stringify(prevMonthResponseRef.current) !==
          JSON.stringify(monthResponse)
        ) {
          setMonthGraph(monthResponse);
          prevMonthResponseRef.current = monthResponse;
        }

        if (
          JSON.stringify(prevDeptResponseRef.current) !==
          JSON.stringify(departmentCount)
        ) {
          setDeptSafeUnsafe(departmentCount);
          setDepartmentGraph(departmentCount);
          prevDeptResponseRef.current = departmentCount;
        }

        if (
          JSON.stringify(prevSectionRespRef.current) !==
          JSON.stringify(sectionCount)
        ) {
          setSectionSafeUnsafe(sectionCount);
          setSectionGraph(sectionCount);
          prevSectionRespRef.current = sectionCount;
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPieChartInfo = async () => {
    try {
      const response = await summaryApi
        .get(
          `/pie-chart/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )
      setTotalPcs(response?.data?.totalPCCount);
      setChartSeries([response?.data?.safeLinuxClients?.count,
      response?.data?.unsafeLinuxClients?.count]);
      setTimeout(() => setLoading(false), 500);
    }
    catch {
      setChartSeries([0, 0, 0]);
    }
  };

  useEffect(() => {
    fetchPieChartInfo();
    fetchData();
  }, [selectedDepartment]);

  const pieChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: ['Compliant', 'Non-compliant'],
    colors: ['#85e289', '#ea4d4d'],
    legend: {
      position: 'bottom',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
  };

  const cleanData = (data, type) =>
    data.map((item) => ({
      ...item,
      [type]: item[type] === "Unknown" ? "N/A" : item[type],
    }));

  // Simulate fetching the data and setting the state
  useEffect(() => {
    setDepartmentGraph(cleanData(departmentGraph, "department"));
    setSectionGraph(cleanData(sectionGraph, "section"));
  }, []);

  // Determine which data set to use based on the view (Department or Section)
  const graphData = view === "Department" ? departmentGraph?.map((item) => {
    if (Object.values(item)[0] === "Unknown") {
      return {
        ...item,
        department: "Unmapped",
      }
    }
    return item;
  }) : sectionGraph?.map((item) => {
    if (Object.values(item)[0] === "Unknown") {
      return {
        ...item,
        section: "Unmapped",
      }
    }
    return item;
  });

  // Extract safe and unsafe data for the graph
  const safeData = graphData.map((item) => item.safeCount);
  const unsafeData = graphData.map((item) => item.unsafeCount);
  const categories = graphData.map((item) =>
    view === "Department" ? item.department : item.section
  );

  // ApexCharts options
  const chartOptions = {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    colors: ["#28a745", "#dc3545"], // Green for safe, red for unsafe
    plotOptions: {
      bar: {
        columnWidth: "10%",
        endingShape: "rounded",
      },
    },
    xaxis: {
      categories: categories, // Departments or Sections
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    legend: { show: true },
    tooltip: { enabled: true },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
    },
  };

  const chartSeriesGraph = [
    {
      name: "Compliant",
      data: safeData, // Safe counts
    },
    {
      name: "Non-compliant",
      data: unsafeData, // Unsafe counts
    },
  ];

  // Handle toggle between Department and Section view
  const toggleView = () => {
    setView(view === "Department" ? "Section" : "Department");
  };

  return (
    <React.Fragment>
      {!loading ? (
        <>
          <div
            className="main main-app p-3 p-lg-4 d-flex flex-column gap-4"
          >
            <div className="d-md-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center justify-content-center fs-sm-normal ps-2">
                <Link to="/" className="shadow" style={{ borderRadius: "25px" }}>
                  <img src={linuxIcon} alt="Window-logo" width={55} height={55} />
                </Link>
                <p className="mx-2 fs-18 m-0 p-0">|</p>
                <p className="fs-20 fw-bolder p-0 m-0">Organization Overview</p>
              </span>
            </div>

            <div className="d-flex align-items-centerjustify-content-between card gap-3 card-one p-4 flex-col allCardEffect shadow">
              <div className="col-xl">
                <p className="fs-18 text-dark fw-bolder p-0 m-0">
                  {totalPcs !== 0 ? (
                    <>
                      Total Endpoints: <span>{totalPcs || 0}</span>
                    </>
                  ) : (
                    "No endpoints mapped yet."
                  )}
                </p>

              </div>
              {totalPcs !== 0 && (roleName === "Super Admin" || roleName === "Organization Admin") && <div className="d-flex gap-3">
                <Row className="card-one shadow p-3 bg-white m-auto" style={{ width: "70%" }}>
                  <Col xl="12">
                    <div>
                      <button onClick={toggleView} style={buttonStyle}>
                        View {view === "Department" ? "Node" : "Command"}-wise
                      </button>
                      <ReactApexChart
                        options={chartOptions}
                        series={chartSeriesGraph}
                        type="bar"
                        height={180}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="card-one shadow p-3 bg-white m-auto">
                  <ReactApexChart
                    options={pieChartOptions}
                    series={chartSeries}
                    height={250}
                    type="pie"
                  />
                </Row>
              </div>}
            </div>

            {totalPcs !== 0 && columnDataDepartment && columnDataDepartment.length > 0 && (
              <div className={`card rounded mb-2 shadow cardStyle cardStyleOrgView ${isDepartmentVisible ? 'table-visible' : 'table-hidden'}`}>
                {loading ? (
                  <PageLoader />
                ) : (
                  <DataTable
                    columns={columnsDepartment}
                    data={columnDataDepartment || []}
                    conditionalRowStyles={conditionalRowStylesDept}
                    noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                  />
                )}
              </div>
            )}

            {totalPcs !== 0 && columnDataSection && columnDataSection.length > 0 && (
              <div className={`card rounded mb-2 shadow cardStyle cardStyleOrgView position-relative ${isSectionVisible ? 'table-visible' : 'table-hidden'}`}>
                {loading ? (
                  <PageLoader />
                ) : (
                  <>
                    <DataTable
                      columns={columnsSection}
                      data={columnDataSection || []}
                      conditionalRowStyles={conditionalRowStylesSec}
                      noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                    />
                    {userRoleName !== "Section Admin" && <div className="d-flex align-items-center justify-content-center rounded-circle m-2 p-2 bg-danger shadow-lg position-absolute top-0 end-0"
                      style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                      onClick={hideSectionTable}
                    >
                      <i className="ri-subtract-line text-white"></i>
                    </div>}
                  </>
                )}
              </div>
            )}

            {totalPcs !== 0 && columnDataSubsection && columnDataSubsection.length > 0 && (
              <div className={`card rounded mb-2 shadow cardStyle cardStyleOrgView position-relative ${isSubsectionVisible ? 'table-visible' : 'table-hidden'}`}>
                {loading ? (
                  <PageLoader />
                ) : (
                  <>
                    <DataTable
                      columns={columnsSubsection}
                      data={columnDataSubsection || []}
                      noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                    />
                    {userRoleName !== "subSection Admin" && <div className="d-flex align-items-center justify-content-center rounded-circle m-2 p-2 bg-danger shadow-lg position-absolute top-0 end-0"
                      style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                      onClick={hideSubsectionTable}
                    >
                      <i className="ri-subtract-line text-white"></i>
                    </div>}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )
        :
        <div className="main main-app p-3 p-lg-4 d-flex flex-column gap-4">
          <PageLoader />
        </div>
      }
    </React.Fragment>
  )
}
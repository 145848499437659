import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { notifyApi } from "../../../api/axiosSet";
import Tippy from "@tippyjs/react";
import PageLoader from "../../../components/common/Loader/PageLoader";

function WhitelistApplication() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const [newAppName, setNewAppName] = useState("");
  const [newAppVersion, setNewAppVersion] = useState("");
  const [apps, setApps] = useState([{ name: "", version: "", status: true }]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const { user } = useSelector((state) => state.authSlice);
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [status, setStatus] = useState();
  const [id, setId] = useState();
  const [searchTerm, setSearchTerm] = useState(''); // Single input field for search
  const [authorize, setAuthorize] = useState([])
  const [buttonLabel, setButtonLabel] = useState(''); // Default label
  const [buttonClass, setButtonClass] = useState('btn-primary'); // Default color class
  const invalidCharacters = /[<>'"]/;

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async (bool) => {
    setLoading(true);
    if (bool) {
      setPageNumber(1);
    }
    try {
      // Construct the query params based on search input and pagination
      let queryParams = new URLSearchParams({
        osType: 'Linux', // Fixed osType
        page: pageNumber,
        limit: pageSize,
      });

      // Add the 'name' query parameter if searchTerm is provided
      if (searchTerm !== '') {
        queryParams.append('name', searchTerm);
      }

      const response = await notifyApi.get(
        `/get/all/apps/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.apps;
      setColumnData(devices);
      setTotalDocuments(response?.data?.pagination?.totalItems);
      setLoading(false);
    } catch (error) {
      setColumnData([]);
      setTotalDocuments(0);
      setLoading(false);
    }
  };

  // Trigger fetchData whenever user, pageNumber, pageSize, or searchTerm changes
  useEffect(() => {
    fetchData(false);
  }, [user, pageNumber, pageSize]);

  useEffect(() => {
    fetchData(true);
  }, [user, searchTerm]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedApps = [...apps];
    updatedApps[index][name] = value;
    setApps(updatedApps);
  };
  const handleStatusChange = (index, e) => {
    const { value } = e.target;
    const newStatus = value === 'true'; // Convert string value to boolean
    const newApps = [...apps];
    newApps[index] = { ...newApps[index], status: newStatus };
    setApps(newApps);
  };
  // Function to add a new app input pair
  const handleAddFields = () => {
    setApps([...apps, { name: '', version: '', status: true }]);
  };

  // Function to remove an app input pair
  const handleRemoveFields = (index) => {
    const updatedApps = apps.filter((_, i) => i !== index);
    setApps(updatedApps);
  };

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await notifyApi.get(
          `/all-department/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const exportToPDF = (data, filename = "WhitelistApplication.pdf") => {
    const doc = new jsPDF("landscape"); // Set landscape mode

    // Organization name and export date/time
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    // Add header with organization name on the left and export date/time on the right
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    ); // Right-aligned

    // Add title
    doc.setFontSize(15);
    doc.text("Whitelist Application", 14, 22);
    const startYPosition = 22; // Adjust this value as needed
    const tableColumn = ["S. No.", "Name", "Version", "Status", "Date"];
    const tableRows = data.map((row, index) => [
      index + 1,
      row.name,
      row.version,
      row.status === true ? "Authorized" : "Unauthorized",
      formatTimestamp(row.date) || "NA",
    ]);

    // Add table with borders for rows and columns
    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      styles: {
        cellPadding: 3, // Padding for cells
        lineColor: [44, 62, 80], // Border color (RGB)
        lineWidth: 0.1, // Border width
      },
      headStyles: {
        fillColor: [52, 73, 94], // Header background color
        textColor: 255, // Header text color
        halign: "center", // Center align header text
        lineWidth: 0.5, // Border width for header
      },
      bodyStyles: {
        lineColor: [44, 62, 80], // Row border color
        lineWidth: 0.1, // Border width for rows
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Background color for alternate rows
      },
    });

    doc.save(filename);
  };

  const exportToCSV = (rows, filename = "WhitelistApplication.csv") => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    // Header with organization name and export date/time
    const headerInfo = [
      `Organization: ${orgName}`,
      `Exported: ${exportDateTime}`,
    ];

    // Table headers
    const csvHeader = ["S. No.", "Name", "Version", "Status", "Date"];

    // Table rows
    const csvRows = rows.map((row, index) => [
      index + 1,
      row.name?.replace(",", ""),
      row.version?.replace(",", ""),
      row.status === true ? "Authorized" : "Unauthorized",
      formatTimestamp(row.date) || "NA",
    ]);

    // Combine all CSV content
    const csvContent = [
      headerInfo, // Add organization name and export date/time
      ["Whitelist Application"],
      [""],
      csvHeader, // Add the table headers
      ...csvRows, // Add the data rows
    ]
      .map((e) => e.join(",")) // Join each array into a comma-separated string
      .join("\n"); // Join each row with a new line

    // Create a Blob and download link for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this application?"
    );

    if (confirmDelete) {
      try {
        await notifyApi.delete(
          `/delete/apps/by/${id}/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
            data: { id }
          }
        );
        toast.success("App deleted successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
        fetchData();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      // User canceled the deletion
      // toast.info("Deletion canceled.", {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "light",
      // });
    }
  };


  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    const data = {
      name: name?.trim(),
      version: version?.trim(),
      status: status,
    };
    const isValid = (app) => {
      if (app.name.trim() === "") {
        alert(`Please enter a valid name.`);
        return false;
      }

      if (app.version.trim() === "") {
        alert(`Please enter a valid version.`);
        return false;
      }

      if (invalidCharacters.test(app.name)) {
        alert(`App name "${app.name}" contains invalid characters.`);
        return false;
      }

      if (invalidCharacters.test(app.version)) {
        alert(`App version "${app.version}" contains invalid characters.`);
        return false;
      }

      return true;
    };

    if (!isValid(data)) {
      return;
    }

    try {
      await notifyApi.put(
        `/update/app/by/${id}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success("Apps Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setSelectedRows([]);
      fetchData();
      // Close the modal
      document.getElementById("closeModal").click();
      document.getElementById("closeModal2").click();
    } catch (error) {
      toast.error(error);
      console.error("Error adding user:", error);
      // Handle error response
    }
  };

  const handleUpdateStatus = async (e) => {
    e.preventDefault();

    // Extracting the IDs and statuses from the authorize array
    const ids = authorize.map(item => item._id);
    const statusUpdate = authorize.map(item => item?.status);

    // Check if the status array contains both true and false
    const hasTrue = statusUpdate.includes(true);
    const hasFalse = statusUpdate.includes(false);

    if (hasTrue && hasFalse) {
      // If both true and false are present, show an alert
      alert("You can only authorize or unauthorize, not both at the same time.");
      return;
    }

    // Determine the status to send in the request (either all true or all false)
    const statusToUpdate = hasTrue === true ? false : true;

    const data = {
      ids: ids, // Adding the array of IDs
      status: statusToUpdate, // Sending the determined status
    };

    try {
      await notifyApi.put(
        `/update/app/by/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response 
      toast.success(statusToUpdate === true ? ' Apps Authorized Successfully!' : "Apps Unauthorized Successfully!"
        , {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
      setSelectedRows([]);
      fetchData();
      // Close the modal
      document.getElementById("closeModal").click();
    } catch (error) {
      toast.error(error.message || "Error updating status");
      console.error("Error updating status:", error);
      // Handle error response
    }
  };


  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      selector: (row) => (<Tippy maxWidth="none" content={row.name}>
        <div>{row.name}
        </div></Tippy>),
      sortable: true,
    },
    {
      name: "Version",
      selector: (row) => row.version,
    },
    {
      name: "Status",
      selector: (row) => row.status || "NA",
      cell: (row) => (
        <span
          className={`badge rounded-pill ${row.status === true
            ? "text-bg-success text-white"
            : "text-bg-danger text-white"
            }`}
          disabled
        >
          {row.status === true ? "Authorized" : "Unauthorized"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatTimestamp(row.date) || "NA",
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row._id || "NA",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() =>
              handleUpdate({
                name: row.name,
                version: row.version,
                status: row.status,
                id: row._id,
              })
            }
            data-bs-toggle="modal"
            data-bs-target="#exampleModal2"
          >
            <i className="ri-edit-line"></i>
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-outline-danger bg-btn-danger"
            onClick={() => handleDelete(row._id)}
          >
            <i className="ri-delete-bin-7-fill"></i>
          </button>
        </>
      ),
    },
  ];


  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setAuthorize(selectedRows)
  };
  useEffect(() => {
    if (selectedRows.length > 0) {
      const allActive = selectedRows.every(row => row.status === true);
      const allInactive = selectedRows.every(row => row.status === false);

      if (allActive) {
        setButtonLabel('Unauthorize');
        setButtonClass('btn-danger'); // Red for unauth

      } else if (allInactive) {
        setButtonLabel('Authorize');
        setButtonClass('btn-success'); // Blue for auth

      } else {
        setButtonLabel('Mixed Status'); // Optional: Handle mixed status cases
        setButtonClass('btn-warning'); // Yellow for mixed status

      }
    } else {
      setButtonLabel('Permit App'); // Default if no rows are selected
      setButtonClass('btn-primary'); // Default to blue if no rows are selected

    }
  }, [selectedRows]);
  const handleUpdate = (row) => {
    setId(row.id);
    setName(row.name || "");
    setVersion(row.version || "");
    setStatus(row.status || false);
  };

  const handleAddApp = async (e) => {
    e.preventDefault();

    const isValid = apps.every((app) => {
      if (app.name.trim() === "") {
        alert(`Please enter a valid name.`);
        return false;
      }

      if (app.version.trim() === "") {
        alert(`Please enter a valid version.`);
        return false;
      }

      if (invalidCharacters.test(app.name)) {
        alert(`App name "${app.name}" contains invalid characters.`);
        return false;
      }

      if (invalidCharacters.test(app.version)) {
        alert(`App version "${app.version}" contains invalid characters.`);
        return false;
      }

      return true;
    });

    if (!isValid) {
      return;
    }
    const data = {
      organizationId: user?.data?.user?.organizationId,
      osType: "Linux",
      apps: apps,
    };

    try {
      await notifyApi.post(
        `/add-apps/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success("Apps Added Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setApps([{ name: "", version: "", status: true }]);
      setSelectedRows([]);
      fetchData();
      // Close the modal
      document.getElementById("closeModal").click();
    } catch (error) {
      document.getElementById("closeModal").click();
      toast.error(error?.response?.data?.message || "Failed to add apps.");
    }
  };

  useEffect(() => {
    const modal = document.getElementById('exampleModal');

    const handleModalClose = () => {
      setApps([{ name: "", version: "", status: true }]);
    };

    modal.addEventListener('hidden.bs.modal', handleModalClose);
    return () => {
      modal.removeEventListener('hidden.bs.modal', handleModalClose);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
            <p className="fs-20 text-dark fw-bolder p-0 m-0">
              Whitelist Applications
            </p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF(columnData)}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(columnData)}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18  fw-bolder p-0 m-0">Applications List</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Applications: {totalDocuments}{" "}
            </p>
          </div>

          <div className="" style={{ position: "relative", width: "17rem" }}>
            <input
              style={{ paddingRight: "1.3rem" }}
              className="form-control"
              type="text"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
          </div>
          &nbsp; &nbsp;
          <button
            type="button"
            className={`btn ${buttonClass} text-white`}
            onClick={handleUpdateStatus}
            disabled={selectedRows.length === 0}
          >
            <i className="ri-shield-check-line" style={{ marginRight: "3px" }}></i>
            {buttonLabel}
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-info text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i className="ri-add-line"></i> Apps
          </button>

        </div>
        <div className="card rounded mb-2 shadow cardStyle">
          {loading ? (
            <PageLoader />
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              selectableRowsHighlight
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              selectableRows
              // clearSelectedRows={selectedRows.length === 0} // Clear selected rows when state is reset
              onSelectedRowsChange={handleSelectedRowsChange}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          )}
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Apps
                </h1>
                <button
                  type="button"
                  id="closeModal"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleAddApp}>
                  {apps.map((app, index) => (
                    <div key={index} className="mb-3">
                      <label htmlFor={`name-${index}`} className="form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`name-${index}`}
                        name="name"
                        maxLength={50}
                        value={app.name}
                        onChange={(e) => handleInputChange(index, e)}
                        required
                      />
                      <label htmlFor={`version-${index}`} className="form-label">
                        Version <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`version-${index}`}
                        name="version"
                        value={app.version}
                        maxLength={50}
                        onChange={(e) => handleInputChange(index, e)}
                        required
                      />
                      <fieldset className="mb-3">
                        <label className="form-label">Status</label>
                        <div className="form-check">
                          <input
                            type="radio"
                            id={`status-active-${index}`}
                            name={`status-${index}`}
                            value="true"
                            checked={app.status === true}
                            onChange={(e) => handleStatusChange(index, e)}
                            className="form-check-input"
                            required
                          />
                          <label htmlFor={`status-active-${index}`} className="form-check-label">
                            <span className="badge rounded-pill text-bg-success">Authorized</span>
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            id={`status-inactive-${index}`}
                            name={`status-${index}`}
                            value="false"
                            checked={app.status === false}
                            onChange={(e) => handleStatusChange(index, e)}
                            className="form-check-input"
                          />
                          <label htmlFor={`status-inactive-${index}`} className="form-check-label">
                            <span className="badge rounded-pill text-bg-danger">Unauthorized</span>
                          </label>
                        </div>
                      </fieldset>
                      {apps && apps.length > 1 && <button
                        type="button"
                        className="btn btn-sm btn-danger mt-2"
                        onClick={() => handleRemoveFields(index)}
                      >
                        Remove
                      </button>}
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary mt-3"
                    onClick={handleAddFields}
                  >
                    Add More
                  </button>
                  &nbsp; &nbsp;
                  <button type="submit" className="btn btn-primary mt-3">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Item
                </h1>
                <button
                  type="button"
                  id="closeModal2"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmitUpdate}>
                  <div className="mb-3">
                    <label htmlFor="nameInput" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameInput"
                      value={name}
                      maxLength={50}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="versionInput" className="form-label">
                      Version <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="versionInput"
                      value={version}
                      maxLength={50}
                      onChange={(e) => setVersion(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="versionInput" className="form-label">
                      Current Status: &nbsp;
                      <span
                        className={`badge rounded-pill ${status === true
                          ? "text-bg-success text-white"
                          : "text-bg-danger text-white"
                          }`}
                        disabled
                      >
                        {status === true ? "Authorized" : "Unauthorized"}
                      </span>
                    </label>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="statusCheck"
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="statusCheck">
                      Authorize
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhitelistApplication;

import React from "react";
import Dashboard from "../dashboard/DashBoard.js";
import LinuxDetail from "../pages/linux/LinuxDetail";
import LinuxSeverityData from "../pages/linux/LinuxSeverityData";
import LinuxSeverityUsb from "../pages/linux/LinuxSeverityUsb";
import LinuxUsbViolation from "../pages/linux/LinuxUsbViolation.jsx";
import LinuxClientDetail from "../pages/linux/LinuxClientsDetail.js";

import AllNotification from "../layouts/AllNotification.jsx";
import UpdateProfile from "../pages/Useradmin/UpdateProfile";
import OrganizationView from "../pages/Useradmin/OrganizationView.jsx";
import DownloadAgent from "../pages/Admin/Download/DownloadAgent.jsx"

import WindowDetail from "../pages/Windows/WindowDetail.js";
import WindowSeverityData from "../pages/Windows/WindowsSeverityData.js";
import WindowSeverityUsb from "../pages/Windows/WindowsSeverityData"
import WindowUsbViolation from "../pages/Windows/WindowsUsbViolation.jsx";
import WindowClientDetail from "../pages/Windows/WindowsClientsDetail.js";

const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  { path: "/organization-overview", element: <OrganizationView /> },
  { path: "/download-agent", element: <DownloadAgent /> },
  { path: "/all-notification", element: <AllNotification /> },

  { path: "/linux-dash", element: <LinuxDetail /> },
  { path: "/linux-severity", element: <LinuxSeverityData /> },
  { path: "/linux-severity-usb", element: <LinuxSeverityUsb /> },
  { path: "/linux-usb-violation", element: <LinuxUsbViolation /> },
  { path: "/linux-client-Details", element: <LinuxClientDetail /> },

  { path: "/windows-dash", element: <WindowDetail /> },
  { path: "/windows-severity", element: <WindowSeverityData /> },
  { path: "/windows-severity-usb", element: <WindowSeverityUsb /> },
  { path: "/windows-usb-violation", element: <WindowUsbViolation /> },
  { path: "/windows-client-Details", element: <WindowClientDetail /> },
];

export default protectedRoutes;

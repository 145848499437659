import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { linuxApi, notifyApi } from "../../../api/axiosSet";
import Tippy from "@tippyjs/react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { Dropdown, Menu, Button } from "antd";
import 'tippy.js/dist/tippy.css';
import PageLoader from "../../../components/common/Loader/PageLoader";
import { osWiseIcons } from "../../../components/common/osWiseIcons";

function WhitelistUsbDevice() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [pcId, setPcId] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([""]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [state, setState] = useState(""); // For the state dropdown
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPcId, setSelectedPcId] = useState("");
  const [selectedServiceNo, setSelectedServiceNo] = useState("");
  const [selectedUsbHistoryId, setSelectedUsbHistoryId] = useState("");
  const [adminActionData, setAdminActionData] = useState();

  const [activeRow, setActiveRow] = useState(null);
  const { user } = useSelector((state) => state.authSlice);

  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async (bool) => {
    setLoading(true);

    if (bool) {
      setPageNumber(1);
    }
    try {
      let url = `/usbhistoryinfo/get-pc-with-whitelisted-usb/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`;
      if (searchTerm) {
        url += `&identifier=${searchTerm}`
      }
      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      setColumnData(response.data.data);
      setLoading(false);
    } catch (error) {
      setTotalDocuments(0);
      setColumnData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [user, pageNumber, pageSize]);

  // useEffect(() => {
  //   fetchData(true);
  // }, [user, searchTerm]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleStatusChange = async (userId, pcId, serviceno) => {

    try {
      const data = {
        falsePositive: false,
        serviceNo: serviceno,
        pcId: pcId,
      };

      const response = await linuxApi.put(
        `/usbhistoryinfo/update-false-positive/${userId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchData();
        // window.location.reload();
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      // toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };

  const handleSerialClick = (row) => {
    // Set the active row when serial number is clicked
    setActiveRow(row);
  };

  // Function to render the dropdown menu with USB Vendor and Product IDs
  const menu = (row) => (
    <Menu>
      {row.usbName && (
        <Menu.Item key="usbName">
          <strong>USB Name:</strong> {row.usbName}
        </Menu.Item>
      )}
      {row.usbProductId && (
        <Menu.Item key="productId">
          <strong>Product ID:</strong> {row.usbProductId}
        </Menu.Item>
      )}
      {row.usbVendorId && (
        <Menu.Item key="vendorId">
          <strong>Vendor ID:</strong> {row.usbVendorId}
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      name: "Hostname",
      selector: (row) => row?.hostname,
      cell: (row) => (
        <Tippy maxWidth="none" content={row.hostname || "N/A"}>
          <span>{row.hostname || "N/A"}</span>
        </Tippy>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Serial Number",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.serialNumber || "N/A"}>
          <span>{row.serialNumber || "N/A"}</span>
        </Tippy>
      ),
      width: "180px",
    },
    {
      name: "IP Address",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.ipAddress?.split(" ").join(", ") || "N/A"}>
          <span>{row.ipAddress?.split(" ").join(", ") || "N/A"}</span>
        </Tippy>
      ),
      width: "180px",
    },
    {
      name: "Timestamp",
      selector: (row) => row?.time,
      cell: (row) => (
        <Tippy maxWidth="none" content={row?.time}>
          <span>{row?.time}</span>
        </Tippy>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "USB Manufacturer",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.usbManufacturer || "N/A"}>
          <span>{row.usbManufacturer || "N/A"}</span>
        </Tippy>
      ),
      width: "180px",
    },
    {
      name: "USB Serial Number",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.usbSerialNumber}>
          {/* Dropdown for USB details on serial click */}
          <Dropdown
            overlay={menu(row)}
            trigger={["click"]} // Trigger dropdown on click
            visible={activeRow === row} // Show dropdown only for the clicked row
            onVisibleChange={(visible) => !visible && setActiveRow(null)} // Close dropdown on outside click
          >
            <div onClick={() => handleSerialClick(row)}>
              <Button type="link" className="p-0">
                <span> {row.usbSerialNumber}</span>
                {/* <i className="ri-arrow-down-s-line"></i> */}
              </Button>
            </div>
          </Dropdown>
        </Tippy>
      ),
      width: "240px",
    },
    {
      name: "Interface Type",
      selector: (row) => row.interfaceType || "N/A",
      width: "180px",
    },

    {
      name: "Admin",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.serviceNo || "N/A"}>
          <span>{row?.serviceNo || "N/A"}</span>
        </Tippy>
      ),
      width: "180px",
    },
    {
      name: "Admin Updated At",
      selector: (row) => row?.AdminUpdatedAt,
      cell: (row) => (
        <Tippy maxWidth="none" content={row?.AdminUpdatedAt !== "NA" ? formatTimestamp(row?.AdminUpdatedAt || "N/A") : "NA"}>
          <span>{row?.AdminUpdatedAt !== "NA" ? formatTimestamp(row?.AdminUpdatedAt || "N/A") : "NA"}</span>
        </Tippy>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            type="button"
            // className="btn btn-sm btn-outline-dark"
            className={`btn btn-sm ${row?.falsePositive === false ? "btn-outline-dark" : "btn-dark"
              }`}
            style={{
              width: "8rem",
              textAlign: "center",
              marginRight: "2rem",
            }} // Add margin-right for spacing
            onClick={() =>
              handleStatusChange(
                row.usbHistoryId,
                row?.pcId,
                user?.data?.user?.email
              )
            } // Use an arrow function to pass the parameters
            disabled={row?.falsePositive === false}
          >
            {" "}
            False Negative{" "}
          </button>

          <button
            type="button"
            // className="btn btn-sm btn-outline-info"
            className={`btn btn-sm ${row?.is_Linked === false ? "btn-outline-info" : "btn-info"
              }`}
            style={{ width: "6rem", textAlign: "center", marginRight: "2rem" }}
            onClick={(e) => handleSubmit(e, row?.pcId, row?.usbSerialNumber, row.usbHistoryId)} // Use an arrow function to pass the parameters
            disabled={row?.is_Linked === false}
          >
            {" "}
            Un-whitelist
          </button>

          {/* btn-outline-primary  for disible mode set this class */}
          <div style={{ position: "relative" }}>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary position-relative"
              style={{ width: "10rem", textAlign: "center" }}
              id="openModalButton"
              data-bs-toggle="modal"
              data-bs-target="#deviceModal"
              onClick={() => {
                handleOpenModal(row?.pcId, row?.usbHistoryId);
              }}
              disabled={
                row?.IsadminActionDone === "completed" ||
                row?.IsadminActionDone === "inProgress" ||
                row?.IsadminActionDone === "pending"
              }
            >
              Admin Action
            </button>

            <button
              type="button"
              // className="btn btn-sm btn-danger position-absolute"
              className={`btn btn-sm position-absolute ${row?.IsadminActionDone === "pending"
                ? "btn-danger"
                : row?.IsadminActionDone === "inProgress"
                  ? "btn-warning"
                  : row?.IsadminActionDone === "completed"
                    ? "btn-success"
                    : "btn-dark"
                }`}
              id="openModalButton2"
              data-bs-toggle="modal"
              data-bs-target="#deviceModal2"
              disabled={row?.adminActionCount === 0}
              style={{
                top: "-10px",
                right: "-11px",
                height: "22px", // Height and width should be equal for a circle
                width: "22px", // Set width to match height
                borderRadius: "50%", // Make it circular
                display: "flex", // Use flexbox to center text
                alignItems: "center", // Center vertically
                justifyContent: "center", // Center horizontally
                padding: 0, // Remove default padding
              }}
              onClick={() => handleOpenModalCount(row?.pcId, row?.usbHistoryId)}
            >
              <span style={{ fontSize: "13px" }}>{row?.adminActionCount}</span>
            </button>
          </div>
        </>
      ),
    },
  ];


  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData?.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Timestamp", key: "time" },
        { label: "USB Manufacturer", key: "usbManufacturer" },
        { label: "USB Serial Number", key: "usbSerialNumber" },
        { label: "Interface Type", key: "interfaceType" },
        { label: "Service No", key: "serviceNo" },
        { label: "Admin Updated At", key: "AdminUpdatedAt" },
        { label: "USB Name", key: "usbName" },
        { label: "Vendor ID", key: "usbVendorId" },
        { label: "Product ID", key: "usbProductId" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name} | Path: ${directory.path} | Permissions:(Owner:${permissions.owner}|Group: ${permissions.group}|Other: ${permissions.other})`;
          })
          .join(" || ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses.split(" ").join(" | ");
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const formatAdminAccountsName = (adminAccountsName) => {
        if (!adminAccountsName || adminAccountsName.length === 0) return "N/A"; // Handle empty or undefined case

        return adminAccountsName
          .map((adminAccount) => adminAccount)
          .join(" | ");
      }

      const formatMultipleAvs = (avs) => {
        if (!avs || avs.length === 0) return "N/A";
        return avs
          .map((av) => av)
          .join(" | ");
      };

      const formatHardeningIssues = (hardeningIssues) => {
        if (hardeningIssues) {
          return Object.entries(hardeningIssues)
            .map(([key, value]) => {
              return `${key}: ${value}`;
            })
            .join(" | ");
        }
        return "No issues";
      }

      const csvData = [
        tableColumn,
        ...columnData.map((item, index) => [
          index + 1,
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              : header.key === "adminAccountsName"
                ? formatAdminAccountsName(item[header.key])
                : header.key === "updatedAt" ?
                  formatTimestamp(item[header.key])
                  : header.key === "usersPasswordAge"
                    ? formatUsersPasswordAge(item[header.key])
                    : header.key === "sharedDirectories"
                      ? formatSharedDirectories(item[header.key])
                      : header.key === "avDetails"
                        ? formatAvDetails(item[header.key])
                        : header.key === "service"
                          ? formatOsDetails(item[header.key])
                          : header.key === "notInstalledAvs" ?
                            formatMultipleAvs(item[header.key])
                            : header.key === "hardeningIssues"
                              ? formatHardeningIssues(item[header.key])
                              : header.key === "AdminUpdatedAt" ?
                                formatTimestamp(item[header.key]) || "N/A"
                                : item[header.key]
          )
        ]),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "Whitelist USB Devices",
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `whitelist_USB_Devices.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Whitelist USB Devices`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => columnData?.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Timestamp", key: "time" },
        { label: "USB Manufacturer", key: "usbManufacturer" },
        { label: "USB Serial Number", key: "usbSerialNumber" },
        { label: "Interface Type", key: "interfaceType" },
        { label: "Service No", key: "serviceNo" },
        { label: "Admin Updated At", key: "AdminUpdatedAt" },
        { label: "USB Name", key: "usbName" },
        { label: "Vendor ID", key: "usbVendorId" },
        { label: "Product ID", key: "usbProductId" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join(" | ");
      };

      const formatAdminAccountsName = (adminAccountsName) => {
        if (!adminAccountsName || adminAccountsName.length === 0) return "N/A";

        return adminAccountsName
          .map((adminAccount) => adminAccount)
          .join(" | ");
      }

      const formatHardeningIssues = (hardeningIssues) => {
        if (hardeningIssues) {
          return Object.entries(hardeningIssues)
            .map(([key, value]) => {
              return `${key}: ${value}`;
            })
            .join(", ");
        }
        return "No issues";
      }

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses.split(" ").join(", ");
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, ", ");
      };

      columnData.forEach((item, index) => {
        const rowData = [index + 1, ...headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "adminAccountsName"
              ? formatAdminAccountsName(item[header.key])
              : header.key === "updatedAt" ?
                formatTimestamp(item[header.key]) :
                header.key === "sharedDirectories"
                  ? formatSharedDirectories(item[header.key])
                  : header.key === "usersPasswordAge"
                    ? item[header.key]
                      .map(
                        (user) =>
                          `${user.userType} :${user.userName}: ${user.passwordAge}`
                      )
                      .join("; ")
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "ipAddress"
                          ? formatMultipleIpAddresses(item[header.key])
                          : header.key === "AdminUpdatedAt" ?
                            formatTimestamp(item[header.key]) || "N/A"
                            : header.key === "hardeningIssues"
                              ? formatHardeningIssues(item[header.key])
                              : Array.isArray(item[header.key])
                                ? item[header.key].join(", ")
                                : item[header.key]
        )];
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          fontSize: 8,
          cellPadding: 3, // Padding for all cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
        // Conditional column width using columnStyles
        columnStyles: {
          0: { cellWidth: 12 },
          4: { cellWidth: 20 },
          9: { cellWidth: 20 },
          [tableColumn.indexOf('Hardening Issues')]: {
            cellWidth: 100,
            halign: "left",
          },
        },
      });

      // Save the PDF
      doc.save(`whitelist_USB_Devices.pdf`);
    }
  }

  const handleSerialNumberChange = (index, value) => {
    const newSerialNumbers = [...serialNumbers];
    newSerialNumbers[index] = value;
    setSerialNumbers(newSerialNumbers);
  };
  const addSerialNumberField = () => {
    setSerialNumbers([...serialNumbers, ""]);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    const pcIds = selectedRows.map((row) => row.pcId);
    const serialNumbers = selectedRows.map((row) => row.serialNumber);
    setPcId(pcIds);
    setSerialNumbers(serialNumbers);

  };

  const handleOpenModal = async (pcId, usbHistoryId) => {
    setSelectedPcId(pcId); // Store pcId in state
    setSelectedUsbHistoryId(usbHistoryId); // Store usbHistoryId in state
    // setSelectedServiceNo(serviceNo); // Store serviceNo in state
    await HandleAdminget(usbHistoryId);
  };

  const handleOpenModalCount = async (pcId, usbHistoryId) => {
    setSelectedPcId(pcId); // Store pcId in state
    setSelectedUsbHistoryId(usbHistoryId); // Store usbHistoryId in state
    // setSelectedServiceNo(serviceNo); // Store serviceNo in state
    await HandleAdminget(usbHistoryId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getBadgeClass = (action) => {
    switch (action) {
      case "actionInitiated":
        return "text-bg-danger";
      case "updateViolation":
        return "text-bg-warning";
      case "closeViolation":
        return "text-bg-success";
      default:
        return "text-bg-secondary"; // fallback class
    }
  };

  // Inside your component render
  const action =
    Array.isArray(adminActionData) && adminActionData?.length > 0
      ? adminActionData[0]?.action
      : null;

  const badgeClass = getBadgeClass(action);

  const handleSubmit = async (e, pcId, serialNumbers, usbHistoryId) => {
    e.preventDefault();
    try {
      const data = {
        organization_Id: user?.data?.user?.organizationId,
        // pcIds: pcId.map((item) => item.pc_Id),
        // usbSerialNumbers: serialNumbers.filter((sn) => sn.trim() !== ""), // Filter out any empty values
        pcIds: [pcId],
        usbSerialNumbers: [serialNumbers],
        serviceNo: user?.data?.user?.email,
        usbHistoryIds: [usbHistoryId]
      };

      const response = await notifyApi.post(
        `/de-link/usbs/to-devices/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form here
        // setSerialNumbers(['']);
        fetchData();
        // document.getElementById("closeModal").click();
        // setToggleClearRows(!toggleClearRows); // Toggle clear rows
        // setSerialNumbers([]);
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitControl = async (e) => {
    e.preventDefault();
    try {
      const data = {
        action: state,
        comment: comment,
        usbHistoryId: selectedUsbHistoryId,
        serviceNo: user?.data?.user?.email,
        pcId: selectedPcId,
      };

      const response = await notifyApi.post(
        `/create-admin-action/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.data?.message?.length > 0) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form here

        // document.getElementById("closeModal").click();

        // setSerialNumbers([]);
        setState(""); // Reset the state
        setComment(""); // Reset the comment
        HandleAdminget(selectedUsbHistoryId);
        fetchData();
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const HandleAdminget = async (usbHistoryId) => {
    try {
      const response = await notifyApi.post(
        `/get-admin-action/by/${usbHistoryId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setAdminActionData(response?.data?.data);

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form or fetch updated data
      } else {
        toast.error("Failed to de-link USBs from devices");
      }
    } catch (error) {
      console.error("Error de-linking USBs from devices:", error);
      toast.error("Error de-linking USBs from devices");
    }
  };

  const columnsAdmin = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      width: "90px",
    },
    {
      name: "Date",
      selector: (row) => formatTimestamp(row?.createdAt),
      cell: (row) => {
        if (!row.createdAt) return null;
        return (
          <Tippy maxWidth="none" content={formatTimestamp(row.createdAt)}>
            <div>{formatTimestamp(row.createdAt)}</div>
          </Tippy>
        );
      },
      sortable: true,
      width: "160px",
    },
    {
      name: "State",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.action} placement="top">
          <span>{row.action}</span>
        </Tippy>
      ),
      width: "130px",
    },

    {
      name: "Comment",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.comment} placement="top">
          <span>{row.comment}</span>
        </Tippy>
      ),
    },
  ];

  const [selectedOS, setSelectedOS] = useState(Object.values(osWiseIcons)[0] || "Windows");

  const handleOSClick = (os) => {
    setSelectedOS(os);
  };

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
            <p className="fs-20 fw-bolder text-dark  p-0 m-0">
              Whitelist USB Devices
            </p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF()}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV()}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 fw-bolder p-0 m-0">USB Lists</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total USB: {totalDocuments}
            </p>
          </div>
          <div className="" style={{ position: "relative", width: "17rem" }}>
            <input
              style={{ paddingRight: "1.3rem" }}
              className="form-control"
              type="text"
              placeholder="Search by Hostname or Serial No."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
          </div>
        </div>

        <div className="w-100 os-icons d-flex justify-content-around align-items-center card card-one py-2 flex-row rounded cardStyle switch-os">
          {Object.values(osWiseIcons).map((dashIcon, index) => (
            <div
              key={index}
              className={`image-box d-flex align-items-center justify-content-center os-icon ${selectedOS === dashIcon ? "selected" : ""}`}
              style={{ height: "3rem", width: "3rem" }}
              onClick={() => handleOSClick(dashIcon)}
            >
              <img className={`icon-img ${dashIcon.includes("mac") ? "apple-logo" : dashIcon.includes("linux") ? "linux-logo" : ""}`} src={dashIcon} width={30} height={30} />
              <div className="connection-shape"></div>
            </div>
          ))}
        </div>

        <div className="card rounded mb-2 shadow cardStyle">
          {loading ? (
            <PageLoader />
          ) : (
            <DataTable
              columns={columns}
              data={columnData.filter(item => item.hostname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.serialNumber.toLowerCase().includes(searchTerm.toLowerCase())) || columnData}
              pagination
              selectableRowsHighlight
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onSelectedRowsChange={handleSelectedRowsChange}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
      <div
        className="modal fade"
        id="deviceModal"
        tabIndex="-1"
        aria-labelledby="deviceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deviceModalLabel">
                Control <i className="ri-usb-line"></i>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              ></button>
            </div>
            <div className="m-3">
              <div className="card rounded mb-2 shadow cardStyle mt-2">
                <form onSubmit={(e) => handleSubmitControl(e, state, comment)}>
                  <div className="modal-body">
                    {/* Dropdown for state selection */}
                    <div className="mb-3">
                      <label htmlFor="stateSelect" className="form-label">
                        State
                      </label>
                      <select
                        id="stateSelect"
                        className="form-select"
                        value={state} // Bind the value to the state
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        <option value="">Select State</option>
                        <option value="actionInitiated">
                          Action Initiated
                        </option>
                        <option value="updateViolation">
                          Violation Updated
                        </option>
                        <option value="closeViolation">Violation Closed</option>
                      </select>
                    </div>

                    {/* Comment textarea */}
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">
                        Comment
                      </label>
                      <textarea
                        id="comment"
                        className="form-control"
                        rows="3"
                        value={comment} // Bind the value to the comment
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Add your comment here..."
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-sm btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <div className="card rounded mb-2 shadow cardStyle mt-2">
                <DataTable
                  columns={columnsAdmin}
                  data={adminActionData}
                  pagination
                  noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deviceModal2"
        tabIndex="-1"
        aria-labelledby="deviceModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="deviceModalLabel2">
                Admin Actions History
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="card rounded mb-2 shadow cardStyle mt-2">
                <DataTable
                  columns={columnsAdmin}
                  data={adminActionData}
                  pagination
                  noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhitelistUsbDevice;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";
import CustomTable from "../../components/common/Table/CustomTable";
import { linuxApi } from "../../api/axiosSet";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

function LinuxSeverityData() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useSelector((state) => state.authSlice);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (!state?.callUrl) {
      navigate("/")
    }
  }, [state?.callUrl])

  const fetchData = async (bool) => {
    let isMounted = true;
    if (bool) {
      setPageNumber(1);
    }
    try {
      setLoading(true);
      let url = `/${state?.callUrl}/${user?.data?.user?.userId}`;
      const selectedDepartment = state?.selectedDepartment;
      const selectedSection = state?.selectedSection;
      const selectedSubsection = state?.selectedSubsection;
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${selectedSection.value}`;
      }
      if (selectedSubsection) {
        url += `${selectedDepartment || selectedSection ? "&" : "?"}subSection=${selectedSubsection.value}`;
      }
      if (selectedDepartment || selectedSection || selectedSubsection) {
        url += `&page=${pageNumber}&pageSize=${pageSize}`
      }
      else {
        url += `?page=${pageNumber}&pageSize=${pageSize}`
      }
      if (searchTerm) {
        url += `&identifier=${searchTerm}`
      }
      const response = await linuxApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      if (isMounted) {
        if (response?.data?.data.length > 0) {
          const calculateData = response?.data?.data?.map((v) => {
            let returnData = {
              hostname: v?.hostname || "NA",
              serialNumber: v?.serialNumber || "NA",
              ipAddress: v?.ipAddress?.split(" ") || [],
              clientScore: v?.auditScore,
              updatedAt: v?.updatedAt,
              adminAccountsName: v?.adminAccountsName,
              sharedDirectories: v?.sharedDirectories,
              usersPasswordAge: v?.usersPasswordAge,
              notInstalledAvs: v?.notInstalledAvs,
              avDetails: v?.avDetails,
              osDetails: v?.osDetails,
              ssid: v?.ssid,
              authenticationType: v?.authenticationType,
              osName: v?.osName,
              currentOSVersion: v?.currentOSVersion,
              expectedOSVersion: v?.expectedOSVersion,
              avName: v?.avName,
              currentAVVersion: v?.currentAVVersion,
              expectedAVVersion: v?.expectedAVVersion,
              antivirusName: v?.antivirusName,
              antivirusVersion: v?.antivirusVersion,
              service: v?.service,
              serverTimestamp: v?.serverTimestamp,
              highCpuUsageCount: v?.highCpuUsageCount,
              highRamUsageCount: v?.highRamUsageCount,
              highCpuUsage: v?.highCpuUsage,
              ramUsage: v?.ramUsage,
              hardeningIssues: v?.hardeningIssues,
              action: "yourActionValue",
              pc_Id: v?.pcId || "NA",
              mismatchCount: v?.mismatchCount || 0,
              nonWhitelistedCount: v?.nonWhitelistedCount || 0,
              nonWhitelistedServiceCount: v?.nonWhitelistedServiceCount || 0,
              edrName: v?.edrName,
              zenWorksName: v?.zenWorksName,
              certificateName: v?.certificateName,
              firewallService: v?.firewallService,
              OsNotHardenedmismatchCount: v?.OsNotHardenedmismatchCount || 0,
            };
            const title = state?.titleState;
            if (title === "FIPS Not Enabled") {
              return { ...returnData, fipsCrypto: "False" }
            }
            else if (title === "RDP Enabled") {
              return { ...returnData, rdpStatus: "Enabled" }
            } else if (title === "CD/DVD Drive Enabled") {
              return { ...returnData, cdDvdStatus: "Enabled" }
            } else if (title === "BIOS Battery Unserviceable") {
              return { ...returnData, biosBatteryStatus: "Unserviceable" }
            } else if (title === "Bluetooth Enabled") {
              return { ...returnData, bluetoothStatus: "Enabled" }
            } else if (title === "Secure Boot Not Enabled") {
              return { ...returnData, secureBootStatus: "Not Enabled" }
            }
            return returnData;
          });
          setColumnData(calculateData);
        } else {
          setColumnData([]);
        }
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    fetchData(false);
  }, [user, state, pageNumber, pageSize]);

  useEffect(() => {
    fetchData(true);
  }, [user, state, searchTerm]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData?.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial No.", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        // { label: "Score", key: "clientScore" },
        { label: "Admin Accounts", key: "adminAccountsName" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" },
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SSID", key: "ssid" },
        { label: "Auth Type", key: "authenticationType" },
        { label: "OS Name", key: "osName" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "AV Name", key: "antivirusName" },
        { label: "Current Version", key: "antivirusVersion" },
        { label: "Services", key: "service" },
        { label: "Hardening Issues", key: "hardeningIssues" },
        { label: "Count", key: "highCpuUsageCount" },
        { label: "Count", key: "highRamUsageCount" },
        { label: "Count", key: "nonWhitelistedCount" },
        { label: "Count", key: "nonWhitelistedServiceCount" },
        { label: "FIPS Enabled", key: "fipsCrypto" },
        { label: "RDP Status", key: "rdpStatus" },
        { label: "CD/DVD Status", key: "cdDvdStatus" },
        { label: "Bios Battery Status", key: "biosBatteryStatus" },
        { label: "Bluetooth Status", key: "bluetoothStatus" },
        { label: "Secure Boot Status", key: "secureBootStatus" },
        { label: "EDR Name", key: "edrName" },
        { label: "ZenWorks Name", key: "zenWorksName" },
        { label: "Certificate Name", key: "certificateName" },
        { label: "Service Name", key: "firewallService" },
        { label: "Count", key: "OsNotHardenedmismatchCount" },
        { label: "Count", key: "mismatchCount" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Timestamp", key: "serverTimestamp" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name} | Path: ${directory.path} | Permissions:(Owner:${permissions.owner}|Group: ${permissions.group}|Other: ${permissions.other})`;
          })
          .join(" || ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses.split(" ").join(" | ");
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userName}/${user.userType}/${user.passwordAge}`)
          .join(" | ");
      };

      const formatAdminAccountsName = (adminAccountsName) => {
        if (!adminAccountsName || adminAccountsName.length === 0) return "N/A"; // Handle empty or undefined case

        return adminAccountsName
          .map((adminAccount) => adminAccount)
          .join(" | ");
      }

      const formatMultipleAvs = (avs) => {
        if (!avs || avs.length === 0) return "N/A";
        return avs
          .map((av) => av)
          .join(" | ");
      };

      const formatHardeningIssues = (hardeningIssues) => {
        if (hardeningIssues) {
          return Object.entries(hardeningIssues)
            .map(([key, value]) => {
              return `${key}: ${value}`;
            })
            .join(" | ");
        }
        return "No issues";
      }

      const csvData = [
        tableColumn,
        ...columnData.map((item, index) => [
          index + 1,
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              : header.key === "adminAccountsName"
                ? formatAdminAccountsName(item[header.key])
                : header.key === "updatedAt" || header.key === "serverTimestamp" ?
                  formatTimestamp(item[header.key])
                  : header.key === "usersPasswordAge"
                    ? formatUsersPasswordAge(item[header.key])
                    : header.key === "sharedDirectories"
                      ? formatSharedDirectories(item[header.key])
                      : header.key === "avDetails"
                        ? formatAvDetails(item[header.key])
                        : header.key === "service"
                          ? formatOsDetails(item[header.key])
                          : header.key === "notInstalledAvs" ?
                            formatMultipleAvs(item[header.key])
                            : header.key === "hardeningIssues"
                              ? formatHardeningIssues(item[header.key])
                              : header.key === "firewallService"
                                ? formatMultipleAvs(item[header.key]) || "NA"
                                : item[header.key]
          )
        ]),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        `${state?.titleState}`,
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => columnData?.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial No.", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        // { label: "Score", key: "clientScore" },
        { label: "Admin Accounts", key: "adminAccountsName" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" },
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SSID", key: "ssid" },
        { label: "Auth Type", key: "authenticationType" },
        { label: "OS Name", key: "osName" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "AV Name", key: "antivirusName" },
        { label: "Current Version", key: "antivirusVersion" },
        { label: "Services", key: "service" },
        { label: "Hardening Issues", key: "hardeningIssues" },
        { label: "Count", key: "highCpuUsageCount" },
        { label: "Count", key: "highRamUsageCount" },
        { label: "Count", key: "nonWhitelistedCount" },
        { label: "Count", key: "nonWhitelistedServiceCount" },
        { label: "FIPS Enabled", key: "fipsCrypto" },
        { label: "RDP Status", key: "rdpStatus" },
        { label: "CD/DVD Status", key: "cdDvdStatus" },
        { label: "Bios Battery Status", key: "biosBatteryStatus" },
        { label: "Bluetooth Status", key: "bluetoothStatus" },
        { label: "Secure Boot Status", key: "secureBootStatus" },
        { label: "EDR Name", key: "edrName" },
        { label: "ZenWorks Name", key: "zenWorksName" },
        { label: "Certificate Name", key: "certificateName" },
        { label: "Service Name", key: "firewallService" },
        { label: "Count", key: "OsNotHardenedmismatchCount" },
        { label: "Count", key: "mismatchCount" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Timestamp", key: "serverTimestamp" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["S. No.", ...headers.map((header) => header.label)];
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const avName = Object.keys(detail)[0] || "Unknown AV";
            const avStatus = detail[avName] || "Status not available";

            return `${avName}:${avStatus}`;
          })
          .join(", ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join(" | ");
      };

      const formatAdminAccountsName = (adminAccountsName) => {
        if (!adminAccountsName || adminAccountsName.length === 0) return "N/A";

        return adminAccountsName
          .map((adminAccount) => adminAccount)
          .join(" | ");
      }

      const formatMultipleAvs = (avs) => {
        if (!avs || avs.length === 0) return "N/A";
        return avs
          .map((av) => av)
          .join(" | ");
      };

      const formatHardeningIssues = (hardeningIssues) => {
        if (hardeningIssues) {
          return Object.entries(hardeningIssues)
            .map(([key, value]) => {
              return `${key}: ${value}`;
            })
            .join(", ");
        }
        return "No issues";
      }

      columnData.forEach((item, index) => {
        const rowData = [index + 1, ...headers.map((header) =>
          header.key === "updatedAt" || header.key === "serverTimestamp"
            ? formatTimestamp(item[header.key])
            : header.key === "adminAccountsName"
              ? formatAdminAccountsName(item[header.key])
              : header.key === "updatedAt" ?
                formatTimestamp(item[header.key]) :
                header.key === "sharedDirectories"
                  ? formatSharedDirectories(item[header.key])
                  : header.key === "usersPasswordAge"
                    ? item[header.key]
                      .map(
                        (user) =>
                          `${user.userName}/${user.userType}/${user.passwordAge}`
                      )
                      .join("; ")
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "firewallService"
                          ? formatMultipleAvs(item[header.key]) || "N/A"
                          : header.key === "hardeningIssues"
                            ? formatHardeningIssues(item[header.key])
                            : Array.isArray(item[header.key])
                              ? item[header.key].join(", ")
                              : item[header.key]
        )];
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for all cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
        // Conditional column width using columnStyles
        columnStyles: {
          [tableColumn.indexOf('Hardening Issues')]: {
            cellWidth: 100,
            halign: "left",
          },
        },
      });

      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  };

  useEffect(() => {
    if (!state?.callUrl) {
      // Fetch necessary data or set default values
    }
  }, [state?.callUrl]);

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <ol className="breadcrumb fs-sm mb-2 p-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">{`Dashboard`}</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-dash" ? (
              <span className="active">{`Linux Dashboard`}</span>
            ) : (
              <NavLink exact to="/linux-dash">
                Linux Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-severity" ? (
              <span className="active">{state?.titleState}</span>
            ) : (
              <NavLink exact to="/linux-severity">
                {state?.titleState}
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal ps-2">
            <Link
              to="/"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Dashboard</p>
          </span>
          {/* <div className="d-flex justify-content-center align-items-center">
            <Button className="btn btn-primary rounded-md d-flex align-items-center gap-2 back-button" onClick={() => navigate(-1)} >
              <i className="ri-arrow-left-line fs-18 lh-1 mr-2" style={{ verticalAlign: 'middle', marginRight: '3px' }}></i>
              <span>Back</span>
            </Button>
          </div> */}
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div className="col-xl">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0} Endpoints{" "}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="" style={{ position: "relative", width: "17rem" }}>
              <input
                style={{ paddingRight: "1.3rem" }}
                className="form-control"
                type="text"
                placeholder="Search by Hostname or Serial No."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
            </div>
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="card rounded mb-2 shadow cardStyle">
          {!loading ? (
            <CustomTable
              rowData={columnData}
              columnData={columnData}
              routeUrl={"/linux-client-Details"}
              currentPage={pageNumber}
              pageSize={pageSize}
              totalDocuments={totalDocuments}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              title={state?.titleState}
            />
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LinuxSeverityData;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import Footer from "../layouts/Footer";
import DashCard from "../components/common/DashBoard/DashCard";
import Select from "react-select";

import windowIcon from "../assets/img/latestIcons/microsoft-windows.svg";
import androidIcon from "../assets/img/latestIcons/android.svg";
import linuxIcon from "../assets/img/latestIcons/linux.svg";
import macIcon from "../assets/img/latestIcons/mac.png";
import chromeIcon from "../assets/img/latestIcons/chrome.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../components/common/Loader/PageLoader";

import axios from "axios";
import { jsPDF } from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
import "../scss/dashboard/_dashcard.scss";

export default function DashBoard() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const data = {
    d1: {
      sections: {
        s1: { subSections: ["sb1", "sb2", "sb3"] },
        s2: { subSections: ["sb12", "sb22", "sb32"] },
        s3: { subSections: ["sb13", "sb23", "sb33"] },
      },
    },
    d2: { sections: { s1: {}, s2: {}, s3: {} } },
    d3: { sections: { s1: {}, s2: {}, s3: {} } },
  };

  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [windowCount, setWindowCount] = useState(0);
  const [linuxCount, setLinuxCount] = useState(0);
  const [androidCount, setAndroidCount] = useState(0);
  const [macCount, setMacCount] = useState(0);
  const [chromeOsCount, setChromeOsCount] = useState(0);

  const [windowCountRisk, setwindowCountRisk] = useState(0);
  const [windowCountUnRisk, setwindowCountUnRisk] = useState(0);

  const [linuxCountRisk, setLinuxCountRisk] = useState(0);
  const [linuxCountUnRisk, setLinuxCountUnRisk] = useState(0);

  const [androidCountRisk, setAndroidCountRisk] = useState(0);
  const [androidCountUnRisk, setAndroidUnCountRisk] = useState(0);

  const [chromeCountRisk, setChromeCountRisk] = useState(0);
  const [chromeCountUnRisk, setChromeCountUnRisk] = useState(0);

  const [macCountRisk, setMacCountRisk] = useState(0);
  const [macCountUnRisk, setMacCountUnRisk] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [department, setDepartment] = useState(null);
  const [section, setSection] = useState(null);
  const [subSection, setSubSection] = useState(null);

  const [departments, setDepartments] = useState([]);

  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    state?.selectedDepartment || null
  );
  const [selectedSection, setSelectedSection] = useState(
    state?.selectedSection || null
  );
  const [selectedSubsection, setSelectedSubsection] = useState(
    state?.selectedSubsection || null
  );

  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);
  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);
  const [TotalDashboard, setTotalDashboard] = useState(0);

  const [currentData, setCurrentData] = useState(
    Object.keys(data).map((department) => ({ name: department, value: 1 }))
  );
  const [currentSections, setCurrentSections] = useState(null);
  const [currentSubSections, setCurrentSubSections] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState(null);

  const handleDepartmentClick = (department) => {
    setCurrentSections(
      Object.keys(data[department].sections).map((section) => ({
        name: section,
        value: 1,
      }))
    );
    setCurrentData(null);
    setCurrentDepartment(department);
  };

  const handleSectionClick = (section) => {
    const subSections = data[currentDepartment].sections[section].subSections;
    setCurrentSubSections(subSections.map((sub) => ({ name: sub, value: 1 })));
    setCurrentSections(null);
  };

  const handleBack = () => {
    if (currentSubSections) {
      setCurrentSections(
        Object.keys(data[currentDepartment].sections).map((section) => ({
          name: section,
          value: 1,
        }))
      );
      setCurrentSubSections(null);
    } else {
      setCurrentData(
        Object.keys(data).map((department) => ({ name: department, value: 1 }))
      );
      setCurrentSections(null);
      setCurrentDepartment(null);
    }
  };

  const [summoryCount, setSummoryCount] = useState({});
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  var windows_unsafe_per =
    (windowCountRisk / (windowCountRisk + windowCountUnRisk)) * 100;
  var linux_unsafe_per =
    (linuxCountRisk / (linuxCountRisk + linuxCountUnRisk)) * 100;
  var android_unsafe_per =
    (androidCountRisk / (androidCountRisk + androidCountUnRisk)) * 100;
  var chrome_unsafe_per =
    (chromeCountRisk / (chromeCountRisk + chromeCountUnRisk)) * 100;
  var macc_unsafe_per = (macCountRisk / (macCountRisk + macCountUnRisk)) * 100;

  const linux_count = Math.round(linux_unsafe_per);

  useEffect(() => {
    if (!isAuthenticated) {
      // User not authenticated, redirect to login page
      navigate("/login");
    } else {
      // Check token expiration time

      const tokenExpirationTimer = setTimeout(() => {
        // Token has expired, log the user out
        dispatch(logout()); // Dispatch logout action to clear user data and token
        navigate("/signin");
      }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);


  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
    fetchData();
  }, [user?.data?.user?.organizationId]);

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SUMMARY_URL}/graph-data/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        // Handle the response data here
        // setLineGraph(response.data.response.sevenDayData);
        setDeptSafeUnsafe(
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts
        );
        setSectionSafeUnsafe(
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts
        );
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  // Fetch Summary Data
  useEffect(() => {
    let isMounted = true; // Track mount status

    const fetchData = () => {
      let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

      // Check if department, section, or sub-section is selected and append to URL
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${selectedSection.value
          }`;
      }
      if (selectedSubsection) {
        url += `${selectedDepartment || selectedSection ? "&" : "?"
          }subSection=${selectedSubsection.value}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
        .then((res) => {
          if (isMounted) {
            // Update your state with the fetched data
            setChromeCountRisk(
              res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount
            );
            setMacCountRisk(res?.data?.mac?.UnsafemacClients);
            setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
            setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
            setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

            setChromeCountUnRisk(
              res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount
            );
            setMacCountUnRisk(res?.data?.mac?.SafemacClients);
            setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
            setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
            setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

            setTotalDashboard(res?.data?.totalDevicesCount);

            setWindowCount(res?.data?.windows?.getTotalPCCount);
            setLinuxCount(res?.data?.linux?.getTotalPCCount);
            setAndroidCount(res?.data?.android?.androidDevicesCount);
            setMacCount(res?.data?.mac?.macPcCount);
            setChromeOsCount(
              res?.data?.chromeOsSummaryInfo?.chromeDevicesCount
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching summary data:", error);
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            setTimeout(fetchData, 50000); // Schedule next fetch after 50 seconds
          }
        });
    };

    fetchData(); // Initial fetch

    return () => {
      isMounted = false; // Cleanup when unmounting
    };
  }, [user, selectedDepartment, selectedSection, selectedSubsection]);


  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const deviceIds = selectedRows?.map((row) => "row.pcId");

    // Construct the data object based on which fields are selected
    const data = { deviceIds };

    if (selectedDepartment) {
      data.departmentId = selectedDepartment.value;
    }
    if (selectedSection) {
      data.sectionId = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSectionId = selectedSubsection.value;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
    } catch (error) {
      toast.error(error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const orgName = user?.data?.user?.organization || "Organization Name";
  const userType = user?.data?.user?.roleName || "Admin Name";
  const exportDateTime = formatTimestamp(new Date());

  const exportToPdf = () => {
    const doc = new jsPDF();

    // Add organization and user info
    doc.setFontSize(12);
    doc.text("System Information Summary", 10, 10);
    doc.text(`Organization: ${orgName}`, 10, 20);
    doc.text(`Role: ${userType}`, 10, 30);
    doc.text(`Export Date: ${exportDateTime}`, 10, 40);

    // Filter Information
    doc.text("Filter Information:", 10, 60);
    doc.text(`Department: ${selectedDepartment?.label || "N/A"}`, 20, 70);
    doc.text(`Section: ${selectedSection?.label || "N/A"}`, 20, 80);
    doc.text(`Subsection: ${selectedSubsection?.label || "N/A"}`, 20, 90);

    // Total Devices Count
    // doc.text('Total Devices Count:', 10, 110);
    doc.text(`Total Devices Count: ${TotalDashboard}`, 10, 110);

    // Unsafe Devices Count
    doc.text("Unsafe Devices Count:", 10, 130);
    doc.text(`Chrome OS: ${chromeCountRisk}`, 20, 140);
    doc.text(`Mac OS: ${macCountRisk}`, 20, 150);
    doc.text(`Android: ${androidCountRisk}`, 20, 160);
    doc.text(`Linux: ${linuxCountRisk}`, 20, 170);
    doc.text(`Windows: ${windowCountRisk}`, 20, 180);

    // Safe Devices Count
    doc.text("Safe Devices Count:", 10, 200);
    doc.text(`Chrome OS: ${chromeCountUnRisk}`, 20, 210);
    doc.text(`Mac OS: ${macCountUnRisk}`, 20, 220);
    doc.text(`Android: ${androidCountRisk}`, 20, 230);
    doc.text(`Linux: ${linuxCountUnRisk}`, 20, 240);
    doc.text(`Windows: ${windowCountUnRisk}`, 20, 250);

    // Total Device Count Per OS
    doc.text("Total Device Count Per OS:", 10, 270);
    doc.text(`Chrome OS: ${chromeOsCount}`, 20, 280);
    doc.text(`Mac OS: ${macCount}`, 20, 290);
    doc.text(`Android: ${androidCount}`, 20, 300);
    doc.text(`Linux: ${linuxCount}`, 20, 310);
    doc.text(`Windows: ${windowCount}`, 20, 320);

    // Save the PDF
    doc.save("system_summary_filtered.pdf");
  };
  const exportToCsv = () => {
    // CSV content
    let csvContent = "System Information Summary\n";
    csvContent += `Organization:,${orgName}\n`;
    csvContent += `Role:,${userType}\n`;
    csvContent += `Export Date:,${exportDateTime}\n\n`;

    // Filter Information
    csvContent += "Filter Information\n";
    csvContent += `Department:,${selectedDepartment?.label || "N/A"}\n`;
    csvContent += `Section:,${selectedSection?.label || "N/A"}\n`;
    csvContent += `Subsection:,${selectedSubsection?.label || "N/A"}\n\n`;

    // Total Devices Count
    csvContent += `Total Devices Count:,${TotalDashboard}\n\n`;

    // Unsafe Devices Count
    csvContent += "Unsafe Devices Count\n";
    csvContent += `Chrome OS:,${chromeCountRisk}\n`;
    csvContent += `Mac OS:,${macCountRisk}\n`;
    csvContent += `Android:,${androidCountRisk}\n`;
    csvContent += `Linux:,${linuxCountRisk}\n`;
    csvContent += `Windows:,${windowCountRisk}\n\n`;

    // Safe Devices Count
    csvContent += "Safe Devices Count\n";
    csvContent += `Chrome OS:,${chromeCountUnRisk}\n`;
    csvContent += `Mac OS:,${macCountUnRisk}\n`;
    csvContent += `Android:,${androidCountUnRisk}\n`; // Corrected the duplicate
    csvContent += `Linux:,${linuxCountUnRisk}\n`;
    csvContent += `Windows:,${windowCountUnRisk}\n\n`;

    // Total Device Count Per OS
    csvContent += "Total Device Count Per OS\n";
    csvContent += `Chrome OS:,${chromeOsCount}\n`;
    csvContent += `Mac OS:,${macCount}\n`;
    csvContent += `Android:,${androidCount}\n`;
    csvContent += `Linux:,${linuxCount}\n`;
    csvContent += `Windows:,${windowCount}\n`;

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "system_summary_filtered.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2 p-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Dashboard
            </NavLink>
          </li>
        </ol>

        <div className="d-md-flex align-items-center justify-content-between mb-2">
          <div>
            <h4 className="main-title mb-0 fs-20 fw-bolder p-2 m-0 ">
              Welcome to Dashboard
            </h4>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between card-one filter-card shadow p-2 flex-row">
          <div className="row w-60">
            <div className="col-12 col-md-3">
              <div className="mb-3">
                <label htmlFor="departments" className="form-label text-dark">
                  Departments
                </label>
                <Select
                  classNamePrefix="custom-select"
                  options={departments.map((dept) => ({
                    label: dept.departmentName,
                    value: dept._id,
                  }))}
                  onChange={handleDepartmentChange}
                  value={selectedDepartment}
                />
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="mb-3">
                <label htmlFor="sections" className="form-label text-dark">
                  Sections
                </label>
                <Select
                  classNamePrefix="custom-select"
                  options={sections.map((section) => ({
                    label: section.sectionName,
                    value: section._id,
                  }))}
                  onChange={handleSectionChange}
                  isDisabled={!selectedDepartment}
                  value={selectedSection} // bind selected value
                />
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="mb-3">
                <label htmlFor="subsections" className="form-label text-dark">
                  Subsections
                </label>
                <Select
                  classNamePrefix="custom-select"
                  options={subsections.map((subSection) => ({
                    label: subSection.subSectionName,
                    value: subSection._id,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedSubsection(selectedOption)
                  }
                  isDisabled={!selectedSection}
                  value={selectedSubsection} // bind selected value
                />
              </div>
            </div>

            <div className="col-12 col-md-auto d-flex align-items-end">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginBottom: "17px" }}
                onClick={() => {
                  setSelectedDepartment(null);
                  setSelectedSection(null);
                  setSelectedSubsection(null);
                }}
              >
                Reset
              </button>
            </div>
          </div>

          <div
            className="d-flex gap-2 mt-3 mt-md-0 align-items-center"
            style={{ width: "171px" }}
          >
            <div className="d-flex align-items-center justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={exportToPdf}
              >
                <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
              </button>

              <button
                type="button"
                className="btn btn-success text-white"
                onClick={exportToCsv}
              >
                <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
              </button>
            </div>
          </div>
        </div>

        {loading ? <PageLoader /> :
          <Row className="g-3 m-0 p-0 mt-3">
            <Col xl="4">
              <DashCard
                dashIcon={windowIcon}
                dashNumber={windowCount}
                handleRoute={() => navigate("/windows-dash")}
                dashTitle={"Windows Endpoints"}
                riskNumber={windowCountRisk}
                riskPerc={windows_unsafe_per ? windows_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={linuxIcon}
                dashNumber={linuxCount}
                handleRoute={() => navigate("/linux-dash")}
                dashTitle={"Linux Endpoints"}
                riskNumber={linuxCountRisk}
                riskPerc={linux_count ? linux_count : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={macIcon}
                dashNumber={macCount}
                dashTitle={"Mac Endpoints"}
                handleRoute={() => navigate("/macOs-dash")}
                riskNumber={macCountRisk}
                riskPerc={macc_unsafe_per ? macc_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={chromeIcon}
                dashNumber={chromeOsCount}
                dashTitle={"ChromeOS Endpoints"}
                handleRoute={() => navigate("/chrome-dash")}
                riskNumber={chromeCountRisk}
                riskPerc={chrome_unsafe_per ? chrome_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={androidIcon}
                dashNumber={androidCount}
                handleRoute={() => navigate("/android-dash")}
                dashTitle={"Android Endpoints"}
                riskNumber={androidCountRisk}
                riskPerc={android_unsafe_per ? android_unsafe_per : "0"}
              />
            </Col>
          </Row>
        }

        <Footer />
      </div>
    </React.Fragment>
  );
}

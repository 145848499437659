import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToaster } from "../../feature/slices/toastSlice";
import { Button } from "react-bootstrap";
import "./common.css";

function Alerts({
  selectedDepartment,
  selectedSection,
  selectedSubsection,
  alertIcon,
  alertTitle,
  alertNumber,
  routeUrl,
  apiUrl,
  controlApi = true,
  description,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClickAlert = () => {
    if (controlApi) {
      navigate(routeUrl, {
        state: {
          selectedDepartment,
          selectedSection,
          selectedSubsection,
          titleState: alertTitle,
          alertNumber: alertNumber,
          callUrl: apiUrl,
        },
      });
    } else {
      dispatch(
        showToaster({
          title: "",
          message: "Not Avalable at this movement",
          color: "warning",
        })
      );
    }
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  let onClickHandler = null;
  if (alertNumber > 0) {
    onClickHandler = handleClickAlert;
  }

  const [showInfo, setShowInfo] = useState(false);
  let hoverTimeout = null;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setShowInfo(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowInfo(false);
  };

  return (
    <div
      className="p-3 rounded position-relative d-flex flex-column align-items-start gap-2 pe-autO p-3 alerthover"
      style={{ cursor: alertNumber > 0 ? 'pointer' : 'default' }}
      onClick={onClickHandler}
    >
      {/* Info Icon with Hover Effect */}
      <Button
        className="bg-transparent border-0 position-absolute opacity-25 p-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ top: "2%", right: "2%", display: 'none' }}
      >
        <i
          className="ri-information-2-line"
          style={{ pointerEvents: 'auto', fontSize: "16px" }}
        ></i>
      </Button>

      <img src={alertIcon} alt="AlertIcon" width={35} />

      {showInfo && (
        <>
          <div
            style={{
              position: "absolute",
              top: "10%",
              right: "calc(5% + 20px)",
              transform: "translateY(-50%)",
              background: "#565556",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              zIndex: "999",
            }}
          >
            <p className="p-1 m-0 rounded fs-12">{description}</p>
          </div>
          <i
            className="ri-arrow-right-s-fill"
            style={{ fontSize: "24px", color: "#565556", position: "absolute", top: "calc(10% - 15px)", right: "calc(3% + 12px)", zIndex: "999" }}
          ></i>
        </>
      )}

      <p className="p-0 m-0 fs-15 fw-bolder">{alertTitle}</p>
      <p className="p-0 m-0 text-danger fw-semibold">
        {alertNumber}{' '}
        {alertTitle.includes('Violations') ? 'Violations' : 'Endpoints'}
      </p>

    </div>
  );
}

export default Alerts;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { notifyApi } from "../../api/axiosSet";
import { Form } from 'react-bootstrap';
import Switch from "react-switch";
import PageLoader from "../../components/common/Loader/PageLoader";

const ExpandedRow = ({ exemptionData, setExemptionData, fetchExemptionData }) => {

  const [updatedEndDate, setUpdatedEndDate] = useState(null);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);

  const convertDateTime = ((mongoDate) => {
    const date = new Date(mongoDate);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(date.getTime() + istOffset);

    const month = String(istDate.getMonth() + 1).padStart(2, '0');
    const day = String(istDate.getDate()).padStart(2, '0');
    const year = istDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  });

  const { user } = useSelector((state) => state.authSlice);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const doc_id = selectedRowForEdit?._id;
    try {
      const postData = {
        exemptedBy: user?.data?.user?.email,
        exemptionEndDate: updatedEndDate,
      }

      const response = await notifyApi.post(
        `update-Exemption-to-pc/${doc_id}/${user?.data?.user?.userId}`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.status === 200 && response?.data?.status === true) {
        document.getElementById("closeModal").click();
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchExemptionData();
        // setExemptionData((prevData) => {
        //     const thisExemptionRowData = prevData.find((data) => data?.id === exemptionData?.id);

        //     if (!thisExemptionRowData) {
        //         console.error("No matching exemption data found");
        //         return prevData;
        //     }
        //     const thisSubrows = thisExemptionRowData?.subRows || [];

        //     const updatedSubrows = thisSubrows.map((subrow) => {
        //         if (subrow._id === doc_id) {
        //             return { ...subrow, exemptionEndDate: updatedEndDate };
        //         }
        //         return subrow;
        //     });

        //     return prevData.map((data) => {
        //         if (data?.id === exemptionData?.id) {
        //             return { ...data, subRows: updatedSubrows };
        //         }
        //         return data;
        //     });
        // });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
    }
  };


  const handleStatusChange = async (doc_id, currentStatus) => {

    const newStatus = !currentStatus;

    try {
      const postData = {
        exemptedBy: user?.data?.user?.email,
        exempted: newStatus,
      }

      const response = await notifyApi.post(
        `update-Exemption-to-pc/${doc_id}/${user?.data?.user?.userId}`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.status === 200 && response?.data?.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchExemptionData();
        // setExemptionData((prevData) => {
        //     const thisExemptionRowData = prevData.find((data) => data?.id === exemptionData?.id);

        //     if (!thisExemptionRowData) {
        //         console.error("No matching exemption data found");
        //         return prevData;
        //     }
        //     const thisSubrows = thisExemptionRowData?.subRows || [];

        //     const updatedSubrows = thisSubrows.map((subrow) => {
        //         if (subrow._id === doc_id) {
        //             return { ...subrow, exempted: newStatus };
        //         }
        //         return subrow;
        //     });

        //     return prevData.map((data) => {
        //         if (data?.id === exemptionData?.id) {
        //             return { ...data, subRows: updatedSubrows };
        //         }
        //         return data;
        //     });
        // });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
    }
  };

  const handleDeleteRow = async (doc_id) => {
    if (!window.confirm("Are you sure you want to delete this Exemption?")) {
      return;
    }
    try {
      const response = await notifyApi.post(
        `/delete-Exemption-to-pc/${doc_id}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      if (response?.status === 200 && response?.data?.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchExemptionData();
        // setExemptionData((prevData) => {
        //     const thisExemptionRowData = prevData.find((data) => data?.id === exemptionData?.id);

        //     if (!thisExemptionRowData) {
        //         console.error("No matching exemption data found");
        //         return prevData;
        //     }
        //     const thisSubrows = thisExemptionRowData?.subRows || [];

        //     const updatedSubrows = thisSubrows.filter((subrow) => (subrow._id !== doc_id));
        //     if (updatedSubrows?.length === 0) {
        //         return prevData.filter((data) => data?.id !== exemptionData?.id);
        //     }
        //     return prevData.map((data) => {
        //         if (data?.id === exemptionData?.id) {
        //             return { ...data, subRows: updatedSubrows };
        //         }
        //         return data;
        //     });
        // });
      } else {
        toast.error("Failed to delete data");
      }
    } catch (error) {
      toast.error("Error deleting data");
    }
  };

  useEffect(() => {
    const modal = document.getElementById('exampleModal');

    const handleModalClose = () => {
      setUpdatedEndDate('');
    };

    modal.addEventListener('hidden.bs.modal', handleModalClose);
    return () => {
      modal.removeEventListener('hidden.bs.modal', handleModalClose);
    };
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const columns = [
    {
      name: "Hostname",
      selector: (row) => row.hostname,
      sortable: true,
    },
    {
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      sortable: true,
    },
    {
      name: "Exemption Start Date",
      selector: (row) => convertDateTime(row.exemptionStartDate),
      sortable: true,
    },
    {
      name: "Exemption End Date",
      selector: (row) => convertDateTime(row.exemptionEndDate),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Switch
          onChange={() =>
            handleStatusChange(
              row._id,
              row.exempted === "true" || row.exempted === true
            )
          }
          checked={row.exempted === "true" || row.exempted === true}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00e676"
          offColor="#ff1744"
          height={18}
          width={35}
          handleDiameter={12}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Edit/Delete",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-outline-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setSelectedRowForEdit(row)}
          >
            <i className="ri-edit-line"></i>
          </button>
          <button
            className="btn btn-outline-danger bg-btn-danger"
            onClick={() => handleDeleteRow(row._id)}
          >
            <i className="ri-delete-bin-7-fill"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {exemptionData && exemptionData?.subRows?.length > 0 ? (
        <DataTable
          columns={columns}
          data={exemptionData.subRows}
        />
      ) : (
        <div className="text-center no-data-found mt-3">
          No data found
        </div>
      )}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Update Exemption End Date
              </h1>
              <button
                type="button"
                id="closeModal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEditSubmit}>
                <div className="mb-3">
                  <label htmlFor="nameInput" className="form-label">
                    New Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="nameInput"
                    value={updatedEndDate || ''}
                    min={
                      selectedRowForEdit?.exemptionStartDate
                        ? formatDate(selectedRowForEdit.exemptionStartDate)
                        : formatDate(new Date())
                    }
                    onChange={(e) => setUpdatedEndDate(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


function ExemptionConfig() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });

  const { user } = useSelector((state) => state.authSlice);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  const [searchTerm, setSearchTerm] = useState('');

  const [exemptionData, setExemptionData] = useState([]);
  const [exemptionNames, setExemptionNames] = useState([]);

  const [listOfExemptionPcWise, setListOfExemptionPcWise] = useState([]);

  const fetchExemptionData = async () => {
    try {
      const response = await notifyApi.get(
        `/all-pc-Exemption/list/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const data = response?.data?.data;

      const newObjectData = {};
      data.forEach((element) => {
        if (newObjectData[element?.violationId]) {
          newObjectData[element?.violationId].subRows = [
            ...newObjectData[element?.violationId].subRows,
            element
          ];
        } else {
          const newExemption = {
            id: element?.violationId,
            mainCol: element?.exemptionName,
            subRows: [element],
          };
          newObjectData[element?.violationId] = newExemption;
        }
      });

      const newExceptionDataTable = Object.values(newObjectData)
        .sort((a, b) => {
          if (a.mainCol > b.mainCol) {
            return 1;
          }
          if (a.mainCol < b.mainCol) {
            return -1;
          }
          return 0;
        });

      setExemptionData(newExceptionDataTable);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  const fetchHostData = async () => {
    try {
      const response = await notifyApi.get(
        `/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?page=${pageNumber}&limit=${pageSize}&identifier=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response.data.totalDevices);
      const devices = response.data.devices;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      setTotalDocuments(0);
      setColumnData([]);
      setLoading(false);
    }
  };

  const fetchExemptionIds = async () => {
    try {
      const response = await notifyApi.get(
        `/get-exemption/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const sortedNames = response?.data?.exemptions?.sort((a, b) => {
        if (a?.exemptionName < b?.exemptionName) return -1;
        if (a?.exemptionName > b?.exemptionName) return 1;
        return 0;
      });
      setExemptionNames(sortedNames);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchExemptionData();
    fetchExemptionIds();
  }, [user]);

  useEffect(() => {
    if (!searchTerm) return;
    fetchHostData();
  }, [user, pageNumber, pageSize, searchTerm]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    visibleColumns.hostname && {
      name: "Hostname",
      selector: (row) => row?.hostname,
      cell: (row) => (
        <Tippy maxWidth="none" content={row.hostname}>
          <div>{row.hostname}</div>
        </Tippy>
      ),
      sortable: true,
    },
    visibleColumns.serialNumber && {
      name: "Serial Number",
      selector: (row) => row.serialNumber,
    },
    visibleColumns.ipAddress && {
      name: "IP Address",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.ipAddress.split(" ").join(", ") || "NA"}>
          <div>{row.ipAddress.split(" ").join(", ") || "NA"}</div>
        </Tippy>
      ),
    },
    visibleColumns.updatedAt && {
      name: "Sync Time",
      selector: (row) => formatTimestamp(row.updatedAt) || "NA",
      sortable: true,
    },
    visibleColumns.departmentName && {
      name: "Associated With",
      selector: (row) =>
      (
        <Tippy maxWidth="none" content={row.department || "NA"}>
          <div>{row.department || "NA"}</div>
        </Tippy>
      ),
      width: '280px'
    },
    {
      name: "",
      cell: (row) => (selectedRows.length > 1 ? null : <div role="button" onClick={() => handleActionClick(row)} className="d-flex align-items-center rounded p-2 bg-primary">
        <i className="ri-add-line text-white small"></i>
        <span className="text-white ms-2">Add Exemption</span>
      </div>

      ),
      sortable: false,
    },
  ];
  const exportToPDF = (data) => {
    const doc = new jsPDF();
    doc.text("Exported Data", 14, 16);

    const tableColumn = [
      "Hostname",
      "Serial Number",
      "IP Address",
      "Sync Time",
      "Associated With",
    ];
    const tableRows = data.map((item) => [
      item.hostname,
      item.serialNumber,
      item.ipAddress || "NA",
      formatTimestamp(item.updatedAt) || "NA",
      item.department || "NA",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("data.pdf");
  };

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        Hostname: item.hostname,
        SerialNumber: item.serialNumber,
        IPAddress: item.ipAddress || "NA",
        ClientScore: item.auditScore || "NA",
        SyncTime: formatTimestamp(item.updatedAt) || "NA",
        AssociatedWith: item.department || "NA",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };

  const getPcwiseExemptionDetails = async (pcId) => {
    try {
      const response = await notifyApi.get(`/get-exemption/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}/${pcId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      if (response.data?.exemptions) {
        setListOfExemptionPcWise(response.data?.exemptions);
      }
    }
    catch { }
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleActionClick = (row) => {
    getPcwiseExemptionDetails(row?.pcId);
    setShowOffcanvas(true);
    setSelectedRows([row]);
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    // setFilteredPCs(allPCs.filter(pc => pc.hostname.toLowerCase().includes(term)));
  };

  const columnsExemption = [
    {
      name: 'Category',
      selector: (row) => `${row.mainCol} (${row?.subRows?.length})`,
      sortable: true,
    },
  ];
  const [selectedExemptions, setSelectedExemptions] = useState([]);
  const [selectAllExemptions, setSelectAllExemptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    const { id, value, checked } = e.target;

    setSelectedExemptions(prev =>
      checked ? [...prev, { id: id, value: value }] : prev.filter(item => item.id !== id)
    );
  };

  const [expiryStartDate, setExpiryStartDate] = useState('');
  const [expiryEndDate, setExpiryEndDate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (exemptionNames?.length === listOfExemptionPcWise?.length) {
      setErrorMessage('All Exemptions already added');
      return;
    }
    if (selectedExemptions?.length === 0) {
      setErrorMessage('No Exemptions selected');
      return;
    }
    try {
      setToggleClearRows(false);
      const dataToSend = {
        organizationId: user?.data?.user?.organizationId,
        pcIds: selectedRows.map((row) => row.pcId) || [],
        exemptedBy: user?.data?.user?.email,
        exemptionStartDate: expiryStartDate || new Date().toISOString().split('T')[0],
        exemptionEndDate: expiryEndDate,
        violations: selectedExemptions.map((item) => item.id) || [],
      }

      const response = await notifyApi.post(
        `/add-Exemption-to-pc/${user?.data?.user?.userId}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      if (response.status === 200 || response.status === 201) {
        setErrorMessage('');
        toast.success("Exemption added successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      setListOfExemptionPcWise([]);
      setSelectAllExemptions(false);
      setSelectedRows([]);
      setToggleClearRows(true);
      setSelectedExemptions([]);
      fetchHostData();
      fetchExemptionData();
      setShowOffcanvas(false);
      setExpiryStartDate('');
      setExpiryEndDate('');
    }
    catch (error) {
      if (error?.response?.status === 409) {
        setShowOffcanvas(false);
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }

  };

  const hideOffcanvas = () => {
    setShowOffcanvas(false);
    setToggleClearRows(false)
    setTimeout(() => {
      setErrorMessage('');
      setListOfExemptionPcWise([]);
      setSelectedRows([]);
      setSelectAllExemptions(false);
      setSelectedExemptions([]);
      setExpiryStartDate('');
      setExpiryEndDate('');
      setToggleClearRows(true);
    }, 200);
  }

  const handleSelectAllChange = () => {
    if (!selectAllExemptions) {
      const allExemptions = exemptionNames
        .filter(option =>
          !listOfExemptionPcWise.some(
            row => row?.exemptionName === option?.exemptionName && row?.exemptionId === option?._id
          )
        )
        .map(option => ({
          id: option._id,
          value: option.exemptionName
        }));

      setSelectedExemptions(allExemptions);
      setSelectAllExemptions(true);
    } else {
      setSelectedExemptions([]);
      setSelectAllExemptions(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">
              Exemption Configuration
            </p>
          </span>
        </div>
        <Form.Group className="mb-3 position-relative">
          <Form.Control
            type="text"
            placeholder="Search by Hostname"
            value={searchTerm}
            onChange={handleSearch}
          />
          <i className="ri-search-line position-absolute p-1 end-0 top-50 translate-middle"></i>
        </Form.Group>
        {
          searchTerm &&
          <div className="card rounded mb-2 shadow cardStyle mb-4 position-relative">
            {loading ? (
              <PageLoader />
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={columnData || []}
                  pagination
                  paginationServer
                  selectableRows
                  selectableRowsHighlight
                  onSelectedRowsChange={handleSelectedRowsChange}
                  clearSelectedRows={toggleClearRows}
                  paginationTotalRows={totalDocuments}
                  paginationDefaultPage={pageNumber}
                  paginationPerPage={pageSize}
                  onChangePage={(page) => {
                    handlePageChange(page);
                  }}
                  onChangeRowsPerPage={(newPageSize) => {
                    handlePageSizeChange(newPageSize);
                  }}
                  noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                />
                {selectedRows.length > 1 && <div role="button" onClick={handleControlClick} className="d-flex align-items-center rounded m-2 p-1 px-2 bg-primary position-absolute end-0 top-0">
                  <i className="ri-add-line text-white small"></i>
                  <span className="text-white ms-2">Add Exemption</span>
                </div>}
              </>

            )}
          </div>

        }
        <div className="card rounded mb-2 shadow cardStyle shadow-lg p-2 mt-4 bg-light">
          <h4 className="p-2 card-title text-primary">Exemption List</h4>
          {loading ? (
            <PageLoader />
          ) : (
            <DataTable
              columns={columnsExemption || []}
              data={exemptionData || []}
              expandableRows
              expandableRowsComponent={({ data }) => <ExpandedRow exemptionData={data || []} setExemptionData={setExemptionData}
                fetchExemptionData={fetchExemptionData} />}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          )}
        </div>

        <Offcanvas
          show={showOffcanvas}
          onHide={hideOffcanvas}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="d-flex align-items-center">
              Selected Devices:
              <span className="badge rounded-pill text-bg-secondary text-white ms-2">
                {selectedRows?.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row, index) => (
                <li key={index}>{row.hostname}</li>
              ))}
            </ul>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Exemptions</label>
                {exemptionNames?.length !== listOfExemptionPcWise?.length && <div className="form-check">
                  <label htmlFor="exemptions" className="form-label"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleSelectAllChange}
                  >{selectAllExemptions ? 'Deselect All' : 'Select All'}</label>
                </div>}
                {exemptionNames.map((option, index) => (
                  <div key={index} className="form-check">
                    <input
                      type="checkbox"
                      id={option._id}
                      value={option.exemptionName || ''}
                      name={option.exemptionName}
                      checked={selectedExemptions.some(
                        item => item.value === option.exemptionName && item.id === option._id
                      ) || listOfExemptionPcWise.some(item => item.exemptionName === option.exemptionName && item.exemptionId === option._id)}
                      onChange={(e) => handleCheckboxChange(e)}
                      disabled={listOfExemptionPcWise.some(item => item.exemptionName === option.exemptionName && item.exemptionId === option._id)}
                      className="form-check-input"
                    />
                    <label htmlFor={option._id} className="form-check-label">
                      {option.exemptionName}
                    </label>
                  </div>
                ))}
              </div>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <div className="mb-3">
                <label htmlFor="expiryStartDate" className="form-label"> Exemption Start Date</label>
                <input
                  type="date"
                  id="expiryStartDate"
                  value={expiryStartDate || ''}
                  onChange={(e) => setExpiryStartDate(e.target.value)}
                  className="form-control"
                  min={new Date().toISOString().split('T')[0]}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="expiryDate" className="form-label"> Exemption End Date</label>
                <input
                  type="date"
                  id="expiryDate"
                  value={expiryEndDate || ''}
                  onChange={(e) => setExpiryEndDate(e.target.value)}
                  className="form-control"
                  min={expiryStartDate || new Date().toISOString().split('T')[0]}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </Offcanvas.Body>

        </Offcanvas>
      </div>
    </React.Fragment>
  );
}

export default ExemptionConfig;

import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageLoader from "../components/common/Loader/PageLoader";
import { notifyApi } from "../api/axiosSet";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
import "../layouts/AllNotify.scss";

export default function AllNotification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [search, setSearch] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterEventTriggerType, setFilterEventTriggerType] = useState("");

  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);
  const notificationsContainerRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      // navigate("/login");
    } else {
      const tokenExpirationTimer = setTimeout(() => {
        dispatch(logout());
        // navigate("/signin");
      }, 24 * 60 * 60 * 1000);

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const updateNotification = () => {
      if (pageNo > totalPages) {
        return;
      }
      notifyApi
        .get(
          `/get-all-notifications/by/${user?.data?.user?.userId}?page=${pageNo}&limit=30`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setTotalNotificationCount(response?.data?.total);
          if (pageNo === 1) {
            setNotificationData(response?.data?.notifications);
            setFilteredNotifications(response?.data?.notifications);
          }
          else {
            setNotificationData((prevData) => [...prevData, ...response?.data?.notifications]);
            setFilteredNotifications((prevData) => [...prevData, ...response?.data?.notifications]);
          }
          setTotalPages(response?.data?.totalPages);
        })
        .catch(() => {
          setLoading(false);
        });
    };
    updateNotification();
  }, [user, pageNo]);

  useEffect(() => {
    const filtered = notificationData?.filter((notification) => {
      const timeStamp = notification?.timeStamp;
      if (!timeStamp) return false;

      let notificationDate;

      try {
        notificationDate = new Date(timeStamp.replace(", ", " ")).toISOString();
      } catch (error) {
        notificationDate = timeStamp.replace(", ", " ");
      }

      const formattedDate =
        typeof notificationDate === "string" && notificationDate.includes("T")
          ? notificationDate.split("T")[0]
          : timeStamp.replace(", ", " ");

      return (
        (filterDate ? formattedDate === filterDate : true) &&
        (filterEventTriggerType
          ? notification.eventTriggerType?.toLowerCase().includes(filterEventTriggerType.toLowerCase())
          : true) &&
        (search
          ? notification.hostname?.toLowerCase().includes(search.toLowerCase()) ||
          notification.eventTriggerType?.toLowerCase().includes(search.toLowerCase()) ||
          notification.notificationType?.toLowerCase().includes(search.toLowerCase())
          : true)
      );
    });

    setFilteredNotifications(filtered);
  }, [filterDate, filterEventTriggerType, search, notificationData]);


  const handleScroll = () => {
    const container = notificationsContainerRef.current;
    if (!container) return;

    const bottom =
      container.scrollHeight - container.scrollTop === container.clientHeight;

    if (bottom || container.scrollHeight - container.scrollTop <= container.clientHeight + 50) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = notificationsContainerRef.current;
    if (container) {
      // Throttle the scroll event
      const throttledScrollHandler = () => {
        clearTimeout(container.scrollTimeout);
        container.scrollTimeout = setTimeout(handleScroll, 100);
      };

      container.addEventListener('scroll', throttledScrollHandler);
    }
    return () => {
      if (container) {
        clearTimeout(container.scrollTimeout);
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [totalPages]);

  return (
    <React.Fragment>
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
            <div className="col-xl">
              <p className="fs-18 fw-bolder p-0 m-0 text-dark">All Notifications</p>
              <p className="text-danger p-0 m-0 fw-semibold">
                Total Notifications: {totalNotificationCount}
              </p>
            </div>
            <div className="d-flex align-items-center gap-2">
              {/* Search Input */}
              <div style={{ flex: "1" }}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search notifications"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              {/* Filter Dropdown */}
              <div style={{ flex: "1" }}>
                <div className="form-group">
                  <select
                    className="form-control"
                    value={filterEventTriggerType}
                    onChange={(e) => setFilterEventTriggerType(e.target.value)}
                  >
                    <option value="">Filter by Event Trigger Type</option>
                    <option value="INSTALLATION_TRIGGER">INSTALLATION_TRIGGER</option>
                    <option value="USB_TRIGGER">USB_TRIGGER</option>
                    <option value="BLUETOOTH_TRIGGER">BLUETOOTH_TRIGGER</option>
                    <option value="FIREWALL_TRIGGER">FIREWALL_TRIGGER</option>
                    <option value="NETWORK_TRIGGER">NETWORK_TRIGGER</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>
            </div>

          </div>

          <Row className="g-3">
            <div id="notification-list" ref={notificationsContainerRef} style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}>
              {filteredNotifications && filteredNotifications.length === 0 ?
                <div className="col-12"><h4 className="text-center">No Notifications Found</h4></div>
                : filteredNotifications.map((item, i) => (
                  <div className="w-100" key={i}>
                    <div className="notification-card shadow-lg border-0 p-3 rounded">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0 fw-bold text-primary">
                          {item?.hostname || "No Hostname"}
                        </h4>
                        <span className="text-muted small timestamp">
                          {item?.timeStamp?.replace(", ", " ")}
                        </span>
                      </div>
                      <p className="card-text mb-0 text-dark fw-semibold">
                        {item?.eventTriggerType || "No Event Trigger"}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted small notification-type">
                          {item?.notificationType || "No Notification Type"}
                        </span>
                        <button style={{ color: "white" }} className="btn btn-sm btn-primary view-details-btn" onClick={() => {
                          navigate("/linux-client-Details", {
                            state: {
                              pcInfoId: item?.pcId,
                              timeTrigger: item?.timeStamp,
                              legacyId: item?.legacyId,
                            },
                          });
                        }}>
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              {loading && <p>Loading more notifications...</p>}
            </div>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

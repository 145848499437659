import React from "react";
import AllEndPoint from "../pages/Useradmin/AllEndPoint";
import WhitelistService from "../pages/Useradmin/WhitePages/WhitelistServices.jsx";
import WhitelistPorts from "../pages/Useradmin/WhitePages/WhitelistPorts";
import WhitelistSubnets from "../pages/Useradmin/WhitePages/WhitelistSubnets.jsx";
import WhitelistApplication from "../pages/Useradmin/WhitePages/WhitelistApplication";
import PatchManagement from "../pages/Useradmin/PatchManagement.jsx";


const OrganizationAdmin = [
  { path: "/all-endpoint", element: <AllEndPoint /> },
  { path: "/whitelist-ports", element: <WhitelistPorts /> },
  { path: "/whitelist-subnet", element: <WhitelistSubnets /> },
  { path: "/whitelist-application", element: <WhitelistApplication /> },
  { path: "/whitelist-service", element: <WhitelistService /> },
  { path: "/patch-management", element: <PatchManagement /> },
]

export default OrganizationAdmin;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function HistoryCard({
  headerName,
  infoIcon = true,
  children,
  description,
  needSearchBar = false,
  searchTerm = "",
  setSearchTerm = () => { },
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const [showInfo, setShowInfo] = useState(false);
  let hoverTimeout = null;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setShowInfo(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowInfo(false);
  };

  return (
    <div
      className="p-1 history-card shadow rounded-md position-relative h-100"
    >
      <div className="d-flex border-bottom border-1 px-3 py-2 align-items-center justify-content-between">
        <span className="m-0 p-0 fs-16 text-primary-dark history-card-header fw-bolder">
          {headerName}
        </span>

        {needSearchBar && (
          <div className="" style={{ position: "relative", width: "17rem" }}>
            <input
              style={{ paddingRight: "1.3rem", fontSize: "12px" }}
              className="form-control p-1"
              type="text"
              placeholder={headerName === "Installed Applications" ? "Search by Application Name" : "Search by Service Name"}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
          </div>
        )}
        <span className="d-flex align-items-center justify-content-center gap-3">
          {infoIcon && <Button
            className="bg-transparent border-0 position-absolute opacity-1 p-0"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ top: headerName === "Security Configurations Hardening Status" ? "10px" : "2%", right: "2%", opacity: "0.6" }}
          >
            <i
              className="ri-information-2-line"
              style={{ pointerEvents: 'auto', fontSize: "16px" }}
            ></i>
          </Button>}
          {showInfo && description && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: (headerName === "Security Configurations Hardening Status" ||
                    headerName === "File Integrity Info"
                  ) ? "25px" : "7%",
                  right: (headerName === "Security Configurations Hardening Status" ||
                    headerName === "File Integrity Info"
                  ) ? "60px" : "45px",
                  transform: "translateY(-50%)",
                  background: "#565556",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  zIndex: "1000",
                  wordWrap: "break-word",
                }}
              >
                <p className="p-1 m-0 rounded fs-12">{description}</p>
              </div>

              <i
                className="ri-arrow-right-s-fill"
                style={{
                  fontSize: "24px",
                  color: "#565556",
                  position: "absolute",
                  top: (headerName === "Security Configurations Hardening Status" ||
                    headerName === "File Integrity Info"
                  ) ? "20px" : "5%",
                  right: (headerName === "Security Configurations Hardening Status" ||
                    headerName === "File Integrity Info"
                  ) ? "45px" : "30px",
                  zIndex: "1000",
                  transform: "translateY(-50%)",
                }}
              ></i>
            </>
          )}
        </span>
      </div>
      <div className="mx-3 rounded">{children}</div>
    </div>
  );
}

export default HistoryCard;

import { summaryApi } from "../../api/axiosSet";

export const fetchLinuxSummary = async (url, token) => {
  try {
    const response = await summaryApi.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const jsonData = response.data;

    const linuxSummary = {
      totalClients: jsonData?.linux?.getTotalPCCount || 0,
      totalSafeClients: jsonData?.linux?.SafelinuxClients || 0,
      totalUnsafeClients: jsonData?.linux?.UnsafelinuxClients || 0,
      totalUnresponsivelients: jsonData?.linux?.getUnresponsiveLinuxCount || 0,
    };

    const linuxAlertSummary = {
      avNotInstalled: jsonData?.linux?.getAntivirusNotInstalledCount || 0,
      avMalfunction: jsonData?.linux?.getAntivirusMalfunctionCount || 0,
      avNotUpdated: jsonData?.linux?.AntivirusNotUpdatedCount || 0,
      edrNotInstalled: jsonData?.linux?.getEDRNotInstalledCount || 0,
      edrMalfunction: jsonData?.linux?.getEDRMalfunctionCount || 0,
      osNotPatched: jsonData?.linux?.getNotPatchedOSCount || 0,
      napnacDisabled: jsonData?.linux?.getNacDisabledPCCount || 0,
      windowsNotActivated: jsonData?.linux?.getInactiveLinuxCount || 0,
      malawareDetected: jsonData?.linux?.MalwareDetectionCount || 0,
      firwallOff: jsonData?.linux?.firewallOffPCCount || 0,
      usbViolation: jsonData?.linux?.getPCsCountWithUnwhitelistedUSB || 0,
      blacklistedSoftware: jsonData?.linux?.blacklistedProgramsCount || 0,
      unknownServices: jsonData?.linux?.getunknownServicesCount || 0,
      oldAdminPasswords: jsonData?.linux?.adminPasswordAgeGreaterThanThresholdCount || 0,
      mulitpleAdmins: jsonData?.linux?.multipleAdminCount || 0,
      sharedFolder: jsonData?.linux?.getSharedDirectoriesDetectedCount || 0,
      outOfDomain: jsonData?.linux?.getOutOfDomainLinuxCount || 0,
      rdpEnabled: jsonData?.linux?.getRdpEnableCount || 0,
      cdDriveEnabled: jsonData?.linux?.getCdDriveEnabledCount || 0,
      biosBatteryHealth: jsonData?.linux?.getBiosBatteryServiceablePcCount || 0,
      scannerInstalled: jsonData?.linux?.getScannerInstalledCount || 0,
      highRamUsage: jsonData?.linux?.getHighRamUsagePcCount || 0,
      highCpuUsage: jsonData?.linux?.getHighCpuUsagePcCount || 0,
      wifiPcCount: jsonData?.linux?.wifiConnectedPCCount || 0,
      blueToothCount: jsonData?.linux?.getBluetoothEnabledCount || 0,
      openNetworkConnection: jsonData?.linux?.getOpenNetworkConnectionPCCount || 0,
      unwantedPorts: jsonData?.linux?.getPCsCountWithUnwantedPorts || 0,
      networkViolation: jsonData?.linux?.getNetworkViolationPCCount || 0,
      communicationViolation: jsonData?.linux?.getNetworkCommunicationViolationCount || 0,
      tpmNotEnabled: jsonData?.linux?.tpmNotEnableedCount || 0,
      fipsNotEnabled: jsonData?.linux?.isFipsNotEnableedCount || 0,
      additionalProcessHardeningInfoCount: jsonData?.linux?.cisAdditionalProcessHardeningInfoCount || 0,
      zenProgramNotInstallPCCount: jsonData?.linux?.getZenProgramNotInstallPCCount || 0,
      certificateClientProgramNotInstallPCCount: jsonData?.linux?.getCertificateClientProgramNotInstallPCCount || 0,
      isOsNotHardenedCountSet: jsonData?.linux?.isOsNotHardenedCount || 0,
      isSecureBootNotEnableedCount: jsonData?.linux?.isSecureBootNotEnableedCount || 0,
      fileIntegrityCheckFailedCount: jsonData?.linux?.fileIntegrityCheckFailedCount || 0,
    };

    const totalDashboard = jsonData?.totalDevicesCount || 0;

    return { linuxSummary, linuxAlertSummary, totalDashboard };
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

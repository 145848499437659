import windowIcon from "../../assets/img/latestIcons/microsoft-windows.svg";
import androidIcon from "../../assets/img/latestIcons/android.svg";
import linuxIcon from "../../assets/img/latestIcons/linux.svg";
import macIcon from "../../assets/img/latestIcons/mac.png";
import chromeIcon from "../../assets/img/latestIcons/chrome.svg";

export const osWiseIcons = {
    windows: windowIcon,
    android: androidIcon,
    linux: linuxIcon,
    mac: macIcon,
    chrome: chromeIcon,
};
export const WINDOW_ALL_CLIENT = "pcinfo/windows-pc-list";

export const SAFE_WINDOW_CLIENT = "pcinfo/safe-windows-clients";

export const UNSAFE_WINDOW_CLIENTS = "pcinfo/unsafe-windows-clients";

export const UNRESPONSIVE_WINDOWS = "pcinfo/all-unresponsive-windows";

export const EDR_NOT_INSTALLED_ENDPOINT = "osinfo/edr-not-Installed";

export const EDR_MALFUNCTION_ENDPOINT = "osinfo/edr-malfunction";

export const FIREWALL_OFF = "networkinfo/firewall-off";

export const NETWORK_INFO_DATA = "networkinfo/is-network-violation";

export const UNWANTED_PORT = "networkinfo/is-unwanted-ports-open";

export const UNKNOWN_NETWORK_COMMUNICATION = "networkinfo/network-communication-violation";

export const TPM_DISABLED = "hardwareinfo/is-tpm-disabled";

export const NAC_NAP = "networkinfo/is-nac-disabled";

export const WINDOW_NOT_ACTIVATED = "pcinfo/all-not-activated-windows";

export const MULTIPLE_PASSWORD = "accountinfo/is-multiple-admin";

export const OLD_PASSWORD = "accountinfo/is-admin-pwd-greater-than-threshold";

export const SHARED_FOLDER = "osinfo/is-shared-directories";

export const WIFI_ENABLED = "networkinfo/is-wifi-enabled";

export const BLUETOOTH_ENABLED = "networkinfo/is-bluetooth-enabled";

export const OUT_OF_DOMAIN = "pcinfo/get-out-of-domain-windows";

export const RDP_ENABLED = "osinfo/is-rdp-enabled";

export const CD_DRIVE_ENABLED = "hardwareInfo/is-cd-drive-enabled";

export const BIOS_BATTERY = "hardwareInfo/bios-battery-serviceable";

export const SCANNER_INSTALLED = "hardwareInfo/is-scanner-Installed";

export const IS_HIGH_RAM_USAGE = "hardwareInfo/is-high-ram-usage";

export const IS_HIGH_CPU_USAGE = "hardwareInfo/is-high-cpu-usage";

export const OPEN_NETWORK_CONNECTION = "networkinfo/is-open-network-connection";

export const ANTIVIRUS_NOT_INSTALLED_COUNT_ENDPOINT = "osinfo/antivirus-not-installed-count";

export const ANTIVIRUS_NOT_INSTALLED_ENDPOINT = "osinfo/antivirus-not-installed";

export const ANTI_VIRUS_MALFUNCTION_ENDPOINT = "osinfo/anti-virus-malfunction";

export const MALWARE_DETECTION_ENDPOINT = "osinfo/malware-detection";

export const ANTI_VIRUS_NOT_UPDATED_ENDPOINT = "osinfo/anti-virus-not-updated";

export const OS_PATCHED = "backendinfo/os-not-patched";

export const NAC_DISABLED = "networkinfo/is-nac-disabled";

export const BLACKLIST_PROGRAME = "backendinfo/all-blacklisted-programs";

export const USB_VOILATION = "usbhistoryinfo/get-pc-with-unwhitelisted-usb";

export const OS_INFO_DATA = "collection/os-info-data";

export const UNKNOWN_SERVICES = "backendinfo/all-unknown-services";

export const IS_FIPS_DISABLE = "cisinfo/is-fips-disabled";

export const IS_SECURE_BOOT_DISABLED = "cisinfo/is-secure-boot-disabled";

export const OS_NOT_HARDENED = "cisinfo/is-os-not-hardened";

export const FILE_INTEGRITY_FAILED = "backendinfo/file-integrity-check-failed/pc-list";

export const IS_PROCESS_HARDENING_INFO = "cisinfo/process-hardening-info";

export const ZEN_NOT_INSTALLED = "backendinfo/zen-not-installed-list";

export const CERTIFICATE_CLIENT_NOT_INSTALLED = "backendinfo/certificate-client-not-installed-list";
import React, { useEffect, useState } from "react";
import newWindowsIcon from "../../assets/img/DashIcon/newWindowIcon.png";
import { Dropdown, Menu, Button } from "antd";

import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { notifyApi, windowsApi } from "../../api/axiosSet";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "jspdf-autotable";

function WindowsUsbViolation() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rowData, setRowData] = useState([]);
  const { user } = useSelector((state) => state.authSlice);
  const [pcId, setPcId] = useState([]);
  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });
  const [selectedPcId, setSelectedPcId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUsbHistoryId, setSelectedUsbHistoryId] = useState("");
  const [adminActionData, setAdminActionData] = useState([]);
  const [states, setStates] = useState("");
  const [comment, setComment] = useState("");

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async (bool) => {
    let isMounted = true;
    let timeoutId;
    if (bool) {
      setPageNumber(1);
    }
    try {
      setLoading(true);
      let url = `/usbhistoryinfo/get-pc-with-unwhitelisted-usb/${user?.data?.user?.userId}`;
      const selectedDepartment = state?.selectedDepartment;
      const selectedSection = state?.selectedSection;
      const selectedSubsection = state?.selectedSubsection;
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${selectedSection.value
          }`;
      }
      if (selectedSubsection) {
        url += `${selectedDepartment || selectedSection ? "&" : "?"
          }subSection=${selectedSubsection.value}`;
      }
      if (selectedDepartment || selectedSection || selectedSubsection) {
        url += `&page=${pageNumber}&pageSize=${pageSize}`
      }
      else {
        url += `?page=${pageNumber}&pageSize=${pageSize}`
      }

      if (searchTerm) {
        url += `&identifier=${searchTerm}`;
      }
      const response = await windowsApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      if (isMounted) {

        if (response?.data?.data.length > 0) {
          const calculateData = response?.data?.data?.map((v) => {
            return {
              hostname: v?.hostname || "NA",
              serialNumber: v?.serialNumber || "NA",
              ipAddress: v?.ipAddress,
              openTcpPorts: v?.openTcpPorts || "NA",
              usbName: v?.usbName?.split(" ") || [],
              usbSerialNumber: v?.usbSerialNumber,
              macAddress: v?.macAddress,
              usbManufacturer: v?.usbManufacturer,
              usbProductId: v?.usbProductId,
              usbVendorId: v?.usbVendorId,
              localAddress: v?.localAddress,
              localPort: v?.localPort,
              foreignAddress: v?.foreignAddress,
              foreignPort: v?.foreignPort,
              time: v?.time,
              falsePositive: v?.falsePositive,
              usbHistoryId: v?.usbHistoryId,
              action: "yourActionValue",
              pc_Id: v?.pcId || "NA",
              adminActionCount: v?.adminActionCount,
              IsadminActionDone: v?.IsadminActionDone,
            };
          });
          setColumnData(calculateData);
        } else {
          setColumnData([]);
        }
      }
    } catch (error) {
      if (isMounted) {
        setError(error);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  };

  const UserType = user?.data?.user?.roleName;

  useEffect(() => {
    fetchData(false);
  }, [user, state, pageNumber, pageSize]);

  useEffect(() => {
    fetchData(true);
  }, [user, state, searchTerm]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();

      // Add organization name and export date/time at the top of the CSV file
      const csvHeader = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
        "", // Empty line before column headers
      ];

      // Determine which columns should be included based on non-empty values
      const headers = ["S. No."]; // Start with "S. No."
      const rows = [
        (item, index) => index + 1, // Add serial number generator
      ];

      if (columnData.some((item) => item.hostname)) {
        headers.push("Hostname");
        rows.push((item) => item.hostname);
      }
      if (columnData.some((item) => item.serialNumber)) {
        headers.push("Serial No.");
        rows.push((item) => item.serialNumber);
      }
      if (columnData.some((item) => item.ipAddress)) {
        headers.push("IP Address");
        rows.push((item) => {
          if (typeof item.ipAddress === "object" && item.ipAddress !== null) {
            return item.ipAddress.join(" | ");
          } else {
            return item.ipAddress.replace(/ /g, ",").replace(/,/g, " | ");
          }
        });
      }
      if (columnData.some((item) => item.usbName)) {
        headers.push("USB Name");
        rows.push((item) => item.usbName);
      }
      if (columnData.some((item) => item.usbSerialNumber)) {
        headers.push("USB Serial No.");
        rows.push((item) => item.usbSerialNumber);
      }
      if (columnData.some((item) => item.macAddress)) {
        headers.push("MAC Address");
        rows.push((item) => item.macAddress);
      }
      if (columnData.some((item) => item.usbManufacturer)) {
        headers.push("Manufacturer");
        rows.push((item) => item.usbManufacturer);
      }
      if (columnData.some((item) => item.usbProductId)) {
        headers.push("USB ProductId");
        rows.push((item) => item.usbProductId);
      }
      if (columnData.some((item) => item.usbVendorId)) {
        headers.push("USB VendorId");
        rows.push((item) => item.usbVendorId);
      }
      if (columnData.some((item) => item.localAddress)) {
        headers.push("Local Address");
        rows.push((item) => item.localAddress);
      }
      if (columnData.some((item) => item.localPort)) {
        headers.push("Local Port");
        rows.push((item) => item.localPort);
      }
      if (columnData.some((item) => item.foreignAddress)) {
        headers.push("Foreign Address");
        rows.push((item) => item.foreignAddress);
      }
      if (columnData.some((item) => item.foreignPort)) {
        headers.push("Foreign Port");
        rows.push((item) => item.foreignPort);
      }
      if (columnData.some((item) => item.time)) {
        headers.push("Timestamp");
        rows.push((item) => item.time);
      }
      if (columnData.some((item) => item.falsePositive)) {
        headers.push("False Positive");
        rows.push((item) => item.falsePositive);
      }

      const csvData = [
        csvHeader.join(","),
        `${state?.titleState}`,
        "",
        headers.join(","),
        ...columnData.map((item, index) =>
          rows.map((row) => row(item, index)).join(",")
        ),
      ].join("\n");

      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}.csv`);
    }
  };


  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      const doc = new jsPDF({ orientation: "landscape" });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();

      // Add header with org name and export date/time
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);

      // Define headers and rows
      const headers = [];
      const rows = [];

      const checkAndPushHeader = (key, label) => {
        if (columnData.some((item) => item[key])) {
          headers.push(label);
          return (item) => {
            if (key === 'ipAddress') {
              return item[key].split(" ").join(", ");
            }

            const value = item[key] || "NA";
            if (Array.isArray(value)) {
              return value.join(", ");
            }
            return value.toString();
          };
        }
        return null;
      };

      const rowFuncs = [
        checkAndPushHeader("hostname", "Hostname"),
        checkAndPushHeader("serialNumber", "Serial Number"),
        checkAndPushHeader("ipAddress", "IP Address"),
        checkAndPushHeader("usbName", "USB Name"),
        checkAndPushHeader("usbSerialNumber", "USB Serial No"),
        checkAndPushHeader("macAddress", "MAC Address"),
        checkAndPushHeader("usbManufacturer", "Manufacturer"),
        checkAndPushHeader("usbProductId", "USB ProductId"),
        checkAndPushHeader("usbVendorId", "USB VendorId"),
        checkAndPushHeader("localAddress", "Local Address"),
        checkAndPushHeader("localPort", "Local Port"),
        checkAndPushHeader("foreignAddress", "Foreign Address"),
        checkAndPushHeader("foreignPort", "Foreign Port"),
        checkAndPushHeader("time", "Timestamp"),
        checkAndPushHeader("falsePositive", "False Positive"),
      ].filter(Boolean);

      columnData.forEach((item, index) => {
        const row = [index + 1, ...rowFuncs.map((func) => func(item))];
        rows.push(row);
      });

      headers.unshift("S. No.");

      // Use autoTable to create the table
      doc.autoTable({
        startY: 30,
        head: [headers],
        body: rows,
        styles: {
          cellPadding: 2,
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [52, 73, 94],
          textColor: 255,
          halign: "center",
          lineWidth: 0.5,
          fontSize: 8,
        },
        bodyStyles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
          fontSize: 8,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: { cellWidth: 15 },
          1: { cellWidth: 35 },
          2: { cellWidth: 25 },
          3: { cellWidth: 30 },
          4: { cellWidth: 35 },
          5: { cellWidth: 35 },
          6: { cellWidth: 25 },
          7: { cellWidth: 20 },
          8: { cellWidth: 20 },
          9: { cellWidth: 25 },
        }
      });

      // Save the PDF
      doc.save(`${state?.titleState}.pdf`);
    }
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };


  const handleOpenModal = async (pcId, usbHistoryId) => {
    setSelectedPcId(pcId);
    setSelectedUsbHistoryId(usbHistoryId);
    await HandleAdminget(usbHistoryId);
  };

  const handleOpenModalCount = async (pcId, usbHistoryId) => {
    setSelectedPcId(pcId);
    setSelectedUsbHistoryId(usbHistoryId);
    await HandleAdminget(usbHistoryId);
    setShowModal(true);
  };

  const HandleAdminget = async (usbHistoryId) => {
    try {
      const response = await notifyApi.post(
        `/get-admin-action/by/${usbHistoryId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setAdminActionData(response?.data?.data);
      } else {
        setAdminActionData();
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn("404 Not Found: ", error.message);
        setAdminActionData();
      } else {
        console.error("Error de-linking USBs from devices:", error);
      }
    }
  };

  const handleSubmit = async (e, pcId, usbserialNumbers, usbHistoryId) => {
    e.preventDefault();
    try {
      const data = {
        organization_Id: user?.data?.user?.organizationId,
        pcIds: [pcId],
        usbSerialNumbers: [usbserialNumbers],
        serviceNo: user?.data?.user?.email,
        usbHistoryIds: [usbHistoryId],
      };

      const response = await notifyApi.post(
        `/add/usbs/to-devices/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        fetchData(true);
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitControl = async (e) => {
    e.preventDefault();
    try {
      const data = {
        action: states,
        comment: comment,
        usbHistoryId: selectedUsbHistoryId,
        serviceNo: user?.data?.user?.email,
        pcId: selectedPcId,
      };

      const response = await notifyApi.post(
        `/create-admin-action/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.data?.message?.length > 0) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        document.getElementById("closeModal").click();
        HandleAdminget(selectedUsbHistoryId);
      }
      setComment("");
      setStates("");
      fetchData(true);
    } catch (error) {
      toast.error("Failed to update status");
    }
  };

  const handleStatusChange = async (userId, pcId, usbserviceno) => {

    try {
      const data = {
        falsePositive: true,
        serviceNo: user?.data?.user?.email,
        pcId: pcId,
      };

      const response = await windowsApi.put(
        `/usbhistoryinfo/update-false-positive/${userId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchData(true);
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
    }
  };

  const hasFalsePositive = rowData?.some(
    (row) => row.falsePositive !== undefined
  );

  const getBadgeClass = (action) => {
    switch (action) {
      case "actionInitiated":
        return "text-bg-danger";
      case "updateViolation":
        return "text-bg-warning";
      case "closeViolation":
        return "text-bg-success";
      default:
        return "text-bg-secondary";
    }
  };

  // Inside your component render
  const action =
    Array.isArray(adminActionData) && adminActionData?.length > 0
      ? adminActionData[0]?.action
      : null;

  const badgeClass = getBadgeClass(action);

  const columnsAdmin = [
    {
      name: "S.No",
      selector: (row, index) => index + 1, // Automatically generate serial numbers
      width: "90px",
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      cell: (row) => {
        if (!row.createdAt) return null; // Don't show anything if createdAt is empty
        return (
          <Tippy maxWidth="none" content={formatTimestamp(row.createdAt)}>
            <div>{formatTimestamp(row.createdAt)}</div>
          </Tippy>
        );
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "State",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.action} placement="top">
          <span>{row.action}</span>
        </Tippy>
      ),
    },
    {
      name: "Service No",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.serviceNo} placement="top">
          <span>{row.serviceNo}</span>
        </Tippy>
      ),
    },
    {
      name: "Comment",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.comment} placement="top">
          <span>{row.comment}</span>
        </Tippy>
      ),
    },
  ];

  const menuadminset = (row) => (
    <Menu>
      {row.usbName && (
        <Menu.Item key="usbName">
          <strong>USB Name:</strong> {row?.usbName}
        </Menu.Item>
      )}
      {row.usbProductId && (
        <Menu.Item key="productId">
          <strong>Product ID:</strong> {row?.usbProductId}
        </Menu.Item>
      )}
      {row.usbVendorId && (
        <Menu.Item key="vendorId">
          <strong>Vendor ID:</strong> {row?.usbVendorId}
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (pageNumber - 1) * pageSize + index + 1,
        width: "90px",
      },
      {
        name: "Hostname",
        selector: (row) => row.hostname,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.hostname}>
            <div>{row.hostname}</div>
          </Tippy>
        ),
        sortable: true,
        width: "200px",
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
      },
      {
        name: "IP Address",
        selector: (row) => (
          <Tippy maxWidth="none" content={row?.ipAddress?.replace(" ", ", ")}>
            <div>{row?.ipAddress?.replace(" ", ", ")}</div>
          </Tippy>
        ),
        width: "150px",
      },

      {
        name: "USB Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.usbSerialNumber}>
            <Dropdown
              overlay={menuadminset(row)}
              trigger={["hover"]}
            >
              <div>
                <Button type="link" className="p-0">
                  {row.usbSerialNumber}{" "}
                </Button>
              </div>
            </Dropdown>
          </Tippy>
        ),
        width: "290px",
      },

      {
        name: "Manufacturer",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.usbManufacturer}>
            <div>{row.usbManufacturer}</div>
          </Tippy>
        ),
        width: "180px",
      },

      {
        name: "Timestamp",
        selector: (row) => row.time,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.time}>
            <div>{row.time}</div>
          </Tippy>
        ),
        sortable: true,
        width: "180px",
      },

      {
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <>
                {row.falsePositive === undefined ? (
                  <span className="badge text-white rounded-pill text-bg-secondary">
                    N/A
                  </span>
                ) : row.falsePositive === false ? (
                  <button
                    type="button"
                    className={`btn btn-sm ${row?.IsadminActionDone === "inProgress"
                      ? "btn-outline-dark"
                      : "btn-dark"
                      }`}
                    onClick={() =>
                      handleStatusChange(
                        row.usbHistoryId,
                        row?.pc_Id,
                        row.usbSerialNumber
                      )
                    }
                    disabled={row?.IsadminActionDone === "inProgress"}
                  >
                    False Positive
                  </button>
                ) : (
                  <span className="badge text-success rounded-pill">True</span>
                )}
              </>
            )}
          </>
        ),
        width: ` ${UserType === "Super Admin" ? '145px' : "1px"}`,
      },

      {
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <>
                <button
                  type="button"
                  className={`btn btn-sm ${row?.IsadminActionDone === "inProgress"
                    ? "btn-outline-info"
                    : "btn-info"
                    }`}
                  onClick={(e) =>
                    handleSubmit(
                      e,
                      row?.pc_Id,
                      row?.usbSerialNumber,
                      row?.usbHistoryId
                    )
                  }
                  disabled={row?.IsadminActionDone === "inProgress"}
                >
                  Whitelist USB
                </button>
              </>
            )}
          </>
        ),
        width: ` ${UserType === "Super Admin" ? "145px" : "1px"}`,
      },

      {
        name: "Action",
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <div style={{ position: "relative", marginRight: "30px" }}>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  style={{ width: "7rem", textAlign: "center" }}
                  id="openModalButton"
                  data-bs-toggle="modal"
                  data-bs-target="#deviceModal"
                  onClick={() => {
                    handleOpenModal(row?.pc_Id, row?.usbHistoryId);
                  }}
                >
                  Admin Action &nbsp;{" "}
                </button>

                <button
                  type="button"
                  className={`btn btn-sm position-absolute ${row?.IsadminActionDone === "pending"
                    ? "btn-danger"
                    : row?.IsadminActionDone === "inProgress"
                      ? "btn-warning"
                      : row?.IsadminActionDone === "completed"
                        ? "btn-success"
                        : "btn-dark"
                    }`}
                  id="openModalButton2"
                  data-bs-toggle="modal"
                  data-bs-target="#deviceModal2"
                  disabled={row?.adminActionCount === 0}
                  style={{
                    top: "-10px",
                    right: "-11px",
                    height: "22px",
                    width: "22px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                  }}
                  onClick={() =>
                    handleOpenModalCount(row?.pcId, row?.usbHistoryId)
                  }
                >
                  <span style={{ fontSize: "13px" }}>
                    {row?.adminActionCount}
                  </span>
                </button>
              </div>
            )}
            <Button
              className="btn details-button rounded-md border-0 d-flex fs10 gap-3 mx-auto px-2 text-light bg-dark text-center justify-center align-items-center"
              onClick={() =>
                navigate("/linux-client-Details", {
                  state: {
                    basicInfo: row,
                    pcInfoId: row?.pc_Id,
                    title: state?.titleState,
                  },
                })
              }
            >
              <p className="m-0 p-0 fs-12">Details</p>
              <i className="ri-arrow-right-line"></i>
            </Button>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        width: "330px",
      },
    ],
    [pageNumber, pageSize, navigate, rowData, hasFalsePositive]
  );

  useEffect(() => {

    const modalElement = document.getElementById("deviceModal");

    const handleModalClose = () => {
      setStates("");
      setComment("");
    };

    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const location = useLocation();

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <ol className="breadcrumb fs-sm mb-2 p-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">{`Dashboard`}</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/windows-dash" ? (
              <span className="active">{`Windows Dashboard`}</span>
            ) : (
              <NavLink exact to="/windows-dash">
                Windows Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/windows-usb-violation" ? (
              <span className="active">
                {state?.titleState || "USB Violation"}
              </span>
            ) : (
              <NavLink exact to="/windows-usb-violation">
                {state?.titleState || "USB Violation"}
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={newWindowsIcon} alt="Windows-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Dashboard</p>
          </span>
          {/* <div className="d-flex justify-content-center align-items-center">
            <Button className="btn btn-primary rounded-md d-flex align-items-center gap-2 back-button" onClick={() => navigate(-1)} >
              <i className="ri-arrow-left-line fs-18 lh-1 mr-2" style={{ verticalAlign: 'middle', marginRight: '3px' }}></i>
              <span>Back</span>
            </Button>
          </div> */}
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div className="col-xl">
            <p className="fs-20 text-dark fw-bolder p-0 m-0">USB Violations</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0}{" "}
              {state?.titleState.includes("Violations") === true
                ? "Violations"
                : "Endpoints"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="" style={{ position: "relative", width: "17rem" }}>
              <input
                style={{ paddingRight: "1.3rem" }}
                className="form-control"
                type="text"
                placeholder="Search by Hostname or Serial No."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
            </div>
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div xl={12} className="card rounded mb-2 shadow cardStyle mt-2">
          {!loading ? (
            <DataTable
              columns={columns}
              data={columnData || []}
              pagination
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          ) : (
            <PageLoader />
          )}
        </div>

        <div
          className="modal fade"
          id="deviceModal"
          tabIndex="-1"
          aria-labelledby="deviceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deviceModalLabel">
                  Control <i className="ri-usb-line"></i>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                ></button>
              </div>
              <div className="m-3">
                <div className=" mt-2">
                  <form
                    onSubmit={(e) => handleSubmitControl(e, state, comment)}
                  >
                    <div className="modal-body">
                      {/* Dropdown for state selection */}
                      <div className="mb-3">
                        <label htmlFor="stateSelect" className="form-label">
                          State
                        </label>
                        <select
                          id="stateSelect"
                          className="form-select"
                          value={states}
                          onChange={(e) => setStates(e.target.value)}
                          required
                        >
                          <option value="">Select State</option>
                          <option value="actionInitiated">
                            Action Initiated
                          </option>
                          <option value="updateViolation">
                            Violation Updated
                          </option>
                          <option value="closeViolation">
                            Violation Closed
                          </option>
                        </select>
                      </div>

                      {/* Comment textarea */}
                      <div className="mb-3">
                        <label htmlFor="comment" className="form-label">
                          Comment
                        </label>
                        <textarea
                          id="comment"
                          className="form-control"
                          rows="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Add your comment here..."
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-sm btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                <div className="card rounded mb-2 shadow cardStyle mt-2 mb-2">
                  <DataTable
                    columns={columnsAdmin}
                    data={adminActionData}
                    pagination
                    noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deviceModal2"
          tabIndex="-1"
          aria-labelledby="deviceModalLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="deviceModalLabel2">
                  Admin Actions History
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="card rounded mb-2 shadow cardStyle mt-2 mb-2">
                  <DataTable
                    columns={columnsAdmin}
                    data={adminActionData}
                    pagination
                    noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WindowsUsbViolation;
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { userApi, notifyApi } from "../../api/axiosSet";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Switch from "react-switch";
import { DownOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { useSkinMode } from "../../components/SkinModeContext";
import PageLoader from "../../components/common/Loader/PageLoader";

function Users() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const { user } = useSelector((state) => state.authSlice);

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasT, setShowOffcanvasT] = useState(false);
  const [roleName, setRoleName] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [toggleClearRows, setToggleClearRows] = useState(false);

  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("")
  const [newUserRank, setNewUserRank] = useState("");
  const [newUserBranch, setNewUserBranch] = useState("");
  const [newUserAppointment, setNewUserAppointment] = useState("");
  const [newUserStation, setNewUserStation] = useState("");
  const [newUserCommand, setNewUserCommand] = useState("");
  const [errors, setErrors] = useState({});

  const [newUserRole, setNewUserRole] = useState({});
  const [newUserOrg, setNewUserOrg] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);

  const [updateUserId, setUpdateUserId] = useState("");

  const { skinMode } = useSkinMode();

  if (skinMode === "dark") {
    document.body.classList.add("dark-skin"); // Add a class to the body
  } else {
    document.body.classList.remove("dark-skin"); // Remove it when it's not dark mode
  }
  const invalidCharacters = /[<>'"]/;
  const hasSpecialChars = /[^a-zA-Z0-9\s]/;
  const hasNumbers = /[0-9]/;
  const hasLowercase = /[a-z]/;
  const hasUppercase = /[A-Z]/;

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (newUserAppointment && newUserAppointment.trim() === "") {
      errors.newUserAppointment = "Appointment is not valid.";
      isValid = false;
    }

    if (newUserStation && newUserStation.trim() === "") {
      errors.newUserStation = "Station is not valid.";
      isValid = false;
    }

    if (newUserAppointment && newUserAppointment.length > 50) {
      errors.newUserAppointment = "Appointment must be less than 50 characters.";
      isValid = false;
    }

    if (newUserStation && newUserStation.length > 50) {
      errors.newUserStation = "Station must be less than 50 characters.";
      isValid = false;
    }

    if (!newUserEmail || newUserEmail.trim() === "") {
      errors.newUserEmail = "Service Number is required.";
      isValid = false;
    }

    if (newUserEmail.length > 10) {
      errors.newUserEmail = "Service Number must be less than 10 digits.";
      isValid = false;
    }

    if (newUserEmail && newUserEmail < 0) {
      errors.newUserEmail = "Service Number must be positive.";
      isValid = false;
    }

    if (!newUserName || newUserName.trim() === "") {
      errors.newUserName = "Name is required.";
      isValid = false;
    }

    if (newUserName && newUserName.length > 50) {
      errors.newUserName = "Name must be less than 50 characters.";
      isValid = false;
    }

    if (formType !== "updateUser") {
      if ((!newUserPassword || newUserPassword.trim() === "") && formType !== "updateUser") {
        errors.newUserPassword = "Password is required.";
        isValid = false;
      }
      else if (newUserPassword.length < 8) {
        errors.newUserPassword = "Password must be at least 8 characters.";
        isValid = false;
      } else if (newUserPassword.length > 50) {
        errors.newUserPassword = "Password must less than 50 characters.";
        isValid = false;
      }
      else if (!hasSpecialChars.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain special characters.";
        isValid = false;
      }
      else if (!hasLowercase.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one lowercase letter.";
        isValid = false;
      }
      else if (!hasUppercase.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one uppercase letter.";
        isValid = false;
      }
      else if (!hasNumbers.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one number.";
        isValid = false;
      }
      else if (invalidCharacters.test(newUserPassword)) {
        errors.newUserPassword = "Password contains invalid characters.";
        isValid = false;
      }
    }

    if (newUserPassword && newUserPassword.trim() === "") {
      errors.newUserPassword = "Password is not valid.";
      isValid = false;
    }

    if (newUserPassword) {
      if (newUserPassword.length < 8) {
        errors.newUserPassword = "Password must be at least 8 characters.";
        isValid = false;
      } else if (newUserPassword.length > 50) {
        errors.newUserPassword = "Password must less than 50 characters.";
        isValid = false;
      }
      else if (!hasSpecialChars.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain special characters.";
        isValid = false;
      }
      else if (!hasLowercase.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one lowercase letter.";
        isValid = false;
      }
      else if (!hasUppercase.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one uppercase letter.";
        isValid = false;
      }
      else if (!hasNumbers.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one number.";
        isValid = false;
      }
      else if (invalidCharacters.test(newUserPassword)) {
        errors.newUserPassword = "Password contains invalid characters.";
        isValid = false;
      }
    }
    if (newUserName && (hasSpecialChars.test(newUserName) || hasNumbers.test(newUserName))) {
      errors.newUserName = "Name must not contain special characters or numbers.";
      isValid = false;
    }
    if (newUserStation && invalidCharacters.test(newUserStation)) {
      errors.newUserStation = "Station contains invalid characters.";
      isValid = false;
    }

    if (newUserAppointment && invalidCharacters.test(newUserAppointment)) {
      errors.newUserAppointment = "Appointment contains invalid characters.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const branchValues = [
    "F(P)", "AE(L)", "AE(M)", "Edm", "Adm",
    "Lgs", "Accts", "Met", "Tech", "Non-Tech"
  ]
  const rankValues = [
    "Air Chief Mshl", "Air Mshl", "AVM", "Gp Capt", "Wg Cdr",
    "Sqn Ldr", "Flt Lt", "Flg Offr", "MWO", "WO", "JWO",
    "SGT", "CPL", "LAC", "AC"
  ]

  const commandValues = [
    "Air HQ", "WAC", "EAC", "CAC", "SWAC", "TC",
    "MC", "AFC", "SAC"
  ]

  const [searchTerm, setSearchTerm] = useState("");
  const [formType, setFormType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });


  const fetchData = async (bool) => {
    setLoading(true);
    if (bool) {
      setPageNumber(1);
    }
    try {
      let url = `/organization/users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`;
      if (searchTerm) {
        url += `&identifier=${searchTerm}`;
      }
      const response = await userApi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.data;
      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      setColumnData(devices);
      setLoading(false);
    } catch {
      setTotalDocuments([]);
      setColumnData(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [user, pageNumber, pageSize, toggleClearRows]);

  useEffect(() => {
    fetchData(true);
  }, [user, searchTerm]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await notifyApi.get(
          `/all-department/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();

    const superAdminRole = roleName?.find(
      (role) => role.name === "Super Admin"
    );
    if (superAdminRole) {
      setNewUserRole({ label: superAdminRole.name, value: superAdminRole._id });
    }

    const superOrg = roleName?.find(
      (role) => role.name === "Organization Admin"
    );
    if (superOrg) {
      setNewUserOrg({ label: superOrg.name, value: superOrg._id });
    }
  }, [user?.data?.user?.organizationId, roleName]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const handleStatusChange = async (userId, currentStatus) => {

    // Convert the current status to a boolean
    const newStatus = !currentStatus;

    try {
      const data = {
        isActive: newStatus,
        user_id: userId,
      };

      const response = await userApi.post(
        `/update-user-status/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Update the status locally without refetching the entire data
        setColumnData((prevData) => {
          if (!prevData) {
            console.error("Previous data is undefined");
            return [];
          }

          return prevData.map((user) =>
            user.id === userId ? { ...user, status: newStatus } : user
          );
        });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row?.firstName,
      cell: (row) => (
        <Tippy maxWidth="none" content={row.firstName}>
          <div>{row.firstName}</div>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Service No.",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.email}>
          <div>{row?.email}</div>
        </Tippy>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          style={getStatusStyle(
            row.status === true || row.status === "true" ? "Active" : "Inactive"
          )}
        >
          {row.status === true || row.status === "true" ? "Active" : "Inactive"}
        </span>
      ),
      sortable: true,
      // width: '110px'
    },

    {
      name: "Added",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <Tippy maxWidth="none" content={formatTimestamp(row.createdAt)}>
          <div>{formatTimestamp(row.createdAt)}</div>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Associated With",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.departmentInfo || "NA"}>
          <div>{row.departmentInfo || "NA"}</div>
        </Tippy>
      ),
      // width: "280px",
    },
    {
      name: "Role",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.roleName}>
          <div>{row.roleName && row.roleName === 'subSection Admin' ? 'Subsection Admin' : row.roleName || "NA"}</div>
        </Tippy>
      ),
      // width: '150px'
    },
    {
      name: "Action",
      cell: (row) => (
        <Switch
          onChange={() =>
            handleStatusChange(
              row.id,
              row.status === "true" || row.status === true
            )
          }
          checked={row.status === "true" || row.status === true} // Convert status to boolean
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00e676"
          offColor="#ff1744"
          height={18}  // Smaller height
          width={35}   // Smaller width
          handleDiameter={12}  // Control the size of the switch handle
        />
      ),
    },
    {
      name: "Edit/Update",
      cell: (row) => (
        <div className="d-flex gap-2"><button
          onClick={() => {
            setNewUserName(row.firstName || "")
            setNewUserEmail(row.email || "")
            setNewUserAppointment(row.appointment || "")
            setNewUserStation(row.station || "")
            setNewUserCommand(row.command || "")
            setNewUserBranch(row.branch || "")
            setNewUserRank(row.rank || "")
            setUpdateUserId(row.id)
            setFormType('updateUser')
          }}
          className="btn btn-outline-primary"
          variant="primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <i class="ri-edit-fill"></i>
        </button>
          <button
            onClick={() => handleActionClick(row)}
            className="btn btn-outline-danger bg-btn-danger"
            variant="primary"
          >
            <i class="ri-delete-bin-7-fill"></i>
          </button>
        </div>
      ),
    },
  ];

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => key === "departmentInfo" ? true : columnData.some((item) => item[key]);

      const headers = [
        { label: "Name", key: "firstName" },
        { label: "Service No.", key: "email" },
        { label: "Status", key: "status" },
        { label: "Creation Date", key: "createdAt" },
        { label: "Associated With", key: "departmentInfo" },
        { label: "Role", key: "roleName" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "createdAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "departmentInfo" ? item[header.key] || "NA"
                          : header.key === "firstName" ? item[header.key]?.replace(",", "")
                            : item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `users_associated.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Users Associated`, 14, 22);

      const hasData = (key) => key === "departmentInfo" ? true : columnData.some((item) => item[key]);
      const headers = [
        { label: "Name", key: "firstName" },
        { label: "Service No.", key: "email" },
        { label: "Status", key: "status" },
        { label: "Creation Date", key: "createdAt" },
        { label: "Associated With", key: "departmentInfo" },
        { label: "Role", key: "roleName" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "createdAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : header.key === "departmentInfo" ? item[header.key] || "NA"
                      : Array.isArray(item[header.key])
                        ? item[header.key].join(", ")
                        : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`users_associated.pdf`);
    }
  }

  const getAllList = async () => {
    try {
      const response = await userApi.get(
        `/roles/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // setDepartments(response.data.departments);
      setRoleName(response.data.roles);
    } catch (error) {
      console.error("Error fetching organization data: ", error);
    }
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    getAllList();
  };

  const handleActionClick = async (row) => {
    // Confirm deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) {
      // Show cancel toast notification
      // toast.info("Deletion canceled.", {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "light",
      // });
      return; // Exit if the user cancels
    }

    try {
      const response = await userApi.delete(
        `/delete/by/${user?.data?.user?.organizationId}/${row.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          data: { userId: row.id },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Update DataTable data
      setSelectedRows([])
      await fetchData();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };
  const handleTableClick = () => {
    setShowOffcanvasT(true);
  };
  const handleCheckboxChange = (columnName) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };

  const submitRoleAsSuperAdmin = async () => {
    if (!newUserRole) {
      toast.error("Please select a role");
      return;
    }
    const user_id = selectedRows.map((row) => row.id);

    try {
      const data = {
        user_id: user_id,
        roleId: newUserRole.value,
      };

      const response = await notifyApi.post(
        `/manage-users-role/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("Role updated successfully");
      // Optionally reset form or state here
      setShowOffcanvas(false);
      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
      setNewUserRole({});
      setNewUserOrg({});
      setSelectedOption(null);
      setSections([]);
      setSubsections([]);
      fetchData();
      setSelectedRows([]);
      setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  }

  const submitRoleAsOrgAdmin = async () => {
    if (!newUserOrg) {
      toast.error("Please select a role");
      return;
    }
    const user_id = selectedRows.map((row) => row.id);

    try {
      const data = {
        user_id: user_id,
        roleId: newUserOrg.value,
      };

      const response = await notifyApi.post(
        `/manage-users-role/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("Role updated successfully");
      // Optionally reset form or state here
      setShowOffcanvas(false);
      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
      setNewUserRole({});
      setNewUserOrg({});
      setSections([]);
      setSubsections([]);
      setSelectedOption(null);
      fetchData();
      setSelectedRows([]);
      setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedOption === 'superAdmin') {
      submitRoleAsSuperAdmin();
      return;
    }
    if (selectedOption === 'orgAdmin') {
      submitRoleAsOrgAdmin();
      return;
    }

    const user_id = selectedRows.map((row) => row.id);

    // Construct the data object based on which fields are selected
    const data = { user_id };

    if (selectedDepartment) {
      data.department = selectedDepartment.value;
    }
    if (selectedSection) {
      data.section = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSection = selectedSubsection.value;
    }

    try {
      const response = await notifyApi.post(
        `/manage-users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      setShowOffcanvas(false);
      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
      setSections([]);
      setSubsections([]);
      setNewUserRole({});
      setNewUserOrg({});
      setSelectedOption(null);
      fetchData();
      setSelectedRows([]);
      setToggleClearRows(!toggleClearRows);
    } catch (error) {
      toast.error(error);
    }
  };

  const resetForm = () => {
    setNewUserName("");
    setNewUserEmail("");
    setNewUserPassword("");
    setNewUserRank("");
    setNewUserBranch("");
    setNewUserAppointment("");
    setNewUserStation("");
    setNewUserCommand("");
    setUpdateUserId("");
    setErrors({});
  };

  const handleUpdateUser = async () => {
    const data = {
      fname: newUserName?.trim(),
      email: newUserEmail?.trim(),
      rank: newUserRank?.trim(),
      branch: newUserBranch?.trim(),
      appointment: newUserAppointment?.trim(),
      station: newUserStation?.trim(),
      command: newUserCommand?.trim(),
      userIdToUpdate: updateUserId?.trim(),
    };

    if (newUserPassword) {
      data.password = newUserPassword;
    }
    try {
      const response = await userApi.post(
        `/update-profile/by/admin/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const resData = response.data;
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      resetForm();

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error?.response?.data?.message);
      // Handle error response
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };

  const handleAddUser = async () => {

    const data = {
      fname: newUserName?.trim(),
      email: newUserEmail?.trim(),
      password: newUserPassword,
      rank: newUserRank?.trim(),
      branch: newUserBranch?.trim(),
      appointment: newUserAppointment?.trim(),
      station: newUserStation?.trim(),
      command: newUserCommand?.trim(),
    };

    try {
      const response = await userApi.post(
        `/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      resetForm();

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error?.response?.data?.message);
      // Handle error response
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };

  const handleAddOrganization = async () => {

    const data = {
      fname: newUserName?.trim(),
      email: newUserEmail?.trim(),
      organization: user?.data?.user?.organization,
      organizationId: user?.data?.user?.organizationId,
      password: newUserPassword,
      rank: newUserRank?.trim(),
      branch: newUserBranch?.trim(),
      appointment: newUserAppointment?.trim(),
      station: newUserStation?.trim(),
      command: newUserCommand?.trim(),
    };

    try {
      const response = await userApi.post(
        `/add-organization-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      resetForm();

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error);
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Handle error response
    }
  };

  const handleAddSuper = async () => {
    const data = {
      fname: newUserName?.trim(),
      email: newUserEmail?.trim(),
      organization: user?.data?.user?.organization,
      organizationId: user?.data?.user?.organizationId,
      password: newUserPassword,
      rank: newUserRank?.trim(),
      branch: newUserBranch?.trim(),
      appointment: newUserAppointment?.trim(),
      station: newUserStation?.trim(),
      command: newUserCommand?.trim(),
    };

    try {
      const response = await userApi.post(
        `/add-super-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      resetForm();

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error);
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Handle error response
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    if (formType === 'user') {
      handleAddUser();
    } else if (formType === 'organization') {
      handleAddOrganization();
    } else if (formType === 'super') {
      handleAddSuper();
    }
    else if (formType === 'updateUser') {
      handleUpdateUser();
    }
    resetForm();
    document.getElementById('closeModalButton').click(); // Close the modal after submission
  };

  useEffect(() => {
    const modalElement = document.getElementById('exampleModal');
    const handleModalHidden = () => {
      resetForm();
    };

    modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, []);

  const hideOffcanvas = () => {
    setShowOffcanvas(false);
    setSelectedOption(null);
    setSelectedDepartment(null);
    setSelectedSection(null);
    setSelectedSubsection(null);
  }

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal ps-2 d-flex flex-column">
            <p className="fs-20 text-dark fw-bolder p-0 m-0">Manage Users</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF()}
            >
              <i className="ri-file-pdf-2-fill"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV()}
            >
              <i className="ri-file-excel-2-fill"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 fw-bolder p-0 m-0 text-dark">Users List</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Users: {totalDocuments}
            </p>
          </div>
          <div className="" style={{ position: "relative", width: "16rem" }}>
            <input
              style={{ paddingRight: "1.3rem" }}
              className="form-control"
              type="text"
              placeholder="Search by Name or Service No."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="fa-solid fa-magnifying-glass position-absolute translate-middle" style={{ right: "0rem", top: "50%", transform: "translateY(-50%)" }}></i>
          </div>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-info text-white"
            onClick={handleControlClick}
            disabled={selectedRows.length === 0}
          >
            <i className="ri-shuffle-line" style={{ marginRight: "3px" }}></i>
            Assign Role
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setFormType("user")}
          >
            <i className="ri-add-line"></i> User
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setFormType("organization")}
          >
            <i className="ri-add-line"></i> Org Admin
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setFormType("super")}
          >
            <i className="ri-add-line"></i> Super Admin
          </button>
        </div>
        <div className="card rounded mb-2 shadow cardStyle">
          {loading ? (
            <PageLoader />
          ) : (
            <DataTable
              columns={columns}
              data={columnData || []}
              pagination
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={toggleClearRows}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<div className="p-2 me-auto no-data-found w-100">No Data Found</div>}
            />
          )}
        </div>

        {/* Offcanvas for displaying selected rows */}
        <Offcanvas
          show={showOffcanvas}
          onHide={hideOffcanvas}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Selected Users:{" "}
              <span className="badge rounded-pill text-bg-secondary text-white">
                {selectedRows.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row) => (
                <li key={row.id}>
                  <span className="badge text-bg-dark">{row.firstName}</span>
                </li>
              ))}
            </ul>

            <div className=" mb-5">
              <div className="card-body p-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="options"
                    value="superAdmin"
                    id="radioRole"
                    onChange={handleOptionChange}
                    checked={selectedOption === "superAdmin"}
                  />
                  <label className="form-check-label text-dark" htmlFor="radioRole">
                    Super Admin <i className="ri-shield-user-line"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="options"
                    value="orgAdmin"
                    id="radioDepartment"
                    onChange={handleOptionChange}
                    checked={selectedOption === "orgAdmin"}

                  />
                  <label className="form-check-label text-dark" htmlFor="radioDepartment">
                    Organization Admin <i className="ri-organization-chart"></i>
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="options"
                    value="others"
                    id="radioOthers"
                    onChange={handleOptionChange}
                    checked={selectedOption === "others"}
                  />
                  <label className="form-check-label text-dark" htmlFor="radioOthers">
                    User
                    {/* <i className="ri-organization-chart"></i> */}
                  </label>
                </div>
              </div>
            </div>

            {/* Conditionally render the Departments card */}
            <div className="">
              <div className="card-body p-2">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="departments" className="form-label text-dark">
                      Departments
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      options={departments.map((dept) => ({
                        label: dept.departmentName,
                        value: dept._id,
                      }))}
                      onChange={handleDepartmentChange}
                      isDisabled={selectedOption !== "others"}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="sections" className="form-label text-dark">
                      Sections
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      options={sections.map((section) => ({
                        label: section.sectionName,
                        value: section._id,
                      }))}
                      onChange={handleSectionChange}
                      isDisabled={selectedOption !== "others" || !selectedDepartment}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="subsections" className="form-label text-dark">
                      Sub-Sections
                    </label>
                    <Select
                      classNamePrefix="custom-select"
                      options={subsections.map((subSection) => ({
                        label: subSection.subSectionName,
                        value: subSection._id,
                      }))}
                      onChange={(selectedOption) =>
                        setSelectedSubsection(selectedOption)
                      }
                      isDisabled={selectedOption !== "others" || !selectedSection || !selectedDepartment}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary"
                    disabled={selectedOption !== "others" ? !selectedOption : selectedDepartment || selectedSection || selectedSubsection ? false : true}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {formType === "user" ? "Add Guest User" : formType === "organization" ? "Add Org. Admin" : formType === "updateUser" ? "Update User" : "Add Super Admin"}
                </h1>
                <button
                  id="closeModalButton"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetForm}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputService" className="form-label">
                      Service No. <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="exampleInputService"
                      aria-describedby="nameHelp"
                      maxLength={10}
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                      style={{ opacity: 0.8 }}
                    />
                    {errors.newUserEmail && <div className="text-danger">{errors.newUserEmail}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleSelectRank" className="form-label">
                      Rank
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectRank"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserRank(e.target.value)}
                      >
                        {newUserRank ? (
                          <option className="dropdown-item" value={newUserRank}>
                            {newUserRank}
                          </option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {rankValues &&
                          rankValues
                            .map((rank, index) => (
                              rank !== newUserRank &&
                              <option key={index} value={rank}>
                                {rank}
                              </option>
                            ))}
                      </select>
                      <DownOutlined
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none'
                        }}
                      />
                    </div>
                    {errors.newUserRank && <div className="text-danger">{errors.newUserRank}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="nameHelp"
                      maxLength={50}
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                    {errors.newUserName && <div className="text-danger">{errors.newUserName}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleSelectBranch" className="form-label">
                      Branch / Trade
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectBranch"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserBranch(e.target.value)}
                      >
                        {newUserBranch ? (
                          <option value={newUserBranch}>{newUserBranch}</option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {branchValues &&
                          branchValues.map((branch, index) => (
                            branch !== newUserBranch &&
                            <option key={index} value={branch}>
                              {branch}
                            </option>
                          ))}
                      </select>
                      <DownOutlined
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none'
                        }}
                      />
                    </div>
                    {errors.newUserBranch && <div className="text-danger">{errors.newUserBranch}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputAppoint" className="form-label">
                      Appointment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputAppoint"
                      aria-describedby="nameHelp"
                      maxLength={50}
                      value={newUserAppointment}
                      onChange={(e) => setNewUserAppointment(e.target.value)}
                    />
                    {errors.newUserAppointment && <div className="text-danger">{errors.newUserAppointment}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputStation" className="form-label">
                      Station
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputStation"
                      aria-describedby="nameHelp"
                      maxLength={50}
                      value={newUserStation}
                      onChange={(e) => setNewUserStation(e.target.value)}
                    />
                    {errors.newUserStation && <div className="text-danger">{errors.newUserStation}</div>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleSelectCommand" className="form-label">
                      Command
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectCommand"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserCommand(e.target.value)}
                      >
                        {newUserCommand ? (
                          <option value={newUserCommand}>{newUserCommand}</option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {commandValues &&
                          commandValues.map((command, index) => (
                            command !== newUserCommand &&
                            <option key={index} value={command}>
                              {command}
                            </option>
                          ))}
                      </select>
                      <DownOutlined
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'none'
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputPass" className="form-label">
                      New Password {formType !== "updateUser" && <span className="text-danger">*</span>}
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleInputPass"
                        aria-describedby="emailHelp"
                        maxLength={50}
                        value={newUserPassword}
                        onChange={(e) => setNewUserPassword(e.target.value)}
                      />
                      {!showPassword ? (
                        <EyeOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }}
                        />
                      )}
                    </div>
                    {errors.newUserPassword && <div className="text-danger">{errors.newUserPassword}</div>}
                  </div>

                  <div className="d-flex justify-content-center mt-3">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>
    </React.Fragment>
  );
}

export default Users;

import React from "react";
import Admin from "../pages/Admin/Admin";
import ListOrg from "../pages/Admin/Organizations/ListOrg";
import OrgDetail from "../pages/Admin/Organizations/OrgDetails";
import Role from "../pages/Admin/Role";
import AdminSettings from "../pages/Admin/Role";

import AddOrg from "../pages/Admin/Organizations/AddOrg";
import License from "../pages/Admin/Organizations/License";
import LicenseView from "../pages/Admin/Organizations/LicenseView";
import UserList from "../pages/Admin/Organizations/UserList";
import UpdateProfile from "../pages/Useradmin/UpdateProfile";

const AdminRoutes = [
  { path: "/", element: <Admin /> },
  { path: "/Organizations", element: <ListOrg /> },
  { path: "/org-detail/:id", element: <OrgDetail /> },
  { path: "/admin-Setting", element: <AdminSettings /> },
  { path: "/role", element: <Role /> },
  { path: "/add-org", element: <AddOrg /> },
  { path: "/org-detail/:id/licenses", element: <License /> },
  { path: "/org-detail/:id/licenses/:createdAt/:validity", element: <LicenseView /> },
  { path: "/org-detail/:id/users", element: <UserList /> },
  { path: "/update-profile", element: <UpdateProfile /> },

];

export default AdminRoutes;

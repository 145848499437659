import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/default-dp.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
import { notifyApi, userApi } from "../api/axiosSet";
import CustomToaster from "../components/common/CustomToaster";
import { showToaster } from "../feature/slices/toastSlice";
import NotificationListMain from "./NotificationListMain";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPopper } from "@popperjs/core";
import { setUser } from "../feature/slices/AuthSlice";
import ThemeToggle from "./ThemeToggle";
import { useSidebarToogle } from "../components/SidebarToogleContext";

function Header() {
  const [notificationDataUnread, setNotificationDataUnread] = useState([]);
  const [notificationDataAll, setNotificationDataAll] = useState([]);
  const [totalNotificationCountUnread, setTotalNotificationCountUnread] = useState(-1);
  const [totalNotificationCountAll, setTotalNotificationCountAll] = useState(-1);

  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const popperInstance = useRef(null);

  const { user } = useSelector((state) => state.authSlice);
  const UsertypeDept = user?.data?.user?.departmentName;
  const UsertypeSection = user?.data?.user?.sectionName;
  const UserTypeSubSection = user?.data?.user?.subSectionName;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sidebarCollapsed, toggleSidebarCollapsed } = useSidebarToogle();

  useEffect(() => {
    if (show && dropdownRef.current && menuRef.current) {
      popperInstance.current = createPopper(
        dropdownRef.current,
        menuRef.current,
        {
          placement: "bottom-end",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ],
        }
      );
    }

    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy();
        popperInstance.current = null;
      }
    };
  }, [show]);

  const handleLogOut = async () => {
    await dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (user?.isPasswordUpdated === false) {
      document.body.classList.add("sidebar-hide");
      navigate("/update-profile");
    }
  }, []);

  const [pageNoUnread, setPageNoUnread] = useState(1);
  const [pageNoAll, setPageNoAll] = useState(1);
  const [totalPagesUnread, setTotalPagesUnread] = useState(1);
  const [totalPagesAll, setTotalPagesAll] = useState(1);

  const totalNotificationCountUnreadRef = useRef(totalNotificationCountUnread);
  const totalNotificationCountAllRef = useRef(totalNotificationCountAll);

  useEffect(() => {
    totalNotificationCountUnreadRef.current = totalNotificationCountUnread;
    totalNotificationCountAllRef.current = totalNotificationCountAll;
  }, [totalNotificationCountUnread, totalNotificationCountAll]);

  // Fetch notifications function
  const fetchNotifications = async (
    type, pageNo, totalPagesAvailable, setTotalPages, setTotalCount, setData
  ) => {
    try {

      if (pageNo !== 1 && pageNo > totalPagesAvailable) {
        return;
      }

      const url = type === 'unread'
        ? `/get-all-unread-notifications/by/${user?.data?.user?.userId}?page=${pageNo}&limit=30`
        : `/get-all-notifications/by/${user?.data?.user?.userId}?page=${pageNo}&limit=30`;

      const response = await notifyApi.get(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });

      const { notifications, total, totalPages } = response.data;

      setTotalPages(totalPages);
      setData((prevData) => (pageNo === 1 ? notifications : [...prevData, ...notifications]));

      setTotalCount((prevTotalCount) => {
        if (type === 'unread') {
          if (prevTotalCount !== -1 && total > prevTotalCount) {
            const firstNotification = notifications[0];
            if (firstNotification) {
              const { eventTriggerType, hostname, alertType } = firstNotification;
              const { title, message, color } = getNotificationDetails(eventTriggerType, hostname, alertType);
              dispatch(showToaster({ title, message, color }));
            }
          }
        }
        return total;
      });
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        try {
          const response = await userApi.post(`/refresh-token`, {
            "refreshToken": user?.data?.refreshToken
          }, {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          });
          const { accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry } = response.data;
          dispatch(setUser({ ...user, data: { ...user?.data, accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry } }));
        }
        catch {
          handleLogOut();
        }
      }
    }
  };

  useEffect(() => {
    fetchNotifications(
      'all',
      pageNoAll,
      totalPagesAll,
      setTotalPagesAll,
      setTotalNotificationCountAll,
      setNotificationDataAll
    );
  }, [pageNoAll, user?.data?.accessToken, user?.data?.user?.userId]);

  useEffect(() => {
    fetchNotifications(
      'unread',
      pageNoUnread,
      totalPagesUnread,
      setTotalPagesUnread,
      setTotalNotificationCountUnread,
      setNotificationDataUnread
    );
  }, [pageNoUnread, user?.data?.accessToken, user?.data?.user?.userId]);

  // Reset notification data if needed
  useEffect(() => {
    let mounted = true;

    const intervalId = setInterval(() => {
      if (mounted) {
        fetchNotifications('unread', 1, totalPagesUnread, setTotalPagesUnread, setTotalNotificationCountUnread, setNotificationDataUnread);
      }
    }, 15000);

    return () => {
      mounted = false;
      clearInterval(intervalId);
    };
  }, [user?.data?.accessToken, user?.data?.user?.userId]);

  const resetNotificationData = () => {
    setPageNoAll(1);
    fetchNotifications('all', 1, totalPagesAll, setTotalPagesAll, setTotalNotificationCountAll, setNotificationDataAll);
    setPageNoUnread(1);
    fetchNotifications('unread', 1, totalPagesUnread, setTotalPagesUnread, setTotalNotificationCountUnread, setNotificationDataUnread);
  };

  const getNotificationDetails = (trigger_TYPE, host_name, Alert_TYPE) => {
    let title = "NA",
      message = "NA",
      color = "success";

    if (trigger_TYPE === "LINUX_USB_TRIGGER") {
      title = "USB Trigger";
      message = `Unauthorized USB used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_FIREWALL_TRIGGER") {
      title = "Firewall Trigger";
      message = `Firewall Trigger in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "MAC_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Agent installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Agent installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_MANUAL_TRIGGER") {
      title = "Manual trigger";
      message = `Manual trigger installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "INSTALLATION_TRRIGGER") {
      title = "Installed trigger";
      message = `Installed trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "USER_LOGON_TRIGGER") {
      title = "Logon trigger";
      message = `Logon trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_DAILY_TRIGGER") {
      title = "Linux specific time";
      message = `Linux specific time trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_USB_TRIGGER") {
      title = "Linux trigger";
      message = `Linux trigger  on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_FIREWALL_TRIGGER") {
      title = "Linux firewall trigger";
      message = `Linux firewall trigger  on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "MAC_INTERVAL_TRIGGER") {
      title = "Mac specific interval trigger";
      message = `interval trigger on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_USB_TRIGGER") {
      title = "USB trigger";
      message = `USB trigger  on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_DAILY_TRIGGER") {
      title = "Daily trigger";
      message = `Daily trigger   on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_DAILY_TRIGGER") {
      title = "Daily trigger";
      message = `Daily trigger   on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_INTERVAL_TRIGGER") {
      title = "Interval trigger";
      message = `Interval trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_LOGON_TRIGGER") {
      title = "Logon trigger";
      message = `Logon trigger on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_NETWORK_TRIGGER") {
      title = "Linux Network Trigger";
      message = `Network trigger on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_USB_TRIGGER") {
      title = "USB Trigger";
      message = `Unauthorized USB  used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device  used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Agent installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_MANUAL_TRIGGER") {
      title = "Manual trigger";
      message = `Manual trigger installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_DEFENDER_SCAN_TRIGGER") {
      title = "Defender scan";
      message = `Defender scanned at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_DEFENDER_MALWARE_TRIGGER") {
      title = "Defender MALWARE";
      message = `Defender malware at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_FIREWALL_TRIGGER") {
      title = "WINDOWS FIREWALL";
      message = `Windows firewall at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_INTERVAL_TRIGGER") {
      title = "WINDOWS FIREWALL";
      message = `Windows firewall at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MissedLog") {
      title = "Missed log";
      message = `Missed log at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_NETWORK_TRIGGER") {
      title = "MAC NETWORK TRIGGER";
      message = `Mac Network at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_LOGON_TRIGGER") {
      title = " LOGON";
      message = ` logon at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_LOGON_TRIGGER") {
      title = " LOGON";
      message = ` logon at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_DAILY_TRIGGER") {
      title = "Daily ";
      message = `Daily trigger at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "USB_TRIGGER") {
      title = "USB trigger";
      message = ` USB trigger at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_NETWORK_TRIGGER") {
      title = "Network trigger";
      message = ` Network trigger at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "APP_INSTALL") {
      title = "APP Installed";
      // message = `Unauthorized USB {NAME} used in ${host_name}`;
      message = `New app installed  ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "APP_UNINSTALL") {
      title = "APP UNINSTALL";
      message = `App uninstalled in ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "NETWORK_STATE_CHANGE") {
      title = "Network state changed";
      message = `Network state changed on ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "ROUTINE") {
      title = "ROUTINE";
      message = `Routine on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "SERVICE_STARTUP") {
      title = "Service start-up";
      message = `Service start-up on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "INSTALLATION") {
      title = "Android Installation";
      message = `Installation at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "DEVELOPER_SETTING_CHANGED") {
      title = "Developer setting changed";
      message = `Developer setting changed at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "USB_CONNECTED") {
      title = "USB connected";
      message = `USB connected at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "BLACKLIST_APP_INSTALL") {
      title = "BLACKLIST app installed";
      message = `BLACKLIST app installed at ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_VERSION_ALERT") {
      title = "WATCHDOG VERSION ALERT";
      message = `Watchdog version alert on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_SERVICE_ALERT") {
      title = "WATCHDOG SERVICE ALERT";
      message = `Watchdog service alert on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_FILE_ALERT") {
      title = "WATCHDOG FILE ALERT";
      message = `Watchdog file alert on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_TRIGGER") {
      title = "Watchdog trigger";
      message = `Watchdog trigger on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_UPDATE_ALERT") {
      title = "WATCHDOG UPDATE ALERT";
      message = `Watchdog Update alert on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_SERVICE_ALERT") {
      title = "WATCHDOG UPDATE ALERT";
      message = `Watchdog Update alert on ${host_name}`;
      color = "danger";
    }
    // Continue with the other conditions...

    return { title, message, color };
  };

  function updateNotification(notificationID) {
    notifyApi
      .post(
        `/update-notification/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then(() => {
      })
      .catch(() => {
      });
  }

  const handleRouteClient = (
    notifcationID,
    pcData,
    timeStamp,
    eventTriggerType
  ) => {
    if (eventTriggerType.includes("WINDOWS")) {
      navigate("/windows-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }

    if (eventTriggerType?.includes("LINUX")) {
      navigate("/linux-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
    if (eventTriggerType?.includes("MAC")) {
      navigate("/macOs-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
    if (eventTriggerType?.includes("CHROME")) {
      navigate("/chrome-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }

    if (eventTriggerType?.includes("ANDROID")) {
      navigate("/android-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    toggleSidebarCollapsed();
  };

  const handleNotificationClick = async (notificationId) => {
    const response = await notifyApi.patch(
      `/update-notification/${notificationId}`,
      { headers: { Authorization: `Bearer ${user?.data?.accessToken}` } }
    );
  };

  const handleClearAll = async () => {
    try {
      const response = await notifyApi.post(
        `/mark-all-notification-as-read/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {}, // Empty object for the request body
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.info(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setPageNoAll(1);
      setPageNoUnread(1);
    } catch (error) {
      toast.error(error.response.data.message)
    }
  };

  return (
    <div className="header-main d-flex align-items-center justify-content-between px-3 px-lg-4">
      <div className="d-flex align-items-center justify-content-start">
        <Link id="toogle-bar" style={{ zIndex: "9999" }} onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
          <i className="ri-menu-2-fill"></i>
        </Link>
      </div>

      <div className="d-flex align-items-center justify-content-end ms-auto">
        <div className="d-flex align-items-center justify-content-between">
          <ThemeToggle />
          <Dropdown
            className="dropdown-notification d-flex align-items-center justify-content-center"
            show={show}
            style={{ marginLeft: "10px" }}
            onToggle={() => {
              if (user?.isPasswordUpdated === false) {
                dispatch(
                  showToaster({
                    title: "Alert",
                    message: "Please update your password",
                    color: "warning",
                  })
                );
                return;
              }
              setShow(!show);
            }}
          >
            <Dropdown.Toggle role="button" as="div" ref={dropdownRef}>
              <i className="ri-notification-3-line" style={{ fontSize: "18px", color: "black" }}></i>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {totalNotificationCountUnread > 0 ? totalNotificationCountUnread : "0"}
              </span>
            </Dropdown.Toggle>
            {show && (
              <Dropdown.Menu
                ref={menuRef}
                className="mt-10-f me--10-f show"
                style={{ position: "absolute", width: "400px" }}
              >
                <NotificationListMain
                  notificationsUnread={notificationDataUnread}
                  notificationCountUnread={totalNotificationCountUnread}
                  notificationsAll={notificationDataAll}
                  notificationCountAll={totalNotificationCountAll}
                  onNotificationClick={handleNotificationClick}
                  onClearAll={handleClearAll}
                  updateNotification={updateNotification}
                  onChange={resetNotificationData}
                  setPageUnread={setPageNoUnread}
                  setPageAll={setPageNoAll}
                />
              </Dropdown.Menu>
            )}
          </Dropdown>

          {/* Profile Dropdown */}
          <Dropdown className="dropdown-profile ms-xl-4" align="end">
            <Dropdown.Toggle
              as="div"
              className="dropdown-toggle d-flex align-items-center mt-2 justify-content-center no-caret"
              role="button"
            >
              <div className="avatar online">
                <img src={userAvatar} alt="" style={{ background: "white" }} />
              </div>
            </Dropdown.Toggle>
            <small
              style={{ fontSize: "10px", fontWeight: "700" }}
            >
              {user?.data?.user?.roleName}
            </small>
            <Dropdown.Menu className="dropdown-menu-outer" style={{ marginTop: "20px" }}>
              <div className="dropdown-menu-body rounded">
                <div className="avatar avatar-xl online mb-3">
                  <img src={userAvatar} alt="" />
                </div>
                <h5
                  className="mb-1 text-dark fw-semibold"
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden"
                  }}
                >
                  {user?.data?.user?.firstName || "Full Name"}
                </h5>
                <p className="fs-md text-secondary mb-0"
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden"
                  }}>
                  {user?.data?.user?.roleName === "Super Admin" || user?.data?.user?.roleName === "Organization Admin"
                    ? user.data.user.roleName
                    : `${UsertypeDept ? UsertypeDept + '/' : ''}${UsertypeSection ? UsertypeSection + '/' : ''}${UserTypeSubSection || ''}`}
                </p>
                <p className="fs-sm text-secondary"
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden"
                  }}>
                  {user?.data?.user?.organization || "NA"}
                </p>

                <nav className="nav">
                  <Link to="/update-profile">
                    <i className="ri-edit-2-line"></i> Edit Profile
                  </Link>
                </nav>
                <hr />
                <nav className="nav">
                  <Link onClick={handleLogOut}>
                    <i className="ri-logout-box-r-line"></i> Log Out
                  </Link>
                </nav>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Custom Toaster */}
        <CustomToaster />
      </div>
    </div>
  );
}

export default Header;
